import * as R from 'ramda';
import { connect } from 'react-redux';
import { accessToken } from '../../../../global/auth';
import { id, url } from '../../../../global/experience';
import VerifiedPreviewButton, {
    PreviewTooltipMenu as PreviewTooltipMenuRaw,
} from './component';
import {
    getPreviewAttribute,
    updatePreviewAttribute,
} from './duck';

/* eslint-disable camelcase */
const mapStateToProps = () => R.applySpec({
    studioId: id,
    url,
    hiddenAttributes: {
        access_token: accessToken,
        verification_enabled: getPreviewAttribute('verificationEnabled'),
        verified: getPreviewAttribute('verified'),
        tamper_enabled: getPreviewAttribute('tamperEnabled'),
        tampered: getPreviewAttribute('tampered'),
    },
});
/* eslint-enable camelcase */

const mapDispatchToProps = {};

export const PreviewTooltipMenu = connect(
    R.applySpec({
        verificationEnabled: getPreviewAttribute('verificationEnabled'),
        verified: getPreviewAttribute('verified'),
        tamperEnabled: getPreviewAttribute('tamperEnabled'),
        tampered: getPreviewAttribute('tampered'),
    }),
    { updatePreviewAttribute },
)(PreviewTooltipMenuRaw);

export default connect(mapStateToProps, mapDispatchToProps)(VerifiedPreviewButton);
