import React from 'react';
import styled from 'styled-components';
import {
    PrimaryButton,
    TertiaryButton,
} from '../../../uiComponents/inputs/button';
import { P } from '../../../uiComponents/textStyles';

const Message = styled(P)`
    margin-bottom: 16px;
`;

const Content = styled.div`
    padding: 24px 32px;
`;

const Buttons = styled.div`
    justify-content: center;
    display: flex;

    & > *:not(:last-child) {
        margin-right: 45px;
    }
`;

export const CONTENT_TYPE = 'confirmation' as const;

export type ContentConfig = {
  bodyText: string;
  acceptText?: string;
  rejectText?: string;
  noReject?: boolean;
};

export type ContentResponse = {
  confirmed: boolean;
};

type ContentProps = {
  modalCompleteConfig: {
    content: typeof CONTENT_TYPE,
    contentConfig: ContentConfig,
  },
  closeModal: (content: typeof CONTENT_TYPE, response: ContentResponse) => void,
};

export const ContentComponent = ({
    modalCompleteConfig: {
        contentConfig: {
            bodyText,
            acceptText,
            rejectText,
            noReject,
        },
    },
    closeModal,
}: ContentProps): React.ReactElement => (
    <Content>
        <Message>{bodyText}</Message>
        <Buttons>
            {
                noReject
                    ? true
                    : (
                        <TertiaryButton
                            type="button"
                            onClick={() => closeModal(CONTENT_TYPE, { confirmed: false })}
                        >
                            {rejectText ?? 'Cancel'}
                        </TertiaryButton>
                    )
            }
            <PrimaryButton
                type="button"
                onClick={() => closeModal(CONTENT_TYPE, { confirmed: true })}
            >
                {acceptText ?? 'Confirm'}
            </PrimaryButton>
        </Buttons>
    </Content>
);
