import * as R from 'ramda';
import * as yup from 'yup';
import {
    createIncrementingIdEntityAdapter,
    IncrementingIdEntityState,
    IncrementingIdEntitySelectors,
    yupIncrementingIdState,
} from './incrementingIdEntityAdapter';
import {
    ComponentId,
} from './components';
import { createStateOperator } from './helpers';
import { unwrapExists } from '../utils';

export type ComponentListId = number;

export type ComponentList = {
  id: ComponentListId;
  componentIds: Array<ComponentId>;
  parentComponentId: ComponentId | null,
};

export type ComponentRange = {
  componentListId: ComponentListId;
  start: ComponentId | 'start';
  end: ComponentId | 'end'; // end is inclusive
};

const componentListsAdapter = createIncrementingIdEntityAdapter<ComponentList>();

export const FIXED_LIST_ID = -1;
export const ROOT_LIST_ID = 0;

export const isReservedListId = (id: number): boolean => id < 1;

export const {
    createOne,
    updateOne,
    updateMany,
    addOne,
    addMany,
    upsertOne,
    upsertMany,
    reserveId,
    removeOne,
} = componentListsAdapter;

export const getInitialState = (): LocalState => ({
    nextId: 1,
    ids: [FIXED_LIST_ID, ROOT_LIST_ID],
    entities: {
        [FIXED_LIST_ID]: {
            id: FIXED_LIST_ID,
            componentIds: [],
            parentComponentId: null,
        },
        [ROOT_LIST_ID]: {
            id: ROOT_LIST_ID,
            componentIds: [],
            parentComponentId: null,
        },
    },
});

export type Selectors<V> = IncrementingIdEntitySelectors<ComponentList, V> & {
  selectComponentRangeIds: (state: V, range: ComponentRange) => Array<ComponentId>;
};

export type LocalState = IncrementingIdEntityState<ComponentList>;

export const componentRangeIndexes = (
    range: ComponentRange,
    componentIds: Array<ComponentId>,
): [number, number] => {
    let startIdx = null;
    let endIdx = null;
    let i = 0;
    for (const id of componentIds) {
        if (range.start === id) {
            startIdx = i;
        }
        if (range.end === id) {
            endIdx = i + 1;
            break;
        }
        i += 1;
    }
    if (range.start === 'start') {
        startIdx = 0;
    }
    if (range.end === 'end') {
        endIdx = componentIds.length;
    }
    if (typeof startIdx !== 'number' || typeof endIdx !== 'number') {
        throw new Error('Invalid Component Range');
    }
    return [startIdx, endIdx];
};

export const getSelectors = <V = LocalState>(
    selectState: (state: V) => LocalState = (R.identity as any),
): Selectors<V> => {
    const baseSelectors = componentListsAdapter.getSelectors(selectState);

    const selectComponentRangeIds = (
        state: V,
        range: ComponentRange,
    ): Array<ComponentId> => {
        const { componentIds } = unwrapExists(baseSelectors.selectById(state, range.componentListId));
        const [startIdx, endIdx] = componentRangeIndexes(range, componentIds);
        return componentIds.slice(startIdx, endIdx);
    };

    return {
        ...baseSelectors,
        selectComponentRangeIds,
    };
};

const localSelectors = getSelectors();

export type PositionInList = (
  | 'start'
  | 'end'
  | {
    afterId?: number;
    atIdx?: number;
  }
);

export type Position = {
  componentListId: ComponentListId;
  position: PositionInList;
}

export const insertListMapMutating = (
    state: LocalState,
    parentComponentId: ComponentId,
    listNames: Record<string, null>,
): Record<string, ComponentListId> => {
    const idMap: Record<string, ComponentListId> = {};
    for (const k of Object.keys(listNames)) {
        const listId = localSelectors.selectNextId(state);
        createOne(state, {
            componentIds: [],
            parentComponentId,
        });
        idMap[k] = listId;
    }
    return idMap;
};

export type InsertIntoListProps = {
  insertPosition: Position,
  newComponentIds: Array<ComponentId>,
};
export const insertIntoList = createStateOperator((
    {
        insertPosition: {
            componentListId,
            position,
        },
        newComponentIds,
    }: InsertIntoListProps,
    state: LocalState,
) => {
    const list = state.entities[componentListId];
    if (!list) {
        throw new Error('No list with given ID');
    }
    const { componentIds } = list;
    if (position === 'start') {
        componentIds.splice(0, 0, ...newComponentIds);
    } else if (position === 'end') {
        componentIds.push(...newComponentIds);
    } else if (typeof position.afterId === 'number') {
        const { afterId } = position;
        const idx = componentIds.findIndex(R.equals(afterId));
        if (idx === -1) { return; }
        list.componentIds.splice(idx + 1, 0, ...newComponentIds);
    } else if (typeof position.atIdx === 'number') {
        list.componentIds.splice(position.atIdx, 0, ...newComponentIds);
    }
});

export type RemoveFromList = {
  componentId: ComponentId;
  componentListId: ComponentListId;
};
export const removeComponentFromList = createStateOperator((
    {
        componentId,
        componentListId,
    }: RemoveFromList,
    state: LocalState,
) => {
    const list = unwrapExists(state.entities[componentListId]);
    if (typeof componentId === 'number') {
        const idx = list.componentIds.indexOf(componentId);
        if (idx !== -1) {
            list.componentIds.splice(idx, 1);
        }
    }
});

export const yupComponentLists = yupIncrementingIdState(yup.object().shape<ComponentList>({
    id: yup.number().required(),
    componentIds: yup.array().of(yup.number().required()).required(),
    parentComponentId: yup.number().defined().nullable(),
}).required());
