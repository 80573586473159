import { Action, createAction, createSelector } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { takeEvery, select, put } from 'redux-saga/effects';
import { RootState } from '../../global/rootReducer';
import { ComponentId, ComponentListId } from '../../global/componentTree';
import { ComponentTree } from '../../global/experience';
import { infoForComponentTypeId, ComponentTypeData } from '../../global/componentTypeData';
import { unwrapExists } from '../../global/utils';

const SINGLE_COMPONENT_SELECTION = 'SINGLE_COMPONENT_SELECTION' as const;
const SEQUENTIAL_COMPONENTS_SELECTION = 'SEQUENTIAL_COMPONENTS_SELECTION' as const;
const COMPONENT_LIST_SELECTION = 'COMPONENT_LIST_SELECTION' as const;

type SequentialSelection = {
  start: ComponentId,
  end: ComponentId,
};

export const setSelectedComponent = createAction<ComponentId>('PAGES/EXPERIENCE_EDIT/SELECTION/SELECT_COMPONENT');
export const setSelectedSequentialComponents = createAction<SequentialSelection>('PAGES/EXPERIENCE_EDIT/SELECTION/SELECT_SEQUENTIAL_COMPONENTS');
export const setSelectedComponentList = createAction<ComponentListId>('PAGES/EXPERIENCE_EDIT/SELECTION/SELECT_COMPONENT_LIST');
export const setSequentialComponentsEnd = createAction<ComponentId>('PAGES/EXPERIENCE_EDIT/SELECTION/SELECT_SEQUENTIAL_COMPONENTS_END');

type SingleComponentSelection = {
    type: typeof SINGLE_COMPONENT_SELECTION;
    id: ComponentId;
};
type SequentialComponentsSelection = {
    type: typeof SEQUENTIAL_COMPONENTS_SELECTION;
} & SequentialSelection;
type ComponentListSelection = {
    type: typeof COMPONENT_LIST_SELECTION;
    id: ComponentListId;
};
type Selection = SingleComponentSelection | SequentialComponentsSelection | ComponentListSelection;

type LocalState = Selection | null;

const defaultSelection = {
    type: COMPONENT_LIST_SELECTION,
    id: 0,
};

export const reducer = (state = defaultSelection, action: Action): LocalState => {
    if (setSelectedComponent.match(action)) {
        return {
            type: SINGLE_COMPONENT_SELECTION,
            id: action.payload,
        };
    }
    if (setSelectedSequentialComponents.match(action)) {
        const { start, end } = action.payload;
        return {
            type: SEQUENTIAL_COMPONENTS_SELECTION,
            start,
            end,
        };
    }
    if (setSelectedComponentList.match(action)) {
        return {
            type: COMPONENT_LIST_SELECTION,
            id: action.payload,
        };
    }
    return state;
};

const selectSelectionLocalState = (state: RootState): LocalState => state.pages.experienceEdit.selection;
const selectSingleComponentSelectionIsValid = (
    state: RootState,
    selection: SingleComponentSelection,
): boolean => {
    const component = ComponentTree.selectComponentById(state, selection.id);
    if (!component) { return false; }
    return true;
};
const selectSequentialComponentsSelectionIsValid = (
    state: RootState,
    selection: SequentialComponentsSelection,
): boolean => {
    const startComponent = ComponentTree.selectComponentById(state, selection.start);
    if (!startComponent) { return false; }

    const endComponent = ComponentTree.selectComponentById(state, selection.end);
    if (!endComponent) { return false; }

    return startComponent.parentListId === endComponent.parentListId;
};

const selectComponentListSelectionIsValid = (state: RootState, selection: ComponentListSelection): boolean => {
    const list = ComponentTree.selectComponentListById(state, selection.id);
    return !!list;
};

const selectSelection = (rootState: RootState): Selection | null => {
    const selection = selectSelectionLocalState(rootState);
    if (!selection) { return defaultSelection; }
    // Check if selection is still valid
    const selectionIsValid = (
        selection.type === SINGLE_COMPONENT_SELECTION
            ? selectSingleComponentSelectionIsValid(rootState, selection)
            : selection.type === SEQUENTIAL_COMPONENTS_SELECTION
                ? selectSequentialComponentsSelectionIsValid(rootState, selection)
                : selection.type === COMPONENT_LIST_SELECTION
                    ? selectComponentListSelectionIsValid(rootState, selection)
                    : false
    );
    return selectionIsValid ? selection : defaultSelection;
};
export const selectSelectedSingleComponentId = (rootState: RootState): number | null => {
    const selection = selectSelection(rootState);
    if (selection && selection.type === SINGLE_COMPONENT_SELECTION) {
        return selection.id;
    }
    return null;
};
export const selectSelectedSequentialComponentSelection = (rootState: RootState): SequentialSelection | null => {
    const selection = selectSelection(rootState);
    if (selection && selection.type === SEQUENTIAL_COMPONENTS_SELECTION) {
        return selection;
    }
    return null;
};
export const selectSelectedSequentialComponentIds = createSelector(
    selectSelectedSequentialComponentSelection,
    ComponentTree.selectComponentByIdMap,
    ComponentTree.selectComponentListByIdMap,
    (selection, componentById, componentListById) => {
        if (!selection) { return null; }
        const startComponent = unwrapExists(componentById[selection.start]);
        const { componentIds } = unwrapExists(componentListById[startComponent.parentListId]);
        // return all components in the start to end range
        // end may be earlier in the list than the start
        const result = [];
        let until = null;
        for (const id of componentIds) {
            if (until) {
                result.push(id);
                if (until === id) {
                    return result;
                }
            } else if (id === selection.start) {
                result.push(id);
                until = selection.end;
            } else if (id === selection.end) {
                result.push(id);
                until = selection.start;
            }
        }
        return null;
    },
);
export const selectSelectedSequentialComponents = createSelector(
    selectSelectedSequentialComponentIds,
    ComponentTree.selectComponentByIdMap,
    (ids, componentMap) => ids && ids.map((id) => componentMap[id]),
);
export const selectSelectedComponentListId = (rootState: RootState): number | null => {
    const selection = selectSelection(rootState);
    return (
        selection && selection.type === COMPONENT_LIST_SELECTION
            ? selection.id
            : null
    );
};
export const selectSelectedComponentList = (
    rootState: RootState,
): ComponentTree.ComponentList | null => {
    const selectedListId = selectSelectedComponentListId(rootState);
    return (
        selectedListId
            ? (ComponentTree.selectComponentListById(rootState, selectedListId) ?? null)
            : null
    );
};
export const selectSelectedComponentListsComponentIds = (rootState: RootState): Array<number> | null => (
    selectSelectedComponentList(rootState)?.componentIds ?? null
);
const selectSelectedComponentIds = createSelector(
    selectSelectedSingleComponentId,
    selectSelectedSequentialComponentIds,
    selectSelectedComponentListsComponentIds,
    (a, b, c) => (a ? [a] : null) ?? b ?? c ?? [],
);
export const selectSelectedComponentIdSet = createSelector(
    selectSelectedComponentIds,
    (componentIds): Set<number> => (
        new Set(componentIds)
    ),
);
export const selectIsComponentSelected = (rootState: RootState, id: number): boolean => (
    selectSelectedComponentIdSet(rootState).has(id)
);
export const selectIsListSelected = (rootState: RootState, id: number): boolean => {
    const focus = selectSelection(rootState);
    return !!focus && focus.type === COMPONENT_LIST_SELECTION && focus.id === id;
};
export const selectEnclosingListId = (rootState: RootState): number | null => {
    const selection = selectSelection(rootState);
    if (!selection) { return null; }
    if (selection.type === COMPONENT_LIST_SELECTION) {
        return selection.id;
    }
    const component = unwrapExists(ComponentTree.selectComponentById(
        rootState,
        selection.type === SINGLE_COMPONENT_SELECTION ? selection.id : selection.start,
    ));
    return component.parentListId;
};
export const selectSelectedSingleComponent = (rootState: RootState): ComponentTree.Component | null => {
    const id = selectSelectedSingleComponentId(rootState);
    return id ? (ComponentTree.selectComponentById(rootState, id) ?? null) : null;
};
export const selectSelectedSingleComponentTypeData = (rootState: RootState): ComponentTypeData | null => {
    const selectedComponent = selectSelectedSingleComponent(rootState);
    return (
        selectedComponent
            ? infoForComponentTypeId(selectedComponent.typeId)
            : null
    );
};
export const selectSelectionsParentComponentIds = (rootState: RootState): Array<ComponentTree.ComponentId> | null => {
    const componentId = selectSelectedSingleComponentId(rootState) ?? selectSelectedComponentIds(rootState)?.[0] ?? null;
    if (!componentId) { return null; }
    const { componentIds } = ComponentTree.selectComponentPath(rootState, componentId);
    return componentIds;
};
export const selectNumberOfComponentsInSelection = (rootState: RootState): number => {
    const selectedSingleComponentId = selectSelectedSingleComponentId(rootState);
    if (selectedSingleComponentId) { return 1; }

    const selectedSequentialComponentIds = selectSelectedSequentialComponentIds(rootState);
    if (selectedSequentialComponentIds) { return selectedSequentialComponentIds.length; }

    const selectedListsComponentIds = selectSelectedComponentListsComponentIds(rootState);
    if (selectedListsComponentIds) { return selectedListsComponentIds.length; }

    return 0;
};

function* setSequentialComponentsEndSaga(
    action: ReturnType<typeof setSequentialComponentsEnd>,
): SagaIterator {
    const endComponent: ReturnType<typeof ComponentTree.selectComponentById> = yield select(ComponentTree.selectComponentById, action.payload);
    if (!endComponent) { return; }

    const singleSelection: ReturnType<typeof selectSelectedSingleComponent> = yield select(selectSelectedSingleComponent);
    if (singleSelection) {
        if (singleSelection.id === endComponent.id) { return; }
        if (singleSelection.parentListId === endComponent.parentListId) {
            yield put(setSelectedSequentialComponents({
                start: singleSelection.id,
                end: endComponent.id,
            }));
        } else {
            yield put(setSelectedComponent(endComponent.id));
        }
        return;
    }

    const sequentialSelection: ReturnType<typeof selectSelectedSequentialComponentSelection> = yield select(selectSelectedSequentialComponentSelection);
    if (sequentialSelection) {
        if (sequentialSelection.end === endComponent.id) { return; }
        const startComponent: ReturnType<typeof ComponentTree.selectComponentById> = yield select(ComponentTree.selectComponentById, sequentialSelection.start);
        if (!startComponent) { return; }
        if (startComponent.parentListId === endComponent.parentListId) {
            yield put(setSelectedSequentialComponents({
                start: startComponent.id,
                end: endComponent.id,
            }));
            return;
        }
    }

    yield put(setSelectedComponent(endComponent.id));
}

export function* saga(): SagaIterator {
    yield takeEvery(
        setSequentialComponentsEnd.type,
        setSequentialComponentsEndSaga,
    );
}
