import React from 'react';
import {
    Field,
    Password,
    Submit,
} from '../uiComponents/input';
import * as styles from './styles.scss';

const preventDefault = (f) => (e) => {
    e.preventDefault();
    f(e);
};

class Auth extends React.Component {
    submit() {
        const { attemptLogin } = this.props;
        attemptLogin(this.emailField.value, this.passwordField.value);
    }

    render() {
        const {
            loginFailedMessage,
            isBusy,
        } = this.props;
        return (
            <div>
                <form onSubmit={preventDefault(this.submit.bind(this))}>
                    <h1 className={styles.title}>Login</h1>
                    {
                        loginFailedMessage
                            ? <div className={styles.errorMessage}>{ loginFailedMessage }</div>
                            : null
                    }
                    <Field split={4} label="Email" passRef={(f) => { this.emailField = f; }} />
                    <Password split={4} label="Password" passRef={(f) => { this.passwordField = f; }} />
                    <Submit working={isBusy} className={styles.submitButton} primary type="submit" label="Login" />
                </form>
            </div>
        );
    }
}

export default Auth;
