import { AnyAction } from 'redux';
import { LOAD_EXPERIENCE } from '../shared';
import { Experience } from '../versioning';

export type AttributeSelect<T> = (experience: Experience) => T;

const staticAttribute = <T>(attrSel: AttributeSelect<T | null>) => (state: T | null = null, action: AnyAction): T | null => {
    switch (action.type) {
        case LOAD_EXPERIENCE:
            return attrSel(action.experience);
        default:
            return state;
    }
};

export default staticAttribute;
