import React from 'react';
import ReactTooltip from 'react-tooltip';
import messages from '../../messages';
import * as styles from './styles.scss';

const Tooltip = ({ children, messageName }) => (
    <div>
        <div data-tip data-for={messageName}>
            { children }
        </div>
        {
            messageName
                ? (
                    <ReactTooltip id={messageName} class={styles.tooltip}>
                        { messages[messageName] }
                    </ReactTooltip>
                )
                : null
        }
    </div>
);

export default Tooltip;
