import apiRequest from '../apiRequest';

function destroy({ id, accessToken }: DestroyParams) {
    return apiRequest(`/experiences/${id}`, {
        method: 'DELETE',
        accessToken,
    });
}

export type DestroyParams = {
  id: string,
  accessToken: string,
}

export default destroy;
