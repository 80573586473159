import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_BASE } from './apiRequest';
import { accessToken } from '../auth';

export type CustomDomain = {
    cfId: string;
    domainName: string;
    status: string;
    organizationId: string;
    createdAt: string;
    updatedAt: string | null;
    verificationErrors?: Array<string>;
};

type GetCustomDomainsBody = {
    customDomains: Array<CustomDomain>;
};

type GetCustomDomainBody = {
    customDomain: CustomDomain;
};

type CreateCustomDomain = {
    customDomain: {
        domainName: string;
    };
};

const CUSTOM_DOMAIN_TAG = 'CustomDomain';

export const CustomDomainApi = createApi({
    reducerPath: 'customDomain',
    baseQuery: fetchBaseQuery({
        baseUrl: API_BASE,
        prepareHeaders: (headers, { getState }) => {
            headers.append('Authorization', `Bearer ${accessToken(getState())}`);
            return headers;
        },
    }),
    tagTypes: [CUSTOM_DOMAIN_TAG],
    endpoints: (builder) => ({
        getCustdomDomainsForOrg: builder.query<GetCustomDomainsBody, number>({
            query: (organizationId) => `custom_domains?organization_id=${organizationId}`,
            providesTags: [CUSTOM_DOMAIN_TAG],
        }),
        createCustdomDomain: builder.mutation<GetCustomDomainBody, CreateCustomDomain>({
            query: ({ customDomain }) => ({
                url: 'custom_domains',
                method: 'POST',
                body: {
                    customDomain,
                },
            }),
            invalidatesTags: [CUSTOM_DOMAIN_TAG],
        }),
        deleteCustdomDomain: builder.mutation<void, string>({
            query: (cfId) => ({
                url: `custom_domain/${cfId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [CUSTOM_DOMAIN_TAG],
        }),
    }),
});
