import styled, { css } from 'styled-components';

const shared = css`
    margin: 0;
`;

const headerShared = css`
    font-weight: 500;
`;

export const H1 = styled.h1`
    ${shared}
    ${headerShared}
    font-size: 24px;
`;

export const H2 = styled.h2`
    ${shared}
    ${headerShared}
    font-size: 20px;
`;

export const H3 = styled.h3`
    ${shared}
    ${headerShared}
    font-size: 18px;
`;

export const H4 = styled.h4`
    ${shared}
    ${headerShared}
    font-size: 16px;
`;

export const H5 = styled.h5`
    ${shared}
    ${headerShared}
    font-size: 14px;
`;

export const P = styled.p`
    ${shared}
    font-size: 14px;
    line-height: 1.43em;
    letter-spacing: 0.025em;
`;
