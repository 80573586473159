import React from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import RawColorPicker from '../colorPicker';
import Spinner from '../loadingSpinner';
import Editor from '../editor';
import Tooltip from '../tooltip';
import StudioFile from './studioFile';
import ImageUpload from './imageUpload';
import * as styles from './styles.scss';
import { CodeEditor } from './codeEditor';

export const Field = ({
    label,
    split,
    onChange,
    passRef,
    tooltipName,
    ...rest
}) => (
    <div className={classnames('row', styles.formField)}>
        <div className={classnames(`col-xs-${split || 6}`, styles.label)}>
            <Tooltip messageName={tooltipName}>
                <label>{ label }</label>
            </Tooltip>
        </div>
        <div className={`col-xs-${12 - (split || 6)}`}>
            <input
                type="text"
                className={styles.text}
                ref={passRef}
                onChange={(e) => onChange && onChange(e.target.value)}
                {...rest}
            />
        </div>
    </div>
);

export const Label = ({ tooltipName, children }) => (
    <div className={classnames(styles.label, styles.full)}>
        <Tooltip messageName={tooltipName}>
            <label>{ children }</label>
        </Tooltip>
    </div>
);

export const SelectKeyField = ({
    value,
    valueDisabled,
    onChange,
    options,
}) => (
    <div className={classnames('row', styles.formField)}>
        <div className="col-xs-6">
            <select
                className={styles.select}
                value={value.key}
                onChange={(e) => onChange && onChange({
                    key: e.target.value,
                    value: value.value,
                })}
            >
                { options.map(({ label, ...rest }) => <option key={label} {...rest}>{ label }</option>) }
            </select>
        </div>
        <div className="col-xs-6">
            <input
                disabled={valueDisabled}
                type="text"
                className={styles.text}
                value={value.value || ''}
                onChange={(e) => onChange && onChange({
                    key: value.key,
                    value: e.target.value,
                })}
            />
        </div>
    </div>
);

export const Checkbox = ({
    value,
    label,
    onChange,
    passRef,
    tooltipName,
    ...rest
}) => (
    <div className={classnames('row', styles.formField)}>
        <div className={classnames('col-xs-11', styles.label)}>
            <Tooltip messageName={tooltipName}>
                <label>{ label }</label>
            </Tooltip>
        </div>
        <div className="col-xs-1">
            <input
                type="checkbox"
                ref={passRef}
                checked={value}
                onChange={(e) => { onChange(e.target.checked); }}
                {...rest}
            />
        </div>
    </div>
);

export const Password = ({
    label, split, onChange, passRef, tooltipName, ...rest
}) => (
    <div className={classnames('row', styles.formField)}>
        <div className={classnames(`col-xs-${split || 6}`, styles.label)}>
            <Tooltip id={tooltipName} messageName={tooltipName}>
                <label>{ label }</label>
            </Tooltip>
        </div>
        <div className={`col-xs-${12 - (split || 6)}`}>
            <input
                type="password"
                className={styles.text}
                onChange={(e) => onChange && onChange(e.target.value)}
                ref={passRef}
                {...rest}
            />
        </div>
    </div>
);

export const Uneditable = ({ label, value, split }) => (
    <div className={classnames('row', styles.formField)}>
        <div className={classnames(`col-xs-${split || 6}`, styles.label)}>
            <label>{ label }</label>
        </div>
        <div className={classnames(`col-xs-${12 - (split || 6)}`, styles.uneditableValue)}>
            { value }
        </div>
    </div>
);

export const Message = ({ message }) => (
    <div className={styles.formField}>
        { message }
    </div>
);

const SelectRaw = ({
    label,
    onChange,
    options,
    tooltipName,
    value,
    defaultValue: _,
    ...rest
}) => (
    <div className={classnames('row', styles.formField)}>
        <div className={classnames('col-xs-6', styles.label)}>
            <Tooltip id={tooltipName} messageName={tooltipName}>
                <label>{ label }</label>
            </Tooltip>
        </div>
        <div className="col-xs-6">
            <select
                className={styles.select}
                onChange={(e) => onChange && onChange(e.target.value)}
                value={value}
                {...rest}
            >
                { options.map(({ label, ...rest }) => <option key={label} {...rest}>{ label }</option>) }
            </select>
        </div>
    </div>
);

export class Select extends React.Component {
    componentDidUpdate(prevProps) {
        const {
            options,
            value,
            defaultValue,
            onChange,
        } = this.props;

        // use defaultValue if options have changed
        // and the current value is not in options
        if (
            prevProps.options !== options
            && !options.some((obj) => obj.value === value)
        ) {
            onChange(defaultValue);
        }
    }

    render() {
        return <SelectRaw {...this.props} />;
    }
}

export const TextArea = ({
    label,
    onChange,
    tooltipName,
    ...rest
}) => (
    <div className={styles.formField}>
        <Tooltip id={tooltipName} messageName={tooltipName}>
            <label className={styles.labelFullWidth}>{ label }</label>
        </Tooltip>
        <textarea
            className={styles.textareaFullWidth}
            onChange={(e) => onChange && onChange(e.target.value)}
            {...rest}
        />
    </div>
);

export const Code = ({
    label,
    onChange,
    tooltipName,
    ...rest
}) => (
    <div className={styles.formField}>
        <Tooltip id={tooltipName} messageName={tooltipName}>
            <label className={styles.labelFullWidth}>{ label }</label>
        </Tooltip>
        <CodeEditor
            onChange={(v) => onChange && onChange(v)}
            {...rest}
        />
    </div>
);

export const ColorPicker = ({
    value,
    label,
    onChange,
    tooltipName,
}) => (
    <div className={classnames('row', styles.formField)}>
        <div className={classnames('col-xs-6', styles.label)}>
            <Tooltip id={tooltipName} messageName={tooltipName}>
                <label>{ label }</label>
            </Tooltip>
        </div>
        <div className="col-xs-6">
            <RawColorPicker
                color={value}
                onChange={
                    (e) => {
                        const {
                            r,
                            g,
                            b,
                            a,
                        } = e.rgb;
                        return onChange && onChange(`rgba(${r}, ${g}, ${b}, ${a})`);
                    }
                }
            />
        </div>
    </div>
);

export const Button = ({
    className,
    el,
    label,
    logo,
    working,
    disabled,
    primary,
    onClick,
    ...rest
}) => {
    const Logo = logo;
    const E = el || 'a';
    return (
        <E
            onClick={working || disabled ? () => {} : onClick}
            className={classnames(
                className,
                styles.button,
                {
                    [styles.primary]: primary,
                    [styles.disabled]: disabled,
                },
            )}
            {...rest}
        >
            { label }
            {
                (working || Logo)
        && (
            <div className={styles.buttonIcon}>
                { (working && <Spinner color={primary ? '#ffffff' : null} />) || (Logo && <Logo />) || null }
            </div>
        )
            }
        </E>
    );
};

const leftPad = R.curry((num, fill, str) => {
    const repeat = R.max(0, num - str.length);
    return R.join('', R.repeat(fill, repeat)) + str;
});

const localOffset = new Date().getTimezoneOffset();

const getValue = (v) => {
    const paddingAndValue = leftPad(2, '0', `${Math.abs(v)}`);

    if (v === 0) {
        return 'GMT';
    }
    if (v > 0) {
        return `GMT+${paddingAndValue}`;
    }

    return `GMT-${paddingAndValue}`;
};

export const DateTimePicker = ({
    value,
    label,
    onChange,
    tooltipName,
}) => (
    <div className={classnames('row', styles.formField)}>
        <div className={classnames('col-xs-6', styles.label)}>
            <Tooltip id={tooltipName} messageName={tooltipName}>
                <label>{ label }</label>
            </Tooltip>
        </div>
        <div className="col-xs-6">
            <DatePicker
                isClearable
                dateFormat="dd MMM yyyy hh:mm aa"
                selected={(() => {
                    const date = value.epoch && new Date(value.epoch * 1000);
                    if (!date) { return null; }
                    // We remove the local timezone offset and add the user specified offset
                    // this allows the user to see the unixtimestamp in the specified utc offset
                    return new Date(date.getTime() - (((60 * value.offset) - localOffset) * 60 * 1000));
                })()}
                showTimeSelect
                timeIntervals={15}
                timeFormat="hh:mm aa"
                portalId="modal-portal"
                popperPlacement="bottom-end"
                onChange={(time) => {
                    // We add back in the offset so the selected time is still relative to UTC not local time
                    onChange(R.assoc(
                        'epoch',
                        time && Math.floor(time.getTime() / 1000) + (((60 * value.offset) - localOffset) * 60),
                        value,
                    ));
                }}
            />
        </div>
        <div className={classnames('col-xs-6', styles.label)}>
            <Tooltip id={tooltipName} messageName={tooltipName}>
                <label>Timezone Offset</label>
            </Tooltip>
        </div>
        <div className="col-xs-6">
            <select
                value={value.offset}
                onChange={
                    (v) => onChange(R.assoc('offset', parseInt(v.target.value, 10), value))
                }
            >
                {
                    R.map(
                        (v) => (
                            <option key={v} value={v}>
                                {getValue(v)}
                            </option>
                        ),
                        R.range(-11, 13),
                    )
                }
            </select>
        </div>
    </div>
);

export const Submit = ({
    className,
    working,
    disabled,
    logo,
    label,
    primary,
    ...rest
}) => {
    const Logo = logo;
    return (
        <button
            type="submit"
            disabled={disabled || working}
            className={classnames(className, styles.button, { [styles.primary]: primary })}
            {...rest}
        >
            { label }
            {
                working || Logo
                    ? (
                        <div className={styles.buttonIcon}>
                            { (working && <Spinner color={primary ? '#ffffff' : null} />) || (Logo && <Logo />) || null }
                        </div>
                    )
                    : null
            }
        </button>
    );
};

export const TextEditor = ({
    value,
    label,
    onChange,
    tooltipName,
    ...rest
}) => (
    <div className={styles.formField}>
        <Tooltip id={tooltipName} messageName={tooltipName}>
            <label className={styles.labelFullWidth}>{ label }</label>
        </Tooltip>
        <Editor
            className={styles.textareaFullWidth}
            value={value}
            onChange={(e) => { onChange(e); }}
            {...rest}
        />
    </div>
);

const variableCondition = [
    {
        type: 'field',
        name: 'varName',
        label: 'Variable Name',
        default: '',
    },
    {
        type: 'select',
        name: 'conditionalType',
        label: 'Condition',
        default: 'eql',
        options: [
            { label: '=', value: 'eql' },
            { label: '!=', value: 'neql' },
            { label: '>', value: 'gt' },
            { label: '<', value: 'ls' },
            { label: '>=', value: 'gte' },
            { label: '<=', value: 'lte' },
        ],
    },
    {
        type: 'field',
        name: 'conditionalValue',
        label: 'Value',
        default: '',
    },
];

const EVENT_OPTIONS = {
    none: [],
    formSubmit: [
        {
            type: 'select',
            name: 'when',
            label: 'When',
            default: 'after',
            options: [
                { label: 'Before', value: 'before' },
                { label: 'After', value: 'after' },
            ],
        },
    ],
    formError: [],
    variableCondition,
    tagVariableCondition: variableCondition,
    advanced: [
        {
            type: 'field',
            name: 'valOne',
            label: 'Value One',
            default: '',
        },
        {
            type: 'field',
            name: 'valTwo',
            label: 'Value Two',
            default: '',
        },
        {
            type: 'select',
            name: 'conditionalType',
            label: 'Condition',
            default: 'eql',
            options: [
                { label: '=', value: 'eql' },
                { label: '!=', value: 'neql' },
            ],
        },
    ],
};

const defaultEventOfType = (type) => EVENT_OPTIONS[type].reduce(
    (t, val) => Object.assign(t, { [val.name]: val.default }),
    { type },
);

const EventOptions = ({ option, value, onChange }) => (
    <div className={classnames('row', styles.formField)}>
        <div className={classnames('col-xs-6', styles.label)}>
            <Tooltip>
                <label>{ option.label }</label>
            </Tooltip>
        </div>
        <div className="col-xs-6">
            {(() => {
                switch (option.type) {
                    case 'field':
                        return (
                            <input
                                type="text"
                                className={styles.text}
                                value={value[option.name]}
                                onChange={(e) => onChange && onChange(R.assoc(option.name, e.target.value, value))}
                            />
                        );
                    case 'select':
                        return (
                            <select
                                className={styles.select}
                                value={value[option.name]}
                                onChange={(e) => onChange && onChange(R.assoc(option.name, e.target.value, value))}
                            >
                                {option.options.map((o) => <option key={o.value} value={o.value}>{ o.label }</option>)}
                            </select>
                        );
                    default:
                        return null;
                }
            })()}
        </div>
    </div>
);

export const Event = ({ value, onChange }) => (
    <>
        <div className={classnames('row', styles.formField)}>
            <div className={classnames('col-xs-6', styles.label)}>
                <Tooltip>
                    <label>Event Type</label>
                </Tooltip>
            </div>
            <div className="col-xs-6">
                <select
                    className={styles.select}
                    value={value.type}
                    onChange={(e) => onChange && onChange(defaultEventOfType(e.target.value))}
                >
                    <option value="none">None</option>
                    <option value="formSubmit">Form Submit</option>
                    <option value="formError">Form Error</option>
                    <option value="variableCondition">Local Variable</option>
                    <option value="tagVariableCondition">Tag Variable</option>
                    <option value="advanced">Advanced</option>
                </select>
            </div>
        </div>
        {
            EVENT_OPTIONS[value.type].map(
                (e, idx) => <EventOptions key={idx} option={e} value={value} onChange={onChange} />,
            )
        }
    </>
);

// We currently only support one event
export const Events = ({ value, onChange }) => (
    <Event
        value={value[0]}
        onChange={(v) => onChange([v])}
    />
);

export const Input = ({ type, ...rest }) => {
    switch (type) {
        case 'events':
            return <Events {...rest} />;
        case 'select':
            return <Select {...rest} />;
        case 'field':
            return <Field {...rest} />;
        case 'message':
            return <Message {...rest} />;
        case 'editor':
            return <TextEditor {...rest} />;
        case 'code':
            return <Code {...rest} />;
        case 'image':
            return <ImageUpload {...rest} />;
        case 'checkbox':
            return <Checkbox {...rest} />;
        case 'color':
            return <ColorPicker {...rest} />;
        case 'studioFile':
            return <StudioFile {...rest} />;
        case 'datetime':
            return <DateTimePicker {...rest} />;
        default:
            return null;
    }
};

export const EasyCopy = ({
    label,
    value,
    split,
    tooltipName,
}) => {
    let rawInput = null;

    const onFocus = () => {
        rawInput.select();
    };

    return (
        <div className={classnames('row', styles.formField)}>
            <div className={classnames(`col-xs-${split || 6}`, styles.label)}>
                <Tooltip id={tooltipName} messageName={tooltipName}>
                    <label>{ label }</label>
                </Tooltip>
            </div>
            <textarea
                onFocus={onFocus}
                spellCheck="false"
                disabled={false}
                onChange={() => undefined}
                ref={(r) => { rawInput = r; }}
                className={classnames(`col-xs-${12 - (split || 6)}`, styles.easyCopy)}
                value={value}
            />
        </div>
    );
};

export { ImageUpload };
