import * as R from 'ramda';

const REGISTER_BUSY = 'BUSY/REGISTER_BUSY' as const;
const RESOLVE_BUSY = 'BUSY/RESOLVE_BUSY' as const;

type RegisterBusyAction = {
  type: typeof REGISTER_BUSY,
  id: string,
};

type ResolveBusyAction = {
  type: typeof RESOLVE_BUSY,
  id: string,
};

type Actions =
  | RegisterBusyAction
  | ResolveBusyAction
  | { type: '__OTHER__' }
;

export const registerBusyRaw = (
    id: string,
): RegisterBusyAction => ({
    type: REGISTER_BUSY,
    id,
});

export const resolveBusyRaw = (
    id: string,
): ResolveBusyAction => ({
    type: RESOLVE_BUSY,
    id,
});

export const componentOptionId = (cid: string, optionName: string) => (
    `COMPONENT_OPTION:${cid}:${optionName}`
);

export const registerBusyComponentOption = (
    cid: string,
    optionName: string,
): RegisterBusyAction => registerBusyRaw(
    componentOptionId(cid, optionName),
);

export const resolveBusyComponentOption = (
    cid: string,
    optionName: string,
): ResolveBusyAction => resolveBusyRaw(
    componentOptionId(cid, optionName),
);

export const experienceAttributeId = (attributeName: string) => (
    `EXPERIENCE_ATTRIBUTE:${attributeName}`
);

export const registerBusyExperienceAttribute = (
    attributeName: string,
): RegisterBusyAction => registerBusyRaw(
    experienceAttributeId(attributeName),
);

export const resolveBusyExperienceAttribute = (
    attributeName: string,
): ResolveBusyAction => resolveBusyRaw(
    experienceAttributeId(attributeName),
);

const initialState: { [id: string]: true } = {};

const reducer = (state = initialState, action: Actions) => {
    switch (action.type) {
        case REGISTER_BUSY:
            return R.assoc(action.id, true, state);
        case RESOLVE_BUSY:
            return R.omit([action.id], state);
        default:
            return state;
    }
};

export default reducer;

export const isBusy = (state: any): boolean => R.keys(state.busy).length > 0;
export const idIsBusy = (id: string) => (state: any) => !!state.busy[id];
