import React, {
    Component,
} from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';
import * as styles from './styles.scss';

const toolbarConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'IMAGE_BUTTON', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Strikethrough', style: 'STRIKETHROUGH' },
        { label: 'Monospace', style: 'CODE' },
        { label: 'Underline', style: 'UNDERLINE' },
    ],
    BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' },
    ],
    BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' },
        { label: 'Blockquote', style: 'blockquote' },
    ],
};

class Editor extends Component {
    constructor(props) {
        super(props);
        const { value: propsValue } = this.props;
        this.state = {
            value: RichTextEditor.createValueFromString(propsValue ?? '', 'html'),
        };
    }

    componentDidUpdate() {
        const { value: newValue } = this.props;
        const { value: oldValue } = this.state;
        if (newValue !== oldValue.toString('html')) {
            this.onChange(RichTextEditor.createValueFromString(newValue ?? '', 'html'));
        }
    }

    onChange(value) {
        const { onChange } = this.props;
        this.setState({ value });
        if (onChange) {
            onChange(value.toString('html'));
        }
    }

    render() {
        const { value } = this.state;
        return (
            <div className={styles.editor}>
                <RichTextEditor
                    toolbarConfig={toolbarConfig}
                    value={value}
                    onChange={(e) => { this.onChange(e); }}
                />
            </div>
        );
    }
}

Editor.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};

export default Editor;
