import React from 'react';
import ReactDOM from 'react-dom';
import { SketchPicker } from 'react-color';
import * as styles from './styles.scss';

const PICKER_WIDTH = 220;
const PICKER_HEIGHT = 301;
const VIEWPORT_PADDING = 12;
const SWATCH_MARGIN = 8;

const Modal = ({
    color,
    onChange,
    position,
    viewportSize,
}) => {
    if (!(position && viewportSize)) {
        return null;
    }

    // If the color picker would be presented off screen
    // nudge it over so it fits within a padding inside the
    // viewport

    const left = Math.max(
        Math.min(
            position.right - PICKER_WIDTH,
            viewportSize.width - PICKER_WIDTH - VIEWPORT_PADDING,
        ),
        VIEWPORT_PADDING,
    );

    const top = Math.max(
        Math.min(
            position.bottom + SWATCH_MARGIN,
            viewportSize.height - PICKER_HEIGHT - VIEWPORT_PADDING,
        ),
        VIEWPORT_PADDING,
    );

    const contents = (
        <div
            className={styles.popover}
            style={{
                left: `${left}px`,
                top: `${top}px`,
            }}
        >
            <SketchPicker
                color={color}
                onChange={onChange}
            />
        </div>
    );

    return ReactDOM.createPortal(
        contents,
        document.getElementById('color-picker-portal'),
    );
};

class ColorPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            position: null,
            viewportSize: null,
        };
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.updatePositioning = this.updatePositioning.bind(this);
        this.buttonRef = React.createRef();
    }

    componentDidMount() {
        this.updatePositioning();
        window.addEventListener('scroll', this.updatePositioning, true);
        window.addEventListener('resize', this.updatePositioning);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updatePositioning);
        window.removeEventListener('resize', this.updatePositioning);
    }

    updatePositioning() {
        if (this.buttonRef.current) {
            this.setState({
                position: this.buttonRef.current.getBoundingClientRect(),
                viewportSize: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
            });
        }
    }

    open() {
        this.setState({ open: true });
    }

    close() {
        this.setState({ open: false });
    }

    render() {
        const {
            color,
            onChange,
        } = this.props;
        const {
            buttonRef,
            open,
            close,
        } = this;
        const { position, viewportSize, open: stateOpen } = this.state;
        return (
            <div>
                <div ref={buttonRef} className={styles.colorPickerBody}>
                    <div className={styles.swatch} onClick={open}>
                        <div className={styles.color} style={{ backgroundColor: color }} />
                    </div>
                    {
                        stateOpen
                            ? (
                                <Modal {...{
                                    color,
                                    onChange,
                                    position,
                                    viewportSize,
                                }}
                                />
                            )
                            : null
                    }
                </div>
                {
                    stateOpen
                        ? <div className={styles.cover} onClick={close} />
                        : null
                }
            </div>
        );
    }
}

export default ColorPicker;
