import reducer, {
    saga,
    loadPage,
} from './duck';
import { Page } from '../type';

export default {
    route: {
        path: '/experiences',
        exact: true,
    },
    loadable: {
        loader: () => import('./container'),
    },
    reducer,
    saga,
    loadPage,
    needsOrg: true,
} as Page;
