import { AnyAction } from 'redux';
import * as R from 'ramda';
import { createAction } from '@reduxjs/toolkit';
import { loadExperience, revertExperience } from '../shared';
import { unwrapExists, assertExists } from '../../utils';
import { RootState } from '../../rootReducer';
import { ExperienceAttributes } from '../versioning';

export { ExperienceAttributes };

const ATTRIBUTES = [
    'componentStyle',
    'backgroundColor',
    'pageTitle',
    'backgroundImage',
    'trackingId',
    'analyticsType',
    'gaDimensions',
];

export const setAttributes = createAction<ExperienceAttributes>('EXPERIENCE/ATTRIBUTES/SET_ATTRIBUTES');

type LocalState = null | {
  clean: ExperienceAttributes;
  edit: ExperienceAttributes;
};

export const reducer = (state: LocalState = null, action: AnyAction): LocalState => {
    if (loadExperience.match(action)) {
        const attributes = R.pick(ATTRIBUTES, action.experience.body.attributes);
        return {
            clean: attributes,
            edit: attributes,
        };
    }
    if (setAttributes.match(action)) {
        assertExists(state);
        return {
            ...state,
            edit: {
                ...state.edit,
                ...R.pick(ATTRIBUTES, action.payload),
            },
        };
    }
    if (revertExperience.match(action)) {
        return {
            ...unwrapExists(state),
            edit: unwrapExists(state).clean,
        };
    }

    return state;
};

const selectLocalState = (state: RootState): LocalState => (
    state.experience.body.attributes
);
const selectCleanAttributes = (state: RootState): ExperienceAttributes => (
    unwrapExists(selectLocalState(state)).clean
);
export const selectAttributes = (state: RootState): ExperienceAttributes => (
    unwrapExists(selectLocalState(state)).edit
);
export const selectIsClean = (state: RootState) => (
    R.equals(selectAttributes(state), selectCleanAttributes(state))
);
