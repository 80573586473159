import { RootState } from '../rootReducer';
import {
    ContentType,
    ContentConfig,
} from './content';
import {
    ModalConfig,
    InternalModalOpenAction,
    InternalModalCloseAction,
    MODAL_INTERNAL_OPEN,
    MODAL_INTERNAL_CLOSE,
} from './sharedActions';

type Action = InternalModalOpenAction | InternalModalCloseAction | { type: 'OTHER_ACTION' };

export type LocalState<K extends ContentType = ContentType> = null | {
  content: K;
  contentConfig: ContentConfig<K>;
  modalConfig: ModalConfig,
};

export const reducer = (state: LocalState = null, action: Action) => {
    switch (action.type) {
        case MODAL_INTERNAL_OPEN:
            return state ?? action;
        case MODAL_INTERNAL_CLOSE:
            return (
                (!state || state.content !== action.content)
                    ? state
                    : null
            );
        default:
            return state;
    }
};

export type ModalCompleteConfig = LocalState;

export const modalCompleteConfig = (state: RootState): LocalState => state.modal.root;
