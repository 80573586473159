import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import TabRouterContext from '../context';
import { getSelectedTab } from '../reducer';

type TabContentContainerProps = React.PropsWithChildren<{
  tabId: string,
}>;

export default ({
    tabId,
    children,
}: TabContentContainerProps): React.ReactElement | null => {
    const tabRouterId = React.useContext(TabRouterContext);
    const selectedTab = useSelector((state: RootState) => (
        getSelectedTab(tabRouterId ?? '', state)
    ));

    return (
        selectedTab === tabId
            ? <>{children}</>
            : null
    );
};
