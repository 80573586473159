import apiRequest, { ApiResponse } from '../apiRequest';

export type DuplicateParams = {
    accessToken: string,
    copyExperienceIds: Array<string>,
    toOrganization: string,
}

export type DuplicateResponseType = ApiResponse<
  {
    succeeded: Array<{
      fromExperience: string;
      toExperience: string;
    }>,
    failed: Array<{
      fromExperience: string;
      error: string;
    }>
  }
>;

const duplicate = ({ accessToken, copyExperienceIds, toOrganization }: DuplicateParams) => (
    apiRequest('/experiences/duplicate', {
        method: 'POST',
        accessToken,
        body: {
            copyExperienceIds,
            toOrganization,
        },
    })
);

export default duplicate;
