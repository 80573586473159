import * as R from 'ramda';
import apiRequest from '../apiRequest';
import {
    Experience,
    ExperienceAnyVersion,
    AssetAssociations,
} from '../../experience';
import {
    seperateMetadata,
    injectMetadata,
} from './helpers';

function updateExperience({ experience, accessToken }: UpdateParams) {
    const [body, metadata] = seperateMetadata(experience.body) as any;
    const response = apiRequest<ResponsePayload>(
        `/experiences/${experience.id}`,
        {
            method: 'PUT',
            body: {
                ...R.omit(['id', 'assetAssociations'], experience),
                body,
                metadata,
            },
            accessToken,
        },
    );
    return response.then((e) => e.map(({ metadata, body, ...rest }) => ({
        ...rest,
        body: injectMetadata(metadata || {})(body),
    })));
}

export type UpdateParams = {
  experience: (
    /* eslint-disable-next-line camelcase */
    { asset_association_updates?: AssetAssociations.AssetAssociationUpdates }
    & Experience
  ),
  accessToken: string,
}

export type ResponsePayload = ExperienceAnyVersion;

export default updateExperience;
