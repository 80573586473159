import reducer from './duck';
import container, {
    PreviewTooltipMenu,
} from './container';

export {
    reducer,
    PreviewTooltipMenu,
};

export default container;
