import React from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import LoadingSpinner from '../uiComponents/loadingSpinner';
import * as styles from './styles.scss';
import { selectIsLoading } from './duck';

const LoadingComponent = () => <LoadingSpinner className={styles.loadingIcon} />;

const LoadablePage = ({ loader, ...options }) => loadable(loader, {
    fallback: <LoadingComponent />,
    resolveComponent: ({ default: Component }) => {
        const ResolvedLoadingComponent = (options.fallback ?? LoadingComponent);
        const C = (props) => {
            const loading = useSelector(selectIsLoading);
            return (
                loading
                    ? <ResolvedLoadingComponent />
                    : <Component {...props} />
            );
        };
        return C;
    },
    ...options,
});

export default LoadablePage;
