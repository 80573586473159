// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#color-picker-portal {\n  position: fixed;\n  left: 0;\n  top: 0;\n  z-index: 300; }\n\n.src-global-uiComponents-colorPicker-styles__colorPickerBody--1xk7g {\n  position: relative;\n  max-width: 28px; }\n\n.src-global-uiComponents-colorPicker-styles__swatch--39NtQ {\n  cursor: pointer;\n  background: #ffffff;\n  box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);\n  display: 'inline-block';\n  cursor: 'pointer';\n  padding: 3px;\n  height: 14px; }\n\n.src-global-uiComponents-colorPicker-styles__color--Y4z6M {\n  height: 100%; }\n\n.src-global-uiComponents-colorPicker-styles__popover--11ihW {\n  position: fixed; }\n\n.src-global-uiComponents-colorPicker-styles__cover--2wMe8 {\n  position: fixed;\n  top: 0px;\n  bottom: 0px;\n  left: 0px;\n  right: 0px; }\n", "",{"version":3,"sources":["webpack://./src/global/uiComponents/colorPicker/styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,OAAO;EACP,MAAM;EACN,YAAY,EAAA;;AAGd;EACE,kBAAkB;EAClB,eAAe,EAAA;;AAGjB;EACE,eAAe;EACf,mBAAmB;EACnB,sCAAmC;EACnC,uBAAuB;EACvB,iBAAiB;EACjB,YAAY;EACZ,YAAY,EAAA;;AAGd;EACE,YAAY,EAAA;;AAGd;EACE,eAAe,EAAA;;AAIjB;EACE,eAAe;EACf,QAAQ;EACR,WAAW;EACX,SAAS;EACT,UAAU,EAAA","sourcesContent":[":global(#color-picker-portal) {\n  position: fixed;\n  left: 0;\n  top: 0;\n  z-index: 300;\n}\n\n.colorPickerBody {\n  position: relative;\n  max-width: 28px;\n}\n\n.swatch {\n  cursor: pointer;\n  background: #ffffff;\n  box-shadow: 0 0 1px rgba(0,0,0,0.8);\n  display: 'inline-block';\n  cursor: 'pointer';\n  padding: 3px;\n  height: 14px;\n}\n\n.color {\n  height: 100%;\n}\n\n.popover {\n  position: fixed;\n}\n\n\n.cover {\n  position: fixed;\n  top: 0px;\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export var colorPickerBody = "src-global-uiComponents-colorPicker-styles__colorPickerBody--1xk7g";
export var swatch = "src-global-uiComponents-colorPicker-styles__swatch--39NtQ";
export var color = "src-global-uiComponents-colorPicker-styles__color--Y4z6M";
export var popover = "src-global-uiComponents-colorPicker-styles__popover--11ihW";
export var cover = "src-global-uiComponents-colorPicker-styles__cover--2wMe8";
export default ___CSS_LOADER_EXPORT___;
