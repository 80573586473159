import { DefaultTheme } from 'styled-components';

declare module 'styled-components' {
    export interface DefaultTheme {
        backgroundColor: string;
        primaryFont: string;
        disabledColor: {
            light: string;
        },
        neutralColor: {
            superExtraLight: string;
            extraLight: string;
            light: string;
            normal: string;
            slightlyDark: string;
            dark: string;
            extraDark: string;
        };
        primaryColor: {
            superExtraLight: string;
            extraLight: string;
            normal: string;
            dark: string;
        };
        infoColor: {
            superExtraLight: string;
            extraLight: string;
            normal: string;
            dark: string;
        };
        warningColor: {
            superExtraLight: string;
            extraLight: string;
            normal: string;
            dark: string;
        };
        successColor: {
            superExtraLight: string;
            extraLight: string;
            normal: string;
            dark: string;
        };
        dangerColor: {
            superExtraLight: string;
            extraLight: string;
            normal: string;
            dark: string;
        };
    }
}

const white = '#ffffff';

const grey = (b: number) => `hsl(221, 7%, ${b}%)`;
const grey91 = grey(91); // #e6e7ea
const grey85 = grey(85); // #d6d8db
const grey52 = grey(52); // #7c818d
const grey40 = grey(40); // #5f636d
const grey27 = grey(27); // #40434a
const grey16 = '#23272f'; // We are cheating! This is a platform side color similar to grey16

const blue = (b: number) => `hsl(219, 87%, ${b}%)`;
const blue96 = blue(96); // #ecf2fe
const blue92 = blue(92); // #d9e5fc
const blue56 = blue(56); // #2d72f0
const blue31 = blue(31); // #0a3a94

const orange = (b: number) => `hsl(28, 86%, ${b}%)`;
const orange95 = orange(95); // #fdf2e7
const orange80 = orange(80); // #f8c9a0
const orange55 = orange(55); // #ef862b
const orange39 = orange(39); // #b95e0e

const green = (b: number) => `hsl(152, 48%, ${b}%)`;
const green94 = green(94); // #e8f7f0
const green76 = green(76); // #a3e0c4
const green50 = green(50); // #40bf84
const green29 = green(29); // #256f4c

const red = (b: number) => `hsl(12, 91%, ${b}%)`;
const red96 = red(96); // #feefec
const red84 = red(84); // #fbc0b1
const red46 = red(46); // #de350b
const red40 = red(40); // #c32e09

const defaultTheme: DefaultTheme = {
    backgroundColor: white,
    primaryFont: 'Lato, sans-serif',
    disabledColor: {
        light: grey85,
    },
    neutralColor: {
        superExtraLight: white,
        extraLight: grey91,
        light: grey85,
        normal: grey52,
        slightlyDark: grey40,
        dark: grey27,
        extraDark: grey16,
    },
    primaryColor: {
        superExtraLight: blue96,
        extraLight: blue92,
        normal: blue56,
        dark: blue31,
    },
    infoColor: {
        superExtraLight: blue96,
        extraLight: blue92,
        normal: blue56,
        dark: blue31,
    },
    warningColor: {
        superExtraLight: orange95,
        extraLight: orange80,
        normal: orange55,
        dark: orange39,
    },
    successColor: {
        superExtraLight: green94,
        extraLight: green76,
        normal: green50,
        dark: green29,
    },
    dangerColor: {
        superExtraLight: red96,
        extraLight: red84,
        normal: red46,
        dark: red40,
    },
};

export default defaultTheme;
