import * as R from 'ramda';
import produce from 'immer';
import * as yup from 'yup';

import { compose } from '@reduxjs/toolkit';
import * as Components from './components';
import * as ComponentLists from './componentLists';
import { equals as incrementingIdEntityStateEquals } from './incrementingIdEntityAdapter';
import { createStateOperator } from './helpers';
import {
    unwrapExists,
    mapValues,
} from '../utils';

export type ComponentTree = {
  components: Components.LocalState;
  componentLists: ComponentLists.LocalState;
};

export type Position = ComponentLists.Position;

export {
    Components,
    ComponentLists,
};

export const getInitialState = (): ComponentTree => ({
    components: Components.getInitialState(),
    componentLists: ComponentLists.getInitialState(),
});

type InsertComponentProps = {
  component: Components.UnmountedComponent,
  insertPosition: ComponentLists.Position,
};
export const insertComponent = createStateOperator((
    {
        component: c,
        insertPosition,
    }: InsertComponentProps,
    state: ComponentTree,
) => {
    const id = localSelectors.components.selectNextId(state);

    const { componentLists: componentListsUnmounted, ...component } = c;

    const componentLists: Record<string, ComponentLists.ComponentListId> | undefined = (
        componentListsUnmounted
      && ComponentLists.insertListMapMutating(state.componentLists, id, componentListsUnmounted)
    );

    Components.createOne(state.components, {
        ...component,
        parentListId: insertPosition.componentListId,
        ...(componentLists && { componentLists }),
    });

    ComponentLists.insertIntoList(
        state.componentLists,
        {
            insertPosition,
            newComponentIds: [id],
        },
    );
});

const removeLists = createStateOperator((
    listIds: Array<ComponentLists.ComponentListId>,
    state: ComponentTree,
) => {
    if (listIds.length === 0) { return; }

    const nestedComponentIds = [];
    for (const listId of listIds) {
        const list = unwrapExists(state.componentLists.entities[listId]);
        nestedComponentIds.push(...list.componentIds);
        ComponentLists.removeOne(state.componentLists, list.id);
    }

    removeComponents(state, {
        componentIds: nestedComponentIds,
        nested: true,
    });
});

export type RemoveComponentsProps = {
  componentIds: Array<Components.ComponentId>;
  nested?: boolean;
};
export const removeComponents = createStateOperator((
    { componentIds, nested = false }: RemoveComponentsProps,
    state: ComponentTree,
) => {
    if (componentIds.length === 0) { return; }

    const removeListIds = [];
    for (const componentId of componentIds) {
        const component = unwrapExists(state.components.entities[componentId]);
        if (component.componentLists) {
            for (const key of Object.keys(component.componentLists)) {
                removeListIds.push(component.componentLists[key]);
            }
        }
        Components.removeOne(state.components, componentId);
        // If this call is nested then the list will be deleted anyway
        if (!nested) {
            ComponentLists.removeComponentFromList(state.componentLists, {
                componentId,
                componentListId: component.parentListId,
            });
        }
    }
    removeLists(state, removeListIds);
});

export type MoveComponentsProps = {
  moveRange: ComponentLists.ComponentRange,
  insertPosition: ComponentLists.Position,
};
export const moveComponents = createStateOperator((
    {
        moveRange,
        insertPosition,
    }: MoveComponentsProps,
    state: ComponentTree,
) => {
    const list = unwrapExists(state.componentLists.entities[moveRange.componentListId]);
    const [startIdx, endIdx] = ComponentLists.componentRangeIndexes(moveRange, list.componentIds);
    const movingIds = list.componentIds.splice(startIdx, endIdx - startIdx);

    for (const id of movingIds) {
        Components.updateOne(state.components, {
            id,
            changes: { parentListId: insertPosition.componentListId },
        });
    }

    ComponentLists.insertIntoList(state.componentLists, {
        insertPosition,
        newComponentIds: movingIds,
    });
});

type SetComponentOptionsProps = {
  id: Components.ComponentId;
  options: Record<string, unknown>;
};
export const setComponentOptions = createStateOperator((
    {
        id,
        options,
    }: SetComponentOptionsProps,
    state: ComponentTree,
) => {
    Components.updateOne(state.components, {
        id,
        changes: {
            options,
        },
    });
});

// To be extra carefull we use this to check
// that values coming out of the id Map are not
// undefined
const assertNumber = (n: unknown): number => {
    if (typeof n !== 'number') {
        throw new TypeError();
    }
    return n;
};

const makeComponentIdMapMutating = (
    oldComponentIds: Array<Components.ComponentId>,
    state: ComponentTree,
): Map<Components.ComponentId, Components.ComponentId> => {
    const componentIdMap = new Map();

    for (const oldId of oldComponentIds) {
        const newId = localSelectors.components.selectNextId(state);
        Components.reserveId(state.components);
        componentIdMap.set(oldId, newId);
    }

    return componentIdMap;
};

const makeComponentListIdMapMutating = (
    oldComponentListIds: Array<Components.ComponentId>,
    state: ComponentTree,
): Map<ComponentLists.ComponentListId, ComponentLists.ComponentListId> => {
    const listIdMap = new Map();

    for (const oldId of oldComponentListIds) {
        if (!ComponentLists.isReservedListId(oldId)) {
            const newId = localSelectors.componentLists.selectNextId(state);
            ComponentLists.reserveId(state.componentLists);
            listIdMap.set(oldId, newId);
        }
    }

    return listIdMap;
};

const mountComponentMutating = (
    component: Components.Component,
    componentIdMap: Map<Components.ComponentId, Components.ComponentId>,
    componentListIdMap: Map<ComponentLists.ComponentListId, ComponentLists.ComponentListId>,
    state: ComponentTree,
) => {
    const id = componentIdMap.get(component.id);
    if (id != null) {
        Components.addOne(state.components, {
            ...component,
            id,
            parentListId: assertNumber(componentListIdMap.get(component.parentListId)),
            ...(
                component.componentLists
                    ? {
                        componentLists: mapValues(
                            componentListIdMap.get.bind(componentListIdMap),
                            component.componentLists,
                        ),
                    }
                    : null
            ),
        });
    }
};

const mountComponentListMutating = (
    list: ComponentLists.ComponentList,
    componentIdMap: Map<Components.ComponentId, Components.ComponentId>,
    componentListIdMap: Map<ComponentLists.ComponentListId, ComponentLists.ComponentListId>,
    state: ComponentTree,
) => {
    const id = componentListIdMap.get(list.id);
    if (id != null) {
        ComponentLists.addOne(state.componentLists, {
            ...list,
            id,
            // The only reserved lists are without a parentComponentId
            // Also all such components ids should exist in the map
            parentComponentId: assertNumber(componentIdMap.get(assertNumber(list.parentComponentId))),
            componentIds: list.componentIds.map(componentIdMap.get.bind(componentIdMap)),
        });
    }
};

export type MountTreeProps = {
  tree: ComponentTree,
  insertPosition: ComponentLists.Position,
};

export const mountSubtree = createStateOperator((
    {
        tree,
        insertPosition,
    }: MountTreeProps,
    state: ComponentTree,
) => {
    const oldComponentIds = localSelectors.components.selectIds(tree);
    // Mapping sorted Ids instead of unsorted entities so result is determenistic
    const componentIdMap = makeComponentIdMapMutating(oldComponentIds, state);

    const oldComponentListIds = localSelectors.componentLists.selectIds(tree);
    // Mapping sorted Ids instead of unsorted entities so result is determenistic
    const componentListIdMap = makeComponentListIdMapMutating(
        oldComponentListIds,
        state,
    );
    // mount root of subtree to choosen list
    componentListIdMap.set(0, insertPosition.componentListId);

    const newComponents = localSelectors.components.selectAll(tree);
    const newComponentLists = localSelectors.componentLists.selectAll(tree);

    for (const c of newComponents) {
        mountComponentMutating(c, componentIdMap, componentListIdMap, state);
    }

    for (const l of newComponentLists) {
        if (l.id === 0) {
            ComponentLists.insertIntoList(
                state.componentLists,
                {
                    insertPosition,
                    newComponentIds: l.componentIds.map(componentIdMap.get.bind(componentIdMap)),
                },
            );
        } else {
            mountComponentListMutating(l, componentIdMap, componentListIdMap, state);
        }
    }
});

export type TreePath = {
  componentIds: Array<Components.ComponentId>;
  componentListIds: Array<ComponentLists.ComponentListId>;
};

export type DeepChildIds = {
  componentIds: Array<Components.ComponentId>;
  componentListIds: Array<ComponentLists.ComponentListId>;
};

type Selectors<V> = {
  components: Components.Selectors<V>;
  componentLists: ComponentLists.Selectors<V>;
  tree: {
    selectComponentPath: (state: V, id: Components.ComponentId) => TreePath;
    selectDeepChildIds: (state: V, id: Components.ComponentId) => DeepChildIds;
    selectExtractSubtree: (state: V, range: ComponentLists.ComponentRange) => ComponentTree;
    selectContainsMoreThanNComponentsOfType: (state: V, n: number, typeId: number) => boolean;
    selectParentComponentForListId: (state: V, listId: ComponentLists.ComponentListId) => (
      Components.Component | null
    );
    selectParentComponentListForComponentId: (state: V, listId: ComponentLists.ComponentListId) => (
      ComponentLists.ComponentList | null
    );
  };
};

export const getSelectors = <V>(
    selectState: (state: V) => ComponentTree = R.identity,
): Selectors<V> => {
    const components = Components.getSelectors<V>(
        compose((s) => s.components, selectState),
    );
    const componentLists = ComponentLists.getSelectors<V>(
        compose((s) => s.componentLists, selectState),
    );

    const selectComponentPath = (state: V, id: Components.ComponentId): TreePath => {
        const componentIds: Array<Components.ComponentId> = [id];
        const componentListIds: Array<ComponentLists.ComponentListId> = [];
        /* eslint-disable-next-line no-constant-condition */
        while (true) {
            const { parentListId } = unwrapExists(
                components.selectById(state, unwrapExists(R.last(componentIds))),
            );
            componentListIds.push(parentListId);
            const { parentComponentId } = unwrapExists(componentLists.selectById(state, parentListId));
            if (parentComponentId == null) { break; }
            componentIds.push(parentComponentId);
        }
        componentIds.reverse();
        componentListIds.reverse();
        return {
            componentIds,
            componentListIds,
        };
    };

    const selectDeepChildIds = (state: V, rootComponentId: Components.ComponentId): DeepChildIds => {
        const componentIds: Array<Components.ComponentId> = [];
        const componentListIds: Array<ComponentLists.ComponentListId> = [];
        let componentIdsIdx = -1;
        let componentListIdsIdx = 0;
        // Iterates and gets all IDs breadth first
        /* eslint-disable-next-line no-constant-condition */
        while (true) {
            if (componentIdsIdx < componentIds.length) {
                while (componentIdsIdx < componentIds.length) {
                    const component = components.selectById(
                        state,
                        (
                            componentIdsIdx === -1
                                ? rootComponentId
                                : componentIds[componentIdsIdx]
                        ),
                    );
                    const componentLists = component?.componentLists;
                    if (componentLists) {
                        for (const name of Object.keys(componentLists)) {
                            componentListIds.push(componentLists[name]);
                        }
                    }
                    componentIdsIdx += 1;
                }
            } else if (componentListIdsIdx < componentListIds.length) {
                while (componentListIdsIdx < componentListIds.length) {
                    const list = componentLists.selectById(
                        state,
                        componentListIds[componentListIdsIdx],
                    );
                    if (list) {
                        for (const id of list.componentIds) {
                            componentIds.push(id);
                        }
                    }
                    componentListIdsIdx += 1;
                }
            } else {
                break;
            }
        }

        return {
            componentIds,
            componentListIds,
        };
    };

    const selectExtractSubtree = (state: V, range: ComponentLists.ComponentRange): ComponentTree => (
        produce(getInitialState(), (newTree: ComponentTree) => {
            const rootComponentIds = componentLists.selectComponentRangeIds(
                state,
                range,
            );
            const componentIds = [...rootComponentIds];
            const componentListIds = [];
            for (const id of rootComponentIds) {
                const dc = selectDeepChildIds(state, id);
                componentIds.push(...dc.componentIds);
                componentListIds.push(...dc.componentListIds);
            }

            componentIds.sort((a, b) => a - b);
            componentListIds.sort((a, b) => a - b);

            const componentIdMap = makeComponentIdMapMutating(componentIds, newTree);
            const componentListIdMap = makeComponentListIdMapMutating(componentListIds, newTree);
            componentListIdMap.set(range.componentListId, 0);

            for (const id of componentIds) {
                const component = unwrapExists(components.selectById(state, id));
                mountComponentMutating(component, componentIdMap, componentListIdMap, newTree);
            }
            for (const id of componentListIds) {
                const list = unwrapExists(componentLists.selectById(state, id));
                mountComponentListMutating(list, componentIdMap, componentListIdMap, newTree);
            }

            ComponentLists.updateOne(newTree.componentLists, {
                id: 0,
                changes: {
                    componentIds: (
                        rootComponentIds.map(componentIdMap.get.bind(componentIdMap))
                    ),
                },
            });
        })
    );

    const selectContainsMoreThanNComponentsOfType = (
        state: V,
        n: number,
        typeId: number,
    ): boolean => {
        const componentMap = components.selectEntities(state);
        let count = 0;
        for (const id of Object.keys(componentMap)) {
            const component = componentMap[id];
            if (component?.typeId === typeId) {
                count += 1;
            }
        }
        return count > n;
    };

    const selectParentComponentForListId = (
        state: V,
        listId: ComponentLists.ComponentListId,
    ): Components.Component | null => {
        const { parentComponentId } = unwrapExists(componentLists.selectById(state, listId));
        return (
            parentComponentId == null
                ? null
                : unwrapExists(components.selectById(state, parentComponentId))
        );
    };

    const selectParentComponentListForComponentId = (
        state: V,
        id: Components.ComponentId,
    ): ComponentLists.ComponentList | null => {
        const { parentListId } = unwrapExists(components.selectById(state, id));
        return (
            parentListId == null
                ? null
                : unwrapExists(componentLists.selectById(state, parentListId))
        );
    };

    return {
        components,
        componentLists,
        tree: {
            selectComponentPath,
            selectDeepChildIds,
            selectExtractSubtree,
            selectContainsMoreThanNComponentsOfType,
            selectParentComponentForListId,
            selectParentComponentListForComponentId,
        },
    };
};

export const localSelectors = getSelectors();

export const equals = (a: ComponentTree, b: ComponentTree): boolean => (
    incrementingIdEntityStateEquals(a.components, b.components)
    && incrementingIdEntityStateEquals(a.componentLists, b.componentLists)
);

export const yupComponentTree = yup.object().shape<ComponentTree>({
    components: Components.yupComponents.required(),
    componentLists: ComponentLists.yupComponentLists.required(),
}).required()
    .test(
        'valid-tree-pointers',
        /* eslint-disable-next-line no-template-curly-in-string */
        '${path} has invalid pointers',
        (componentTree: ComponentTree): componentTree is ComponentTree => {
            try {
                const lists = localSelectors.componentLists.selectAll(componentTree);
                // All components of list exist and point to correct parrent list
                for (const list of lists) {
                    if (!list.componentIds.every((id: Components.ComponentId): boolean => {
                        const component = localSelectors.components.selectById(componentTree, id);
                        return component?.parentListId === list.id;
                    })) {
                        return false;
                    }
                }

                const components = localSelectors.components.selectAll(componentTree);
                // All lists of component exists and point to correct parent
                for (const component of components) {
                    if (component?.componentLists && !Object.values(component.componentLists).every((listId: ComponentLists.ComponentListId): boolean => {
                        const list = localSelectors.componentLists.selectById(componentTree, listId);
                        return list?.parentComponentId === component.id;
                    })) {
                        return false;
                    }
                }
                return true;
            } catch (_) {
                // parts of the structure may be blank and trigger errors
                // you cannot enforce checking the shape before the tests in yup
                // https://github.com/jquense/yup/issues/851
                return false;
            }
        },
    )
    .test(
        'singular-render-per-item',
        /* eslint-disable-next-line no-template-curly-in-string */
        '${path} an item renders more than once',
        (componentTree: ComponentTree): componentTree is ComponentTree => {
            try {
                // This prevents any component or list from being used in multiple places in the tree
                // it also prevents the root or fixed components from being used as a child list of
                // a component.
                //
                // Consequently this prevents cyclic paths where a child renders it's parent causing

                // A circular rendering path is when a child of a component renders it's parent.
                // technicaly this method invalidates any time a component or lists shows up
                // in multiple places in the tree even if it would not cause a rendering cycle but
                // this is also something we do not support.
                const renderedComponentIds = new Set();
                const renderedListIds = new Set([-1, 0]);
                const componentIdsQueue: Array<Components.ComponentId> = [];
                const listIdsQueue: Array<ComponentLists.ComponentListId> = [];
                /* eslint-disable-next-line no-constant-condition */
                while (true) {
                    if (componentIdsQueue.length) {
                        const component = unwrapExists(localSelectors.components.selectById(
                            componentTree,
                            unwrapExists(componentIdsQueue.shift()),
                        ));
                        for (const id of Object.values(component.componentLists ?? {})) {
                            if (renderedListIds.has(id)) {
                                return false;
                            }
                            renderedListIds.add(id);
                            listIdsQueue.push(id);
                        }
                    } else if (listIdsQueue.length) {
                        const list = unwrapExists(localSelectors.componentLists.selectById(
                            componentTree,
                            unwrapExists(listIdsQueue.shift()),
                        ));
                        for (const id of list.componentIds) {
                            if (renderedComponentIds.has(id)) {
                                return false;
                            }
                            renderedComponentIds.add(id);
                            componentIdsQueue.push(id);
                        }
                    } else {
                        break;
                    }
                }
                return true;
            } catch (_) {
                // parts of the structure may be blank and trigger errors
                // you cannot enforce checking the shape before the tests in yup
                // https://github.com/jquense/yup/issues/851
                return false;
            }
        },
    );
