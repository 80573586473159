import * as React from 'react';
import classnames from 'classnames';
import * as R from 'ramda';
import ReactTooltip from 'react-tooltip';
import 'react-toggle/style.css';
import Toggle from 'react-toggle';
import * as styles from './styles.scss';

const ToggleField = ({
    show,
    label,
    value,
    onChange,
}) => (
    show === false
        ? null
        : (
            <div className={classnames(styles.toggleField)}>
                <label>{ label }</label>
                <Toggle
                    className={styles.toggle}
                    icons={false}
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                />
            </div>
        )
);

export const PreviewTooltipMenu = ({
    updatePreviewAttribute,
    verificationEnabled,
    verified,
    tamperEnabled,
    tampered,
}) => (
    <div id="tooltip-wrapper">
        <ReactTooltip
            id="previewOptions"
            effect="solid"
            place="bottom"
            border
            delayUpdate={500}
            type="light"
        >
            <div className={styles.tooltipBody}>
                <div className={styles.toggleFieldSection}>
                    <ToggleField
                        label="Verification Enabled"
                        value={verificationEnabled}
                        onChange={(v) => updatePreviewAttribute('verificationEnabled', v)}
                    />
                    <ToggleField
                        sub
                        label="Verified"
                        value={verified}
                        onChange={(v) => updatePreviewAttribute('verified', v)}
                        show={verificationEnabled}
                    />
                </div>
                <div className={styles.toggleFieldSection}>
                    <ToggleField
                        label="Tamper Enabled"
                        value={tamperEnabled}
                        onChange={(v) => updatePreviewAttribute('tamperEnabled', v)}
                    />
                    <ToggleField
                        sub
                        label="Tampered"
                        value={tampered}
                        onChange={(v) => updatePreviewAttribute('tampered', v)}
                        show={tamperEnabled}
                    />
                </div>
            </div>
        </ReactTooltip>
    </div>
);

class PreviewButton extends React.Component {
    componentDidMount() {
        ReactTooltip.rebuild();
    }

    render() {
        const { studioId, url, hiddenAttributes: propsHiddenAttributes } = this.props;
        const hiddenAttributes = __IS_V2__
            ? propsHiddenAttributes
            : { [__BB_EVENT_TOKEN__]: '_' };

        const previewURL = (
            __IS_V2__
                ? `${__API_BASE__}/experiences/${studioId}/preview`
                : url
        );

        const method = __IS_V2__ ? 'post' : 'get';

        return (
            <form method={method} action={previewURL} target="_blank">
                {
                    R.map(
                        ([key, value]) => <input key={key} type="hidden" name={key} value={value} />,
                        R.toPairs(hiddenAttributes),
                    )
                }
                <div className={styles.previewButton}>
                    <button className={styles.previewMain} type="submit">
                        Preview
                    </button>
                    { __IS_V2__
                        ? (
                            <a id="previewButtonLink" className={styles.expand} data-for="previewOptions" data-tip data-event="click">
                                <i className={classnames('material-icons', styles.expandIcon)}>expand_more</i>
                                &zwnj;
                            </a>
                        )
                        : null }
                </div>
            </form>
        );
    }
}

export default PreviewButton;
