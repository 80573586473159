import apiRequest from '../apiRequest';

export type getOrganizationProps = {
  accessToken: string;
};

export const getOrganization = ({ accessToken }: getOrganizationProps) => (
    apiRequest('/organization', {
        method: 'GET',
        accessToken,
    })
);

export type contextSwitchProps = {
  accessToken: string;
  organizationId: string;
};
export const contextSwitch = ({
    accessToken,
    organizationId,
}: contextSwitchProps) => (
    apiRequest('/organization', {
        method: 'PUT',
        body: {
            organizationId,
        },
        accessToken,
    })
);
