import * as R from 'ramda';
import * as yup from 'yup';
import {
    EntityState,
    EntitySelectors,
    createEntityAdapter,
    EntityAdapter,
    compose,
} from '@reduxjs/toolkit';
import { createStateOperator } from './helpers';

type IncrementingIdEntity = Record<'id', number>;

type WithoutId<T> = Omit<T, 'id'>;

export type IncrementingIdEntityState<T extends IncrementingIdEntity> = (
  Omit<EntityState<T>, 'ids'>
  & {
    nextId: number,
    ids: Array<number>,
  }
);

export type IncrementingIdEntitySelectors<T extends IncrementingIdEntity, V> = (
  // We know selectIds will return numbers not strings
  Omit<EntitySelectors<T, V>, 'selectIds'>
  & {
    selectNextId: <V>(state: V) => number;
    selectIds: <V>(state: V) => Array<number>;
  }
);

export type IncrementingIdEntityAdapter<T extends IncrementingIdEntity> = (
  Omit<EntityAdapter<T>, 'getSelectors' | 'getInitialState'>
  & {
    reserveId<S extends EntityState<T>>(state: S): S;
    createOne<S extends EntityState<T>>(state: S, entity: WithoutId<T>): S;
    getInitialState(): IncrementingIdEntityState<T>;
    getInitialState<S extends Record<string, unknown>>(state: S): IncrementingIdEntityState<T> & S;
    getSelectors(): IncrementingIdEntitySelectors<T, IncrementingIdEntityState<T>>;
    getSelectors<V>(
      selectState: (state: V) => IncrementingIdEntityState<T>
    ): IncrementingIdEntitySelectors<T, V>;
  }
);

const localSelectNextId = (state: IncrementingIdEntityState<any>): number => state.nextId;

export const createIncrementingIdEntityAdapter = <T extends IncrementingIdEntity>(): (
  IncrementingIdEntityAdapter<T>
) => {
    const baseAdapter = createEntityAdapter<T>({
        sortComparer: (a: T, b: T) => a.id - b.id,
    });

  type S = IncrementingIdEntityState<T>;

  const getInitialState: {
    <S extends Record<string, unknown>>(
      state: S,
    ): IncrementingIdEntityState<T> & S;
    (): IncrementingIdEntityState<T>;
  } = (<S extends Record<string, unknown>>(
    state: S,
  ): IncrementingIdEntityState<T> & S => ({
      ...{
          ...baseAdapter.getInitialState(),
          // prove to TypeScript ids are only numbers in our situation
          ids: [],
      },
      nextId: 1,
      ...state,
  })) as any; // typescript does not understand this optional generic

  const reserveId = (state: S): void => {
      // Extra careful just in case nextId is somehow taken
      do {
          state.nextId += 1;
      } while (state.nextId in state.entities);
  };

  // This is ok since we know in this context we are mutating an Immer draft
  const createOneMutably = (entity: WithoutId<T>, state: S): void => {
      const id = state.nextId;
      reserveId(state);

      state.ids.push(id);
      state.entities[id] = {
          ...entity,
          id,
      } as T;
  };

  return {
      ...baseAdapter,
      getInitialState,
      reserveId,
      createOne: createStateOperator(createOneMutably),
      getSelectors: <V>(
          selectState: (state: V) => S = R.identity,
      ): IncrementingIdEntitySelectors<T, V> => {
          const localSelectors = baseAdapter.getSelectors(selectState);
          return {
              ...localSelectors,
              selectIds: localSelectors.selectIds as (s: V) => Array<number>,
              selectNextId: compose(localSelectNextId, selectState),
          };
      },
  };
};

export const equals = <T extends IncrementingIdEntity>(
    a: IncrementingIdEntityState<T>,
    b: IncrementingIdEntityState<T>,
): boolean => {
    if (a === b) { return true; }
    if (a.ids.length !== b.ids.length) { return false; }
    for (let i = 0; i < a.ids.length; i += 1) {
        if (a.ids[i] !== b.ids[i]) {
            return false;
        }
    }
    for (const id of a.ids) {
        if (!R.equals(a.entities[id], b.entities[id])) {
            return false;
        }
    }
    return true;
};

export const yupIncrementingIdState = <S extends yup.ObjectSchema<IncrementingIdEntity>>(
    yupEntity: S,
) => (
        yup.object().shape({
            ids: yup.array().of(yup.number().required()).required(),
            nextId: yup.number().required(),
            entities: yup.lazy((value: unknown) => {
                if (typeof value !== 'object' || value == null) {
                    return yup.object().required();
                }
                const shape: Record<string, S> = {};
                for (const k of Object.keys(value)) {
                    shape[k] = yupEntity;
                }
                return yup.object(shape);
            }),
        })
            .test(
                'has-valid-next-id',
                /* eslint-disable-next-line no-template-curly-in-string */
                '${path} nextId is not after all ids',
                (
                    value: IncrementingIdEntityState<IncrementingIdEntity>,
                ): value is typeof value => (
                    value?.ids?.every((id: number) => id < value.nextId) ?? true
                ),
            )
            .test(
                'has-valid-ids',
                /* eslint-disable-next-line no-template-curly-in-string */
                '${path} ids are invalid',
                (
                    value: IncrementingIdEntityState<IncrementingIdEntity>,
                ): value is typeof value => {
                    const { ids = [], entities = {} } = value ?? {};

                    const idSet = new Set(ids);

                    return (
                        // ids where unique
                        idSet.size === ids.length
                        // ids match keys of entities map
                        && R.equals(
                            idSet,
                            new Set(Object.keys(entities).map((id: string) => parseInt(id, 10))),
                        )
                        // each entities id matches it's key
                        && R.toPairs(entities).every(([
                            key,
                            { id },
                        ]: [string, IncrementingIdEntity]): boolean => (
                            parseInt(key, 10) === id
                        ))
                    );
                },
            )
    );
