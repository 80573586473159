import * as R from 'ramda';
import {
    combineReducers,
    ReducersMapObject,
    Reducer,
    Action,
} from '@reduxjs/toolkit';

const looseCombineReducers = <S, A extends Action>(
    mapping: ReducersMapObject<S, A>,
): Reducer<S, A> => {
    const combined = combineReducers(mapping);
    const keys = R.keys(mapping);
    return (state: S, action: A) => {
        const inner = R.pick(keys as Array<string>, state);
        const result = combined(inner as S, action);
        if (result === inner) { return state; }
        return {
            ...state,
            ...result,
        };
    };
};

export default looseCombineReducers;
