const action = (a) => `LOADABLE_PAGE/${a}`;

const PAGE_LOAD_START = action('LOAD_START');
const PAGE_LOAD_FINISHED = action('LOAD_FINISHED');

const initialState = ({
    loading: true,
});

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PAGE_LOAD_START:
            return {
                ...state,
                loading: true,
            };
        case PAGE_LOAD_FINISHED: {
            return {
                ...state,
                loading: false,
            };
        }
        default:
            return state;
    }
};

export default reducer;

export const loadablePage = (state) => state.loadablePage;
export const selectIsLoading = (state) => loadablePage(state).loading;

export const pageLoadStart = () => ({
    type: PAGE_LOAD_START,
});

export const pageLoadFinished = () => ({
    type: PAGE_LOAD_FINISHED,
});
