import {
    UncreatedExperience,
    CURRENT_VERSION,
} from './versioning';

const defaultExperience: UncreatedExperience = {
    name: 'Experience',
    description: 'A default experience. Fill in a brief description of your experience here.',
    body: {
        version: CURRENT_VERSION,
        attributes: {
            componentStyle: 'default',
            backgroundColor: '#eeeeee',
            backgroundImage: '',
        },
        relationships: {
            components: {
                items: [
                    {
                        id: '1',
                        typeId: '27',
                        componentLists: { content: '1' },
                        options: {
                            backgroundColor: '#ffffff',
                            borderColor: '#dddddd',
                            borderRadius: '2px',
                            borderWidth: '1px',
                            fontSize: '12px',
                            fontWeight: '400',
                            height: 'auto',
                            margin: '8px 0px',
                            padding: '20px',
                            textAlign: 'left',
                            textColor: '#000000',
                            width: 'auto',
                        },
                    },
                ],
                nextId: 2,
                lists: [
                    {
                        componentKey: 'content',
                        componentTypeId: '27',
                        id: '1',
                        items: [],
                    },
                ],
                nextListId: 2,
            },
        },
    },
};

export default defaultExperience;
