import * as R from 'ramda';
import { all } from 'redux-saga/effects';
import { saga as authSaga } from '../auth';
import { pageRouterSaga } from '../router';
import * as pages from '../../pages';
import { saga as uploadFileSaga } from '../uploadFile';
import { saga as experiencesSaga } from '../experiences';
import { saga as modalSaga } from '../modal';

function* rootSaga() {
    yield all([
        pageRouterSaga(R.values(pages)),
        authSaga(),
        uploadFileSaga(),
        experiencesSaga(),
        modalSaga(),
    ]);
}

export default rootSaga;
