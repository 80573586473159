import apiRequest, {
    ApiResponse,
} from '../apiRequest';
import { AssetType } from './types';

const ASSET_API_BASE = __API_BASE__.replace(/\/api$/, '');

export type AssetUploadProps = {
    assetToken: string,
    file: File,
};

type VersionUploadResponse = {
  data: {
    assetId: string,
    assetVersionId: string,
    assetType: AssetType,
    filename: string,
  },
};

const assetUpload = async ({
    assetToken,
    file,
}: AssetUploadProps): Promise<ApiResponse<VersionUploadResponse>> => (
    apiRequest('/version-upload', {
        apiBase: `${ASSET_API_BASE}/asset-api`,
        method: 'POST',
        accessToken: assetToken,
        headers: {
            'Content-Type': file.type,
        },
        rawBody: file,
    })
);

export default assetUpload;
