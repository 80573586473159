import * as R from 'ramda';
import { Either } from 'monet';
import { AnyAction } from 'redux';
import {
    remove,
    includes,
} from 'lodash-es';
import { SagaIterator } from 'redux-saga';
import {
    call,
    put,
    select,
    take,
    delay,
    spawn,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import looseCombineReducers from '../../global/utils/looseCombineReducers';
import {
    Experience,
    loadExperience,
    isClean,
    updateExperience,
    Attributes,
    ComponentTree,
    AssetAssociations,
} from '../../global/experience';
import { RoutePayload } from '../../global/router';
import {
    logout,
    sessionGetExperience,
} from '../../global/auth';
import { unwrapExists } from '../../global/utils';
import {
    assetAssociationToReference,
} from '../../global/api/asset';
import { uploadFileSaga } from '../../global/uploadFile';
import { reducer as previewButtonReducer } from './experienceConfigs/previewButton';
import { RootState } from '../../global/rootReducer';
import {
    validateMoveComponentType,
} from '../../global/componentTypeData/canAddComponents';
import * as Selection from './selection';
import {
    registerBusyRaw,
    resolveBusyRaw,
    registerBusyComponentOption,
    resolveBusyComponentOption,
    registerBusyExperienceAttribute,
    resolveBusyExperienceAttribute,
} from '../../global/busy';
import { saga as componentCopySaga } from './componentCopy';
import { NotFoundResourceError } from '../../global/utils/errors';

enum ExperienceEditActionType {
  FocusList = 'PAGES/EXPERIENCE_EDIT/FOCUS_LIST',
  FocusComponent = 'PAGES/EXPERIENCE_EDIT/FOCUS_COMPONENT',
  ShowComponent = 'PAGES/EXPERIENCE_EDIT/SHOW_COMPONENT',
  HideComponent = 'PAGES/EXPERIENCE_EDIT/HIDE_COMPONENT',
  AddComponent = 'PAGES/EXPERIENCE_EDIT/ADD_COMPONENT',
  OnDragEnd = 'PAGES/EXPERIENCE_EDIT/ON_DRAG_END',
  RemoveComponent = 'PAGES/EXPERIENCE_EDIT/REMOVE_COMPONENT',
  DuplicateComponent = 'PAGES/EXPERIENCE_EDIT/DUPLICATE_COMPONENT',
  SelectDetailPannel = 'PAGES/EXPERIENCE_EDIT/SELECT_DETAIL_PANNEL',
  SetComponentOptions = 'PAGES/EXPERIENCE_EDIT/SET_COMPONENT_OPTIONS',
  SetComponentFileOption = 'PAGES/EXPERIENCE_EDIT/SET_COMPONENT_FILE_OPTION',
  SetExperienceFileAttribute = 'PAGES/EXPERIENCE_EDIT/SET_EXPERIENCE_FILE_ATTRIBUTE',
  ReloadExperience = 'PAGES/EXPERIENCE_EDIT/RELOAD_EXPERIENCE',
  Revert = 'PAGES/EXPERIENCE_EDIT/REVERT',
  Save = 'PAGES/EXPERIENCE_EDIT/SAVE',
  SetEditing = 'PAGES/EXPERIENCE_EDIT/SET_EDITING',
  CollapseList = 'PAGES/EXPERIENCE_EDIT/COLLAPSE_LIST',
  UncollapseList = 'PAGES/EXPERIENCE_EDIT/UNCOLLAPSE_LIST',
}

export type LocalState = {
  hiddenIds: number[],
  selectedDetailPanel: string,
  uncollapsedLists: { [listId: string]: boolean },
  previewButton: any, // this is typed in it's reducer
  selection: any, // this is typed in it's reducer
}

const initialState = {
    hiddenIds: [],
    selectedDetailPanel: 'details',
    uncollapsedLists: {},
    previewButton: undefined,
    selection: undefined,
};

const childReducers = looseCombineReducers({
    previewButton: previewButtonReducer,
    selection: Selection.reducer,
});

const reducer = (state: LocalState = initialState, action: AnyAction) => {
    switch (action.type) {
        case ExperienceEditActionType.ReloadExperience:
            return {
                ...initialState,
                selectedDetailPanel: state.selectedDetailPanel,
                uncollapsedLists: state.uncollapsedLists,
                previewButton: state.previewButton,
                selection: state.selection,
            };
        case ExperienceEditActionType.CollapseList:
            return {
                ...state,
                uncollapsedLists: R.omit([action.listId], state.uncollapsedLists),
            };
        case ExperienceEditActionType.UncollapseList:
            return {
                ...state,
                uncollapsedLists: R.assoc(action.listId, true, state.uncollapsedLists),
            };
        case ExperienceEditActionType.ShowComponent:
            return {
                ...state,
                hiddenIds: remove(state.hiddenIds, action.id),
            };
        case ExperienceEditActionType.HideComponent:
            if (includes(state.hiddenIds, action.id)) {
                return state;
            }
            return {
                ...state,
                hiddenIds: state.hiddenIds.concat([action.id]),
            };
        case ExperienceEditActionType.SelectDetailPannel:
            return {
                ...state,
                selectedDetailPanel: action.selection,
            };
        default: {
            return childReducers(state, action);
        }
    }
};

export default reducer;

export const experienceEdit = (state: RootState): LocalState => (
    state.pages.experienceEdit
);
export const hiddenIds = (state: RootState) => experienceEdit(state).hiddenIds;
export const listIsCollapsed = (listId: number) => (state: RootState) => !experienceEdit(state).uncollapsedLists[listId];
export const uncollapsedLists = (state: RootState) => experienceEdit(state).uncollapsedLists;
export const selectExperienceIsClean = isClean;
export const selectedDetailPanel = (state: RootState) => experienceEdit(state).selectedDetailPanel;

export const showComponent = (id: number) => ({
    type: ExperienceEditActionType.ShowComponent,
    id,
});

export const hideComponent = (id: number) => ({
    type: ExperienceEditActionType.HideComponent,
    id,
});

export const addComponent = (typeId: number) => ({
    type: ExperienceEditActionType.AddComponent,
    typeId,
});

export const removeComponent = (id: number) => ({
    type: ExperienceEditActionType.RemoveComponent,
    id,
});

export const duplicateComponent = (id: number) => ({
    type: ExperienceEditActionType.DuplicateComponent,
    id,
});

export const selectDetailPanel = (selection: string) => ({
    type: ExperienceEditActionType.SelectDetailPannel,
    selection,
});

export const onDragEnd = (move: any) => ({
    type: ExperienceEditActionType.OnDragEnd,
    move,
});

export const setComponentOptions = (namespace: string[], options: any) => ({
    type: ExperienceEditActionType.SetComponentOptions,
    namespace,
    options,
});

export const reloadExperience = () => ({
    type: ExperienceEditActionType.ReloadExperience,
});

export const collapseList = (listId: number) => ({
    type: ExperienceEditActionType.CollapseList,
    listId,
});

export const uncollapseList = (listId: number) => ({
    type: ExperienceEditActionType.UncollapseList,
    listId,
});

export const revert = () => ({
    type: ExperienceEditActionType.Revert,
});

export const save = () => ({
    type: ExperienceEditActionType.Save,
});

export const setComponentFileOption = (
    {
        file,
        namespace,
        optionName,
        loadingId,
    }:
  {
    file: File,
    optionName: string,
    loadingId: number,
    namespace: string[],
  },
) => ({
    type: ExperienceEditActionType.SetComponentFileOption,
    namespace,
    file,
    optionName,
    loadingId,
});

export const setExperienceFileAttribute = (
    { file, attributeName, loadingId }: { file: File, attributeName: string, loadingId: number },
) => ({
    type: ExperienceEditActionType.SetExperienceFileAttribute,
    file,
    attributeName,
    loadingId,
});

export function* duplicateComponentSaga({ id }: { id: number }): SagaIterator {
    const nId = yield select(ComponentTree.selectNextComponentId);
    yield put(hideComponent(nId));
    yield put(ComponentTree.duplicateComponent({ componentId: id }));
    yield put(Selection.setSelectedComponent(nId));
    yield put(showComponent(nId));
}

export function* addComponentSaga({ typeId }: { typeId: number }): SagaIterator {
    const id: ComponentTree.ComponentId = yield select(ComponentTree.selectNextComponentId);

    const singleId = yield select(Selection.selectSelectedSingleComponentId);
    const sequentialIds = yield select(Selection.selectSelectedSequentialComponentIds);
    const afterId = singleId ?? sequentialIds?.[sequentialIds?.length - 1] ?? null;

    const listId = yield select(Selection.selectEnclosingListId);
    yield put(hideComponent(id));

    if (listId === ComponentTree.FIXED_LIST_ID) {
        yield put(ComponentTree.insertComponent({
            typeId,
            position: {
                componentListId: ComponentTree.FIXED_LIST_ID,
                position: 'end',
            },
        }));
    } else {
        yield put(ComponentTree.insertComponent({
            typeId,
            position: {
                componentListId: listId,
                position: (
                    afterId
                        ? { afterId }
                        : 'end'
                ),
            },
        }));
    }

    yield put(Selection.setSelectedComponent(id));
    yield delay(10);
    yield put(showComponent(id));
}

const makeLocation = (move: any) => {
    const { droppableId } = move;
    const [listId, index] = droppableId.split('-');
    return {
        listId: parseInt(listId, 10),
        index: parseInt(index, 10) + move.index,
        droppableId,
    };
};

const makeLocations = (move: any) => R.pipe(
    R.evolve({
        source: makeLocation,
        destination: makeLocation,
    }),
    ({ source, destination }: any) => ({
        source,
        destination: {
            listId: destination.listId,
            // Because the lists are the same we need
            // to compensate for 0 indexing
            index: (
                source.listId === destination.listId
                    && move.source.droppableId !== move.destination.droppableId
                    && source.index < destination.index
                    ? destination.index - 1
                    : destination.index
            ),
        },
    }),
)(move);

export function* onDragEndSaga({ move }: { move: any }): SagaIterator {
    if (move.destination) {
        const { source, destination } = makeLocations(move);

        const sourceList: ComponentTree.ComponentList = unwrapExists(yield select((state) => (
            ComponentTree.selectComponentListById(state, source.listId)
        )));

        const sourceId = unwrapExists(sourceList.componentIds[source.index]);

        const { typeId } = yield select((state) => (
            ComponentTree.selectComponentById(state, sourceId)
        ));

        const canMove = yield select(
            (state) => validateMoveComponentType(destination.listId, state)(typeId),
        );

        if (canMove) {
            yield put(ComponentTree.moveComponents({
                moveRange: { componentListId: source.listId, start: sourceId, end: sourceId },
                insertPosition: {
                    componentListId: destination.listId,
                    position: { atIdx: destination.index },
                },
            }));
        }
    }
}

export function* removeComponentSaga({ id }: { id: number }): SagaIterator {
    yield put(hideComponent(id));
    yield delay(300);
    yield put(ComponentTree.removeComponents({
        componentIds: [id],
    }));
    yield put(showComponent(id));
}

export function* setComponentFileOptionSaga({
    file,
    optionName,
    namespace,
}: {
  file: File | null,
  optionName: string,
  loadingId: number,
  namespace: string[],
}): SagaIterator {
    const selectedId = yield select(Selection.selectSelectedSingleComponentId);
    if (!selectedId) {
        return;
    }

    yield put(registerBusyComponentOption(selectedId, optionName));

    const result: SagaReturnType<typeof uploadFileSaga> | Either<never, null> =
      file
          ? yield call(uploadFileSaga, {
              name: 'Image For Studio Component',
              file,
          })
          : Either.Right(null);
    if (result.isRight()) {
        const right = result.right();
        const asset = right && right.asset;
        if (asset && typeof asset !== 'string') {
            yield put(AssetAssociations.addAssets([asset]));
        }
        yield put(ComponentTree.updateComponentOptions({
            id: selectedId,
            namespace,
            options: {
                [optionName]: (
                    asset && typeof asset !== 'string'
                        ? assetAssociationToReference(asset)
                        : asset
                ),
            },
        }));
    }

    yield put(resolveBusyComponentOption(selectedId, optionName));
}

export function* setExperienceFileAttributeSaga({
    file,
    attributeName,
}: {
  file: File | null,
  attributeName: string,
  loadingId: number,
}) {
    yield put(registerBusyExperienceAttribute(attributeName));

    const result: SagaReturnType<typeof uploadFileSaga> | Either<never, null> = (
        file
            ? yield call(uploadFileSaga, {
                name: 'Image For Experience Configuration',
                file,
            })
            : Either.Right(null)
    );
    if (result.isRight()) {
        const right = result.right();
        const asset = right && right.asset;
        if (asset && typeof asset !== 'string') {
            yield put(AssetAssociations.addAssets([asset]));
        }
        yield put(Attributes.setAttributes({ [attributeName]: (
            asset && typeof asset !== 'string'
                ? assetAssociationToReference(asset)
                : asset
        ) }));
    }

    yield put(resolveBusyExperienceAttribute(attributeName));
}

export function* setComponentOptionsSaga({
    options,
    namespace,
}: { options: any, namespace: string[] }): SagaIterator {
    const id = yield select(Selection.selectSelectedSingleComponentId);
    if (!id) { return; }
    yield put(ComponentTree.updateComponentOptions({
        id,
        namespace,
        options,
    }));
}

export function* loadExperienceSaga({ experience }: { experience: Experience }) {
    yield put(loadExperience(experience));
}

export const EXPERIENCE_SAVE_SAGA_BUSY_ID = 'SAGA:EXPERIENCE_SAVE';
export function* saveSaga(): SagaIterator {
    const busyId = EXPERIENCE_SAVE_SAGA_BUSY_ID;
    yield put(registerBusyRaw(busyId));
    const response = yield call(updateExperience);
    if (response.isRight()) {
        yield put(reloadExperience());
        yield put(loadExperience(response.right()));
    }
    yield put(resolveBusyRaw(busyId));
}

export function* revertSaga() {
    yield put(ComponentTree.revert());
}

export function* loadPage({ match }: { match: RoutePayload }): SagaIterator {
    const response = yield call(sessionGetExperience as any, `bluebite:es:${match.params.experienceId}`, true);
    if (response.isLeft()) {
        if (response.left() instanceof NotFoundResourceError) {
            yield put(push('/experiences'));
        } else {
            yield call(logout);
        }
    } else {
        yield put(loadExperience(response.right()));
    }
}

const sagasRegistry: { [key: string]: any } = {
    [ExperienceEditActionType.AddComponent]: addComponentSaga,
    [ExperienceEditActionType.RemoveComponent]: removeComponentSaga,
    [ExperienceEditActionType.DuplicateComponent]: duplicateComponentSaga,
    [ExperienceEditActionType.SetComponentOptions]: setComponentOptionsSaga,
    [ExperienceEditActionType.OnDragEnd]: onDragEndSaga,
    [ExperienceEditActionType.Save]: saveSaga,
    [ExperienceEditActionType.Revert]: revertSaga,
    [ExperienceEditActionType.SetComponentFileOption]: setComponentFileOptionSaga,
    [ExperienceEditActionType.SetExperienceFileAttribute]: setExperienceFileAttributeSaga,
};

export function* saga(): SagaIterator {
    yield spawn(componentCopySaga);
    yield spawn(Selection.saga);
    while (true) {
    // We know each action aligns with the saga
        const payload = yield take(R.keys(sagasRegistry));
        yield call(sagasRegistry[payload.type], payload);
    }
}
