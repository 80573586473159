import type {
    ContentConfig,
    ContentType,
} from './content';

export const MODAL_INTERNAL_OPEN = 'MODAL/INTERNAL/OPEN' as const;
export const MODAL_INTERNAL_CLOSE = 'MODAL/INTERNAL/CLOSE' as const;

export type ModalConfig = {
  implicitDismiss: boolean;
};
export type InternalModalOpenAction<K extends ContentType = ContentType> = {
  type: typeof MODAL_INTERNAL_OPEN,
  content: K,
  contentConfig: ContentConfig<K>,
  modalConfig: ModalConfig,
};
export const internalOpenModal = <K extends ContentType>(
    content: K,
    contentConfig: ContentConfig<K>,
    modalConfig: ModalConfig,
): InternalModalOpenAction<K> => ({
        type: MODAL_INTERNAL_OPEN,
        content,
        contentConfig,
        modalConfig,
    });

export type InternalModalCloseAction = {
  type: typeof MODAL_INTERNAL_CLOSE,
  content: ContentType,
};
export const internalCloseModal = <K extends ContentType>(
    content: K,
): InternalModalCloseAction => ({
        type: MODAL_INTERNAL_CLOSE,
        content,
    });
