import React from 'react';
import {
    ContentConfig,
    ContentResponse,
    ContentType,
} from './types';
import {
    CONTENT_TYPE as CONFIRMATION_CONTENT_TYPE,
    ContentComponent as ConfirmationContentComponent,
} from './confirmation';

type ContentComponentMap = {
  [K in ContentType]: React.FunctionComponent<ContentProps<K>>;
};
const ComponentsMap: ContentComponentMap = {
    [CONFIRMATION_CONTENT_TYPE]: ConfirmationContentComponent,
};
const contentLookup = <K extends ContentType>(content: K): React.FunctionComponent<ContentProps<K>> => (
  // Keys of ContentComponentMap are the content type of their associated value.
  // We explicitly define the type of ContentComponentMap that way to typecheck it but
  // this fact is not generically encoded in the structure of the resulting type even
  // though we know it must be true. This type cast assures TypeScript that this
  // relationship will always be true.
  ComponentsMap[content] as any
);

export type ContentProps<T extends ContentType = ContentType> = {
  modalCompleteConfig: {
    content: T,
    contentConfig: ContentConfig<T>,
  }
  closeModal: (content: T, response: ContentResponse<T>) => void,
};

export const Content = <K extends ContentType>(props: ContentProps<K>) => {
    const { modalCompleteConfig } = props;
    const C = contentLookup(modalCompleteConfig.content);
    if (!C) { return null; }
    return <C {...props} />;
};
