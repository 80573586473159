import {
    call,
    takeEvery,
    put,
    select,
    fork,
    take,
    cancel,
} from 'redux-saga/effects';
import { Task } from 'redux-saga';
import { push } from 'connected-react-router';
import {
    AuthActionType,
    AuthStatus,
    sessionStatus,
} from '../../global/auth';
import { selectExperiencesRoute } from '../links';

function* redirectSaga() {
    const path: ReturnType<typeof selectExperiencesRoute> = yield select(selectExperiencesRoute);
    yield put(push(path));
}

function* loginSuccessSaga() {
    yield takeEvery(AuthActionType.LoginSuccess, redirectSaga);
}

export function* loadPage() {
    if (
        (yield select(sessionStatus)) as ReturnType<typeof sessionStatus>
        === AuthStatus.HasSession
    ) {
        yield call(redirectSaga);
    }
}

export function* pageLoaded() {
    const loginSuccessProcess: Task = yield fork(loginSuccessSaga);
    yield take('@@router/LOCATION_CHANGE');
    yield cancel(loginSuccessProcess);
}
