import 'flexboxgrid/dist/flexboxgrid.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import store from './store';
import SyncApp from './global/router';

if (__PROJ_ENV__ !== 'local') {
    Sentry.init({
        dsn: __SENTRY_DSN_URL__,
        environment: __PROJ_ENV__,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.01,
    });
}

const dest = document.getElementById('app');

const render = (App: React.ComponentType) => {
    ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,
        dest,
    );
};

render(SyncApp);
