import React from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import CodeMirror from 'codemirror';

const EditorContainer = styled.div`
    height: 200px;
    border: 1px solid #ccc;
    border-radius: 2px;
    font-weight: 500;
    font-size: 12px;
`;

const useCodeMirror = (
    initialValue: string,
): [CodeMirror.Editor | null, React.Ref<HTMLDivElement>] => {
    const [codeMirror, setCodeMirror] = React.useState<CodeMirror.Editor | null>(null);
    const ref = React.useCallback((node) => {
        if (node !== null) {
            setCodeMirror(CodeMirror(node, {
                value: initialValue,
                mode: 'htmlmixed',
                lineNumbers: true,
            }));
        }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);
    return [codeMirror, ref];
};

type CodeEditorProps = {
    value: string;
    onChange: (value: string) => void;
};
export const CodeEditor = ({ value, onChange }: CodeEditorProps): React.ReactElement => {
    const [localValue, setLocalValue] = React.useState(value);
    const [codeMirror, ref] = useCodeMirror(value);

    /*
     * The editor and external state managment (In our case redux) both
     * have copies of the editor state. We need to synchronize these competing
     * values which is a bit tricky:
     *
     * 1. We throttle the external updates to avoid generating too many values
     *    and firing too many actions.
     *
     * 2. We save a local copy of the last update.
     *
     * 3. Everytime the value updates externally we compare it to the last update.
     *    This way we know if the Redux state was updated externally
     *    (notably from reverting via the cancel button). If so we update
     *    the editor. Otherwise this update was triggered by us and the editor is
     *    already up to date. Note we can not check the editor itself since it
     *    has likely already changed with an update that is being throttled.
     *
     */
    React.useEffect(() => {
        if (codeMirror && localValue !== value) {
            codeMirror.setValue(value);
        }
    /*
     * We only want to check if the incomming value was externally triggered
     * if we included localValue here it would trigger as soon as the
     * local state is updated before the external state has a chance to update.
     */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [value, codeMirror]);

    React.useEffect(() => {
        if (codeMirror) {
            codeMirror.setSize(null, 200);

            const callback = throttle((cm: CodeMirror.Editor) => {
                const value = cm.getValue();
                setLocalValue(value);
                onChange(value);
            }, 100);

            codeMirror.on('change', callback);

            return () => {
                codeMirror.off('change', callback);
            };
        }
    }, [codeMirror, onChange]);

    return (
        <EditorContainer ref={ref} />
    );
};

const CodeEditorLabel = styled.label`
    display: block;
    font-weight: 700;
    font-size: #4b4b4c;
`;

const CodeEditorLabelText = styled.div`
    margin-bottom: 8px;
`;

type CodeEditorWithLabelProps = CodeEditorProps & {
    label: string;
};

export const CodeEditorWithLabel = ({
    label,
    ...props
}: CodeEditorWithLabelProps): React.ReactElement => (
    <CodeEditorLabel>
        <CodeEditorLabelText>{label}</CodeEditorLabelText>
        <CodeEditor {...props} />
    </CodeEditorLabel>
);
