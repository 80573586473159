export default {
    fail: 'Something went wrong. Please contact support.',
    // Login
    loginHover: 'Use the same username and password as the Blue Bite platform',
    loginInvalidCredentials: 'The email and password provided are invalid',
    loginNoAccess: 'Your account does not have access to the Experience Studio.',
    // Experiences/
    searchHover: 'Search for name or ID of Experience File',
    // Experiences/xyz
    experienceName: 'Name for this Experience File',
    experienceId: 'ID for this Experience File',
    experienceComponentStyle: 'Default styling includes borders around each Component',
    experienceBackgroundColor: 'Background color around Components',
    experienceDeleteConfirmation: 'Are you sure you would like to delete this experience?',
    // Components
    dynamicComponentMessage: 'This Component is dynamic and will load with the configurations specified in the Blue Bite Dashboard.',
    componentStaticVSDynamic: 'Static values are defined here, Dynamic values are defined on a per-tag basis on the Blue Bite Dashboard.',
    // Campaign
    componentDescriptionCampaign: "Campaign Components will render the Creative image who's campaign is assigned to the corresponding letter-position.\n\nIf there is no Campaign assigned, or if the Creative has no image, this Component will automatically hide.",
    //   Swiper
    componentDescriptionSwiper: "The Swiper Component will render the Creative image who's Campaign is assigned to positions: E, F, G, H, and I.\n\nWith each new Campaign, this Component will display a swiper option allowing users to scroll left and right. If there is only one Campaign present it will behave like the standard Campaign Component",
    //   Weather
    componentDescriptionWeather: "The Weather Component will display the weather conditions in a location determined based on the object's properties. In the case of a Static tag, GPS coordinates of the object are used. In the case of Mobile tag, the device's IP address is used.",
    componentOptionsWeatherTemperature: 'Set the default temperature scale for the Weather Component. The option to switch scales will always be available for users on their phone.',
    //   Image
    componentDescriptionImage: 'Upload an image directly to the studio with the Image Component or specify a image url to source the image from.',
    componentOptionsImageSrc: 'Upload a file or include an image URL.',
    componentOptionsImageLink: 'Add a web, email or any other internet address.',
    //   Twitter
    componentDescriptionTwitter: 'The Twitter Component will display real-time content from a Twitter handle or hashtag. This component is dynamic and will load with the configurations specified in the Blue Bite Dashboard.',
    //   Instagram
    componentDescriptionInstagramEmbed: 'The Instagram Embed component allows you to display a specific post. This component is static and will always load the photo or video you configure in the URL input.',
    //   YouTube
    componentDescriptionYouTube: 'The YouTube Component will display real-time content from an YouTube video, playlist or channel. See [Experience Studio documentation](https://docs.mtag.io/) for detailed configuration instructions.',
    componentOptionsYouTubeType: 'Type of YouTube content',
    componentOptionsYouTubeId: 'ID YouTube content',
    componentOptionsTimestamp: 'Format time as Unix Timestamp',
    //   Interstitial
    componentDescriptionInterstitial: "The Interstitial Component will render the Creative image who's Campaign is assigned to position: Interstitial.",
    //   Form
    componentDescriptionForm: "Use the Form Wrapper to collect user information directly from the mobile page. This wrapper must be configured in tandem with 3rd party form handler that will collect your user's responses.\n\nFurther customize the form by adding with any number of text inputs, select dropdowns, checkboxes, and hidden fields.",
    componentOptionsFormAction: 'Informs the browser what URL to submit to',
    componentOptionsFormMethod: 'Describes the type of request to make when submitting the form',
    //   Input
    componentDescriptionInput: 'Add a text input box to your form with the Input Component. Make sure to configure the Input Name such that it is in-line with your desired form.',
    //   Checkbox
    componentDescriptionCheckbox: 'Add a checkbox to your form with the Checkbox Component. Make sure to configure the Checkbox Name such that it is in-line with your desired form.',
    //   Editor
    componentDescriptionEditor: 'Add text directly to the studio with the Text Component.',
    //   Snippet
    componentDescriptionSnippet: 'Embed HTML snippets with the HTML Component. <iframe> and basic HTML text editing tags are allowed.',
    componentOptionsSnippetHTML: 'Add embedded HTML code to be inserted into Component',
    //   Exclusive
    componentDescriptionExclusive: 'Components added to the Exclusive Wrapper will show or hide based on the result of NFC tag verification.',
    componentOptionsExclusiveVerified: 'Components inside this wrapper will appear if the NFC verification matches. The default value for all technologies (NFC, QR, URL, etc.) is false.',
    //   Schedule
    componentDescriptionSchedule: "Components added to the Schedule Wrapper will only appear during a predetermined period of time. Start and End dates are configured as a global date-time, not by the device's local time.",
    //   Countdown
    componentDescriptionCountdown: "Displays a Countdown dial based on a predetermied start and end date. Start and End dates are configured as a global date-time, not by the device's local time.",
    //   Gallery
    componentDescriptionGallery: 'Add images to the Gallery Wrapper and arrange them in either a swiper or a grid.',
    componentOptionsGalleryType: 'Refer to the Help section for a description of each gallery type.',
    //   Verified
    componentDescriptionVerified: 'The Verified Component will render a checkmark if the NFC tag is verified or an X-mark if it is not verifed. All experience previews will render as false.',
    //   Event
    componentDescriptionEvent: 'The Event Wrapper will reveal nested components based on a set criteria or action.\n\nExample: Hide a form after a successful entry',
    //   Delay
    componentDescriptionDelay: 'The Delay Wrapper will reveal nested components after a predetermined number of seconds.',
    //   Button
    componentDescriptionButton: 'Button components add a clickable button to your page.',
    //   Section
    componentDescriptionSection: "Stylize any component's size, border, fonts, and colors by nesting them inside the section component.",
    //   Hidden Input
    componentDescriptionHiddenInput: 'The Hidden Input Component will not be visibile to the end user but will still pass information to your form. This can be used to identify the source of your data, or used dynamically to pass the mTAG ID of your object.\n\nExample: Value = {{tag:mtag_id}}',
    //   Select
    componentDescriptionSelect: 'The Select Component will create a dropdown list for your form. Sepcify the list terms by separating them with commas in the Options input.\n\nExample:\n\nLabel = Color\n\nOption Labels = Red, Green, Blue\n\nOption Values = r, g, b',
    //   Action
    componentDescriptionAction: 'Action components will run the specified action when the component is rendered. If the Action Component is not nested in a Wrapper, then the action will occur when the page loads. If the Action component is inside a Wrapper, then it will occur when the conditions for that Wrapper are met.\n\nExample:\n\nAction: URL Link nested in a Delay Wrapper will redirect the user after a certain period of time)',
    //   Cookie Banner
    componentDescriptionCookieBanner: 'The cookie banner provides a means to inform visitors of cookies used in your experience and to capture their consent to that use.',
    // Error
    componentError: 'Something went wrong',
    componentErrorWrapper: 'Unsupported Child Component',
    componentErrorUploadSize: 'File size too large',
    componentErrorUploadType: 'Invalid file type',
    invalidFileType: 'Invalid file type. Only png, jpg, and gif formats are supported.',
    invalidFileSize: 'File size too large. Please choose a file under 4MB',
    invalidFileUpload: 'An error occurred while uploading your file. Only png, jpg, and gif files under 4MB are supported.',
};
