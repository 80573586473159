import apiRequest, {
    ApiResponse,
} from '../apiRequest';

export type AssetDuplicationReference = {
  hash: string;
  assetId: string;
  assetVersionId: string;
};

export type AssetDuplicateProps = {
  assetReferences: Array<AssetDuplicationReference>;
  organizationId: number;
  accessToken: string;
};

export type AssetMapping = {
  fromAssetId: string;
  fromAssetVersionId: string;
  toAssetId: string;
  toAssetVersionId: string;
};

export type DuplicationResponseBody = {
  assetMappings: Array<AssetMapping>;
};

const duplicate = ({
    accessToken,
    organizationId,
    assetReferences,
}: AssetDuplicateProps): Promise<ApiResponse<DuplicationResponseBody>> => (
    apiRequest<DuplicationResponseBody>('/assets/duplicate', {
        method: 'POST',
        accessToken,
        body: {
            organizationId,
            assetReferences,
        },
    })
);

export default duplicate;
