import React from 'react';
import { useSelector } from 'react-redux';
import {
    Route,
    Redirect,
} from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router';
import { createBrowserHistory } from 'history';
import LoadablePage from '../loadablePage';
import * as pages from '../../pages';
import Navbar from '../navbar';
import { Modal } from '../modal';
import { selectOrganizationFuture } from '../auth';
import { Future } from '../utils';
import * as styles from './styles.scss';
import StyleRoot from '../styleRoot';

export {
    pageRouterSaga,
    RoutePayload,
} from './duck';

const history = createBrowserHistory();

const RedirectRoot = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.toString();
    return (
        isLoggedIn
            ? (
                <Redirect to={{ pathname: '/experiences', search }} />
            )
            : (
                <Redirect to={{ pathname: '/login', search }} />
            )
    );
};

const pageRouteProps = Object.entries(pages).map(([name, page]) => ({
    key: name,
    ...page.route,
    component: LoadablePage(page.loadable),
}));

const App = () => {
    const organizationFuture = useSelector(selectOrganizationFuture);
    const urlParams = new URLSearchParams(window.location.search);
    const search = urlParams.toString();
    const isLoggedIn = Future.isSuccess(organizationFuture);
    return (
        <ConnectedRouter history={history}>
            <StyleRoot>
                <Navbar />
                <Modal />
                <div className={styles.body}>
                    {
                        Future.isUnresolved(organizationFuture)
                            ? null
                            : (
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        render={() => (
                                            <RedirectRoot isLoggedIn={isLoggedIn} />
                                        )}
                                    />
                                    { pageRouteProps.map((routeProps) => (
                                        <Route {...routeProps} />
                                    )) }
                                    <Route
                                        path="/experiences"
                                        render={() => (
                                            <Redirect to={{ pathname: '/experiences', search }} />
                                        )}
                                    />
                                    <Route
                                        path="/"
                                        render={() => (
                                            <RedirectRoot isLoggedIn={isLoggedIn} />
                                        )}
                                    />
                                </Switch>
                            )
                    }
                </div>
            </StyleRoot>
        </ConnectedRouter>
    );
};

export { history };

export default App;
