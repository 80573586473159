import * as R from 'ramda';
import { Either } from 'monet';
import {
    put,
    call,
    takeEvery,
} from 'redux-saga/effects';
import {
    AnyAction,
    createSelector,
} from '@reduxjs/toolkit';
import {
    sessionListExperiences,
} from '../auth';

export type ExperienceInfo = {
  id: string,
  name: string,
  description: string,
}

enum ExperiencesActionType {
  LoadExperiences = 'EXPERIENCES/LOAD_EXPERIENCES',
  FetchExperiences = 'EXPERIENCES/FETCH_EXPERIENCES',
}

type ExperiencesState = {
  items: ExperienceInfo[] | null,
}

const initialState = {
    items: null,
};

const reducer = (state: ExperiencesState = initialState, action: AnyAction) => {
    switch (action.type) {
        case ExperiencesActionType.LoadExperiences:
            return {
                items: action.experiences,
            };
        default:
            return state;
    }
};

export default reducer;

// TODO: remove any
export const experiences = (state: any): ExperiencesState => state.experiences;
export const experienceItems = (state: any) => experiences(state).items;
export const orderedExperienceItems = createSelector(
    experienceItems,
    (items: ExperienceInfo[] | null) => R.sortBy(
        ({ name }: ExperienceInfo) => name.toLowerCase(),
        items || [],
    ),
);

export const experienceItemWithId = (id: string) => (state: any) => {
    const items = experienceItems(state);
    return items && items.find((i) => i.id === id);
};

export const loadExperiences = (experiences: Array<ExperienceInfo>) => ({
    type: ExperiencesActionType.LoadExperiences,
    experiences,
});

export const fetchExperiences = () => ({
    type: ExperiencesActionType.FetchExperiences,
});

export function* fetchExperiencesSaga() {
    const response: Either<unknown, Array<ExperienceInfo>> = yield call(sessionListExperiences);
    if (response.isRight()) {
        yield put(loadExperiences(response.right()));
    }
}

export function* saga() {
    yield takeEvery(ExperiencesActionType.FetchExperiences, fetchExperiencesSaga);
}
