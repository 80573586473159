import * as R from 'ramda';
import { AnyAction } from 'redux';
import {
    createAction,
    createSelector,
} from '@reduxjs/toolkit';
import { loadExperience } from '../shared';
import { RootState } from '../../rootReducer';
import { ComponentTree, LegacyState } from '../../componentTree';
import { unwrapExists, selectorPipe } from '../../utils';
import { defaultComponentOfType } from '../../componentTypeData';

export type Component = ComponentTree.Components.Component;
export type ComponentId = ComponentTree.Components.ComponentId;
export type ComponentList = ComponentTree.ComponentLists.ComponentList;
export type ComponentListId = ComponentTree.ComponentLists.ComponentListId;
export type ComponentTree = ComponentTree.ComponentTree;

export const {
    FIXED_LIST_ID,
    ROOT_LIST_ID,
} = ComponentTree.ComponentLists;

export const moveComponents = createAction<ComponentTree.MoveComponentsProps>(
    'EXPERIENCE/COMPONENT_TREE/MOVE_COMPONENTS',
);
type InsertComponentActionPayload = {
  typeId: number;
  position: ComponentTree.Position;
};
export const insertComponent = createAction<InsertComponentActionPayload>(
    'EXPERIENCE/COMPONENT_TREE/INSERT_COMPONENT',
);
export type RemoveComponentsProps = ComponentTree.RemoveComponentsProps;
export const removeComponents = createAction<ComponentTree.RemoveComponentsProps>(
    'EXPERIENCE/COMPONENT_TREE/REMOVE_COMPONENTS',
);
export const mountSubtree = createAction<ComponentTree.MountTreeProps>(
    'EXPERIENCE/COMPONENT_TREE/MOUNT_SUBTREE',
);
type DuplicateComponentActionPayload = {
  componentId: ComponentId;
};
export const duplicateComponent = createAction<DuplicateComponentActionPayload>(
    'EXPERIENCE/COMPONENT_TREE/DUPLICATE_COMPONENT',
);
export type UpdateComponentOptionsPayload = {
  id: ComponentId,
  namespace: Array<string>;
  options: Record<string, unknown>;
};
export const updateComponentOptions = createAction<UpdateComponentOptionsPayload>(
    'EXPERIENCE/COMPONENT_TREE/UPDATE_COMPONENT_OPTION',
);
export const revert = createAction(
    'EXPERIENCE/COMPONENT_TREE/REVERT',
);

export type LocalState = {
  editingTree: ComponentTree.ComponentTree | null,
  cleanTree: ComponentTree.ComponentTree | null,
};

const initialState: LocalState = {
    editingTree: null,
    cleanTree: null,
};

export const reducer = (
    state: LocalState = initialState,
    action: AnyAction,
): LocalState => {
    if (loadExperience.match(action)) {
        const newTree = LegacyState.fromLegacyTree(action.experience.body.relationships.components);
        return {
            editingTree: newTree,
            cleanTree: newTree,
        };
    }
    if (moveComponents.match(action)) {
        return {
            ...state,
            editingTree: ComponentTree.moveComponents(unwrapExists(state.editingTree), action.payload),
        };
    }
    if (insertComponent.match(action)) {
        return {
            ...state,
            editingTree: ComponentTree.insertComponent(unwrapExists(state.editingTree), {
                insertPosition: action.payload.position,
                component: defaultComponentOfType(action.payload.typeId),
            }),
        };
    }
    if (removeComponents.match(action)) {
        return {
            ...state,
            editingTree: ComponentTree.removeComponents(
                unwrapExists(state.editingTree),
                action.payload,
            ),
        };
    }
    if (mountSubtree.match(action)) {
        return {
            ...state,
            editingTree: ComponentTree.mountSubtree(
                unwrapExists(state.editingTree),
                action.payload,
            ),
        };
    }
    if (duplicateComponent.match(action)) {
        const { componentId } = action.payload;

        const component = unwrapExists(localEditingTreeSelectors.components.selectById(
            state,
            componentId,
        ));

        const subtree = localEditingTreeSelectors.tree.selectExtractSubtree(
            state,
            {
                componentListId: component.parentListId,
                start: componentId,
                end: componentId,
            },
        );

        return {
            ...state,
            editingTree: ComponentTree.mountSubtree(unwrapExists(state.editingTree), {
                tree: subtree,
                insertPosition: {
                    componentListId: component.parentListId,
                    position: { afterId: componentId },
                },
            }),
        };
    }
    if (updateComponentOptions.match(action)) {
        const options = R.over(
            R.lensPath(action.payload.namespace ?? []),
            (ns: Record<string, unknown>) => ({
                ...ns,
                ...action.payload.options,
            }),
            unwrapExists(ComponentTree.localSelectors.components.selectById(
                unwrapExists(state.editingTree),
                action.payload.id,
            )).options,
        );
        return {
            ...state,
            editingTree: ComponentTree.setComponentOptions(
                unwrapExists(state.editingTree),
                {
                    id: action.payload.id,
                    options,
                },
            ),
        };
    }
    if (revert.match(action)) {
        return {
            ...state,
            editingTree: state.cleanTree,
        };
    }
    return state;
};

const localEditingTreeSelectors = ComponentTree.getSelectors(
    (ls: LocalState): ComponentTree.ComponentTree => (
        unwrapExists(ls.editingTree)
    ),
);

const localEditingTreeComponentList = (
    state: LocalState,
    listId: ComponentTree.ComponentLists.ComponentListId,
): Array<ComponentTree.Components.Component> => (
    unwrapExists(
        localEditingTreeSelectors.componentLists.selectById(state, listId),
    )
        .componentIds.map(
            (id: ComponentTree.Components.ComponentId): ComponentTree.Components.Component => (
                unwrapExists(localEditingTreeSelectors.components.selectById(state, id))
            ),
        )
);

const selectComponentTree = (state: RootState): LocalState => (
    state.experience.body.componentTree
);
export const selectEditingTree = (state: RootState): ComponentTree.ComponentTree => (
    unwrapExists(selectComponentTree(state).editingTree)
);
export const selectAllComponents = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.components.selectAll,
);
export const selectComponentByIdMap = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.components.selectEntities,
);
export const selectComponentById = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.components.selectById,
);
export const selectComponentListById = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.componentLists.selectById,
);
export const selectParentComponentForListId = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.tree.selectParentComponentForListId,
);
export const selectComponentListByIdMap = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.componentLists.selectEntities,
);
export const selectParentComponentListForComponentId = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.tree.selectParentComponentListForComponentId,
);
export const selectListComponents = selectorPipe(
    selectComponentTree,
    localEditingTreeComponentList,
);
export const selectTreeLoaded = selectorPipe(
    selectComponentTree,
    ({ editingTree, cleanTree }) => !!(editingTree && cleanTree),
);
export const selectComponentPath = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.tree.selectComponentPath,
);
export const selectMoreThanNComponentsOfType = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.tree.selectContainsMoreThanNComponentsOfType,
);
export const selectNextComponentId = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.components.selectNextId,
);
export const selectNextComponentListId = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.componentLists.selectNextId,
);
export const selectExtractSubtree = selectorPipe(
    selectComponentTree,
    localEditingTreeSelectors.tree.selectExtractSubtree,
);
export const selectLegacyState = selectorPipe(
    selectComponentTree,
    (state: LocalState) => LegacyState.toLegacyTree(unwrapExists(state.editingTree)),
);
export const selectFirstFixedComponentWithTypeId = (
    state: RootState,
    typeId: number,
): Component | null => {
    const fixedList = selectComponentListById(
        state,
        FIXED_LIST_ID,
    );
    for (const id of fixedList?.componentIds ?? []) {
        const component = selectComponentById(state, id);
        if (component?.typeId === typeId) {
            return component;
        }
    }
    return null;
};

export const selectIsClean = createSelector(
    selectComponentTree,
    ({ cleanTree, editingTree }) => (
        cleanTree
            && editingTree
            && ComponentTree.equals(cleanTree, editingTree)
    ),
);
