import apiRequest, {
    ApiResponse,
} from '../apiRequest';
import { Asset } from './types';

type AssetCreateProps = {
  name: string,
  accessToken: string,
  studioId: string,
  organizationId: string,
};

const create = ({
    name,
    accessToken,
    organizationId,
}: AssetCreateProps): Promise<ApiResponse<Asset>> => (
    apiRequest<Asset>('/images', {
        method: 'POST',
        accessToken,
        body: {
            name,
            organizationId,
        },
    })
);

export default create;
