import * as R from 'ramda';

type PreviewButtonState = {
  attributes: PreviewButtonAttributes,
}

type PreviewButtonAttributes = {
  verificationEnabled: boolean,
  verified: boolean,
  tamperEnabled: boolean,
  tampered: boolean,
};

enum PreviewButtonActionType {
  UpdatePreviewAttribute = 'PAGES/EXPERIENCE_EDIT/PREVIEW_BUTTON/UPDATE_PREVIEW_ATTRIBUTE',
}

type UpdatePreviewAttributeAction = {
  type: PreviewButtonActionType.UpdatePreviewAttribute,
  key: keyof PreviewButtonAttributes,
  value: boolean
}
export const updatePreviewAttribute = (key: keyof PreviewButtonAttributes, value: boolean): UpdatePreviewAttributeAction => ({
    type: PreviewButtonActionType.UpdatePreviewAttribute,
    key,
    value,
});

const defaultPreviewAttributes: PreviewButtonState = {
    attributes: {
        verificationEnabled: false,
        verified: false,
        tamperEnabled: false,
        tampered: false,
    },
};

type PreviewButtonActions = UpdatePreviewAttributeAction | { type: '' };

const reducer = (state: PreviewButtonState = defaultPreviewAttributes, action: PreviewButtonActions) => {
    switch (action.type) {
        case PreviewButtonActionType.UpdatePreviewAttribute:
            return R.assocPath(['attributes', action.key], action.value, state);
        default:
            return state;
    }
};

const getState = (state: any): PreviewButtonState => state.pages.experienceEdit.previewButton;
export const getPreviewAttribute = (key: keyof PreviewButtonAttributes) => (state: any): boolean => (
    !!((getState(state) || {}).attributes || {})[key]
);

export default reducer;
