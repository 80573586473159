import { Page } from '../type';

export default {
    route: {
        path: '/organization/settings',
        exact: true,
    },
    loadable: {
        loader: () => import('./component'),
    },
    needsOrg: true,
} as Page;
