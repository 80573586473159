import {
    push,
    replace,
} from 'connected-react-router';
import {
    isRejectedWithValue,
} from '@reduxjs/toolkit';
import { Either } from 'monet';
import { AnyAction } from 'redux';
import * as Cookies from 'js-cookie';
import { SagaIterator } from 'redux-saga';
import {
    takeEvery,
    call,
    put,
    select,
    spawn,
    actionChannel,
    take,
} from 'redux-saga/effects';
import { Experience } from '../experience';
import createAsset from '../api/asset/create';
import duplicateAsset, { AssetDuplicationReference } from '../api/asset/duplicate';
import {
    login,
    listExperiences,
    getExperience,
    createExperience,
    updateExperience,
    uploadFile,
    destroyExperience,
    duplicateExperience,
    getOrganization,
    contextSwitch,
    DuplicateResponseType,
} from '../api';
import { hubspotRequest } from '../hubspot';
import {
    registerBusyRaw,
    resolveBusyRaw,
} from '../busy';
import messages from '../messages';
import { Future } from '../utils';
import { NotFoundResourceError } from '../utils/errors';
import { selectLoginRoute } from '../../pages/links';

// This is the parameter the V3 dashboard includes when linking to the Studio
const ORGANIZATION_ID_PARAM_NAME = 'organization_id';

export enum AuthStatus {
  NeedsLogin,
  HasSession,
  LoginFailed,
  MissingPermissions,
}

export enum AuthActionType {
  AttemptLogin = 'AUTH/ATTEMPT_LOGIN',
  LoginSuccess = 'AUTH/LOGIN_SUCCESS',
  LoginFailed = 'AUTH/LOGIN_FAILED',
  Logout = 'AUTH/LOGOUT',
  LoadSessionFromCookies = 'AUTH/LOAD_SESSION_FROM_COOKIES',
  SetOrganization = 'AUTH/SET_ORGANIZATION',
}

export type Email = string;
export type Password = string;

type LoginCredentials = {
  email: Email,
  password: Password,
}

export function loginSuccess({ accessToken, details }: Props) {
    return {
        type: AuthActionType.LoginSuccess,
        accessToken,
        details,
    };
}

export type Props = {
  accessToken: string,
  details: string,
};

export const logout = (clearOrg = false) => ({
    type: AuthActionType.Logout,
    clearOrg,
});

export const attemptLogin = (email: Email, password: Password) => ({
    type: AuthActionType.AttemptLogin,
    email,
    password,
});

export const loadSessionFromCookies = () => ({
    type: AuthActionType.LoadSessionFromCookies,
});

export const loginFailed = (message: string) => ({
    type: AuthActionType.LoginFailed,
    message,
});

export type OrganizationFuture = Future.Future<Organization, null>;
export const setOrganization = (organization: OrganizationFuture) => ({
    type: AuthActionType.SetOrganization,
    organization,
});

/* eslint-disable camelcase */
/* This structure comes from the platform */
export type SubscriptionConfiguration = {
    max_active_objects: number;
    interaction_cap: number;
    has_authentication: boolean;
    custom_domains: boolean;
};
/* eslint-enable camelcase */

export type Organization = {
  id: number;
  name: string;
  subscription?: SubscriptionConfiguration;
};

export type AuthState = {
  status: AuthStatus,
  accessToken: string | null,
  loginFailedMessage: string | null,
  organization: OrganizationFuture,
};

const initialState: AuthState = {
    status: AuthStatus.NeedsLogin,
    accessToken: null,
    loginFailedMessage: null,
    organization: Future.Unresolved,
};

const reducer = (state: AuthState = initialState, action: AnyAction) => {
    switch (action.type) {
        case AuthActionType.LoginSuccess:
            return {
                ...state,
                status: AuthStatus.HasSession,
                accessToken: action.accessToken,
                details: action.details,
                loginFailedMessage: null,
            };
        case AuthActionType.LoginFailed:
            return {
                ...state,
                loginFailedMessage: action.message,
            };
        case AuthActionType.Logout:
            return {
                ...initialState,
                organization: Future.Err(null),
            };
        case AuthActionType.SetOrganization:
            return {
                ...state,
                organization: action.organization,
            };
        default:
            return state;
    }
};

export default reducer;

// TODO: use global state
export const auth = (state: any): AuthState => state.auth;
export const sessionStatus = (state: any) => auth(state).status;
export const accessToken = (state: any) => auth(state).accessToken;
export const loginFailedMessage = (state: any) => auth(state).loginFailedMessage;
export const hasSession = (state: any) => sessionStatus(state) === AuthStatus.HasSession;
export const selectOrganizationFuture = (state: any): OrganizationFuture => (
    auth(state).organization
);
export const urlParams = (state: any): URLSearchParams | null => {
    const currentParams = new URLSearchParams(window.location.search);
    if (!currentParams.has(ORGANIZATION_ID_PARAM_NAME)) {
        return null;
    }
    const org = selectOrganizationFuture(state);
    const id = (
        Future.isSuccess(org)
            ? Future.unwrap(org).id
            : currentParams.get(ORGANIZATION_ID_PARAM_NAME)
    );
    const params = new URLSearchParams();
    if (id) {
        params.set(ORGANIZATION_ID_PARAM_NAME, id.toString());
    }
    return params;
};

const SESSION = 'SESSION';
const SESSION_VERSION = 'v0.1.0';
export function* loadSessionFromCookiesSaga() {
    const session = JSON.parse(Cookies.get(SESSION) || 'null');
    if (session) {
        const { accessToken, details } = session;
        yield put(loginSuccess({ accessToken, details }));
        yield call(getOrganizationInitSaga);
    } else {
        yield put(setOrganization(Future.Err(null)));
    }
}

export function* attemptLoginSaga({ email, password }: LoginCredentials): SagaIterator {
    if (!email || !password) {
        yield put(loginFailed(messages.loginInvalidCredentials));
        return;
    }
    const busyId = `Login:${Math.random()}`;
    yield put(registerBusyRaw(busyId));
    const response = yield call(login, { email, password });
    if (response.isRight()) {
        const { access_token: accessToken, details } = response.right();
        if (__IS_V2__) {
            yield spawn(hubspotRequest, '289c8115-7379-44a6-b235-55c5f3683b62', {
                email,
                firstname: details.name.first,
                lastname: details.name.last,
                bb_account_name: details.account.name,
                bb_account_id: details.account.id,
                bb_user_id: details.user_id,
                bb_user: true,
                bb_subscription_plan_name: details.account.subscription_plan.name,
                bb_subscription_plan_amount: details.account.subscription_plan.amount,
            });
        }
        Cookies.set(SESSION, JSON.stringify({ accessToken, details, version: SESSION_VERSION }));
        yield put(setOrganization(Future.Unresolved));
        yield put(loginSuccess({ accessToken, details }));
        yield call(getOrganizationInitSaga);
    } else {
        const { status } = response.left();
        switch (status) {
            case 400:
                yield put(loginFailed(messages.loginInvalidCredentials));
                break;
            case 403:
                yield put(loginFailed(messages.loginNoAccess));
                break;
            default:
                yield put(loginFailed(messages.fail));
                break;
        }
    }
    yield put(resolveBusyRaw(busyId));
}

// Do not call this saga directly
// the session state must also be cleared
// from the store
export function* logoutSaga({
    clearOrg,
}: { clearOrg: boolean, type: typeof AuthActionType.Logout }): SagaIterator {
    Cookies.set(SESSION, JSON.stringify(null));
    const path = yield select(selectLoginRoute, clearOrg);
    yield put(push(path));
}

export function* sessionUploadFile({ file }: { file: File }): SagaIterator {
    return yield call(handleRequestFailure, uploadFile, { file });
}

export function* sessionCreateAsset({ name, studioId }: { name: string, studioId: string }): SagaIterator {
    const organizationFuture: ReturnType<typeof selectOrganizationFuture> = yield select(selectOrganizationFuture);
    const organizationId = Future.unsafeUnwrap(organizationFuture).id.toString();
    return yield call(handleRequestFailure, createAsset, { name, studioId, organizationId });
}

export function* sessionDuplicateAssets({
    assetReferences,
}: { assetReferences: Array<AssetDuplicationReference> }): SagaIterator {
    const organizationFuture: ReturnType<typeof selectOrganizationFuture> = yield select(selectOrganizationFuture);
    const organizationId = Future.unsafeUnwrap(organizationFuture).id.toString();
    return yield call(handleRequestFailure, duplicateAsset, { assetReferences, organizationId });
}

export function* sessionListExperiences(): SagaIterator {
    return yield call(handleRequestFailure, listExperiences);
}

export function* sessionCreateExperience(
    experience: Omit<Experience, 'id' | 'asset_associations' | 'url'>,
): SagaIterator {
    return yield call(handleRequestFailure, createExperience, { experience });
}

export function* sessionUpdateExperience(experience: Experience): SagaIterator {
    return yield call(handleRequestFailure, updateExperience, { experience });
}

export function* sessionGetExperience(id: string): SagaIterator {
    return yield call(handleRequestFailure, getExperience, { id });
}

export function* sessionDestroyExperience(id: string): SagaIterator {
    return yield call(handleRequestFailure, destroyExperience, { id });
}

export { DuplicateResponseType };
export function* sessionDuplicateExperience(
    inputArgs: { copyExperienceIds: Array<string>, toOrganization: string },
): SagaIterator {
    return yield call(
        handleRequestFailure,
        duplicateExperience,
        inputArgs,
    );
}

export function* sessionGetOrganization(): SagaIterator {
    return yield call(handleRequestFailure, getOrganization, {});
}

export function* sessionContextSwitch(organizationId: string): SagaIterator {
    return yield call(handleRequestFailure, contextSwitch, {
        organizationId,
    });
}

export function* handleRequestFailure<I>(request: ((args: any) => I), props: any = {}): (
  SagaIterator & Iterator<any, Either<I, string>>
) {
    const at = yield select(accessToken);
    if (at) {
        const response = yield call(request, { ...props, accessToken: at });
        if (response.isLeft()) {
            if (response.left().status === 404) {
                return Either.Left(new NotFoundResourceError());
            }
            yield put(logout());
        }
        return response;
    }
    yield put(logout());
    return Either.Left('No Access Token');
}

export function* getOrganizationInitSaga() {
    const params = new URLSearchParams(location.search);

    const chosenId = (
        __IS_V2__
            ? null
            : params.get(ORGANIZATION_ID_PARAM_NAME)
    );

    const response: Either<unknown, { organization: Organization }> = yield (
        chosenId
            ? call(sessionContextSwitch, chosenId)
            : call(sessionGetOrganization)
    );

    if (response.isRight()) {
        try {
            const { organization } = response.right();

            const organizationId = organization.id.toString();

            if (chosenId && organizationId !== chosenId) {
                const params = new URLSearchParams(window.location.search);

                params.set(ORGANIZATION_ID_PARAM_NAME, organizationId);

                yield put(replace(
                    [
                        window.location.pathname,
                        '?',
                        params.toString(),
                    ].join(''),
                ));
            }

            yield put(setOrganization(Future.Success(organization)));
        } catch (e) {
            yield put(setOrganization(Future.Err(null)));
        }
    } else {
        yield put(setOrganization(Future.Err(null)));
    }
}

export function* watchForFailedRequests(): SagaIterator {
    const actions = yield actionChannel('*');
    while (true) {
        const action = yield take(actions);
        if (isRejectedWithValue(action)) {
            const { status } = action.error;
            if (status === 401 || status === 403 || status >= 500) {
                yield put(logout());
            }
        }
    }
}

export function* saga() {
    yield takeEvery(AuthActionType.AttemptLogin as any, attemptLoginSaga);
    yield takeEvery(AuthActionType.Logout, logoutSaga);
    yield takeEvery(AuthActionType.LoadSessionFromCookies, loadSessionFromCookiesSaga);
    yield spawn(watchForFailedRequests);
}
