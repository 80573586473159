import {
    createAction,
    AnyAction,
} from '@reduxjs/toolkit';
import { Experience } from './versioning';

export type { Experience };

export const LOAD_EXPERIENCE = 'EXPERIENCE/LOAD';

export const loadExperience = (experience: Experience): LoadExperienceAction => ({
    type: LOAD_EXPERIENCE,
    experience,
});

export type LoadExperienceAction = {
  type: typeof LOAD_EXPERIENCE,
  experience: Experience,
};

loadExperience.match = (action: AnyAction): action is LoadExperienceAction => (
    action.type === LOAD_EXPERIENCE
);

export const revertExperience = createAction('PAGES/EXPERIENCE_EDIT/REVERT');
