import { connect } from 'react-redux';
import TabRouter, {
    TabConfig,
} from './component';
import {
    getSelectedTab,
    selectTab,
} from '../reducer';

type TabRouterContainerProps = {
  className?: string,
  tabRouterId: string,
  defaultTabs?: Array<string>,
  tabs: Array<TabConfig>,
};

const mapStateToProps = (state: any, props: TabRouterContainerProps) => ({
    selectedTab: getSelectedTab(props.tabRouterId, state),
});

const mapDispatchToProps = {
    selectTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabRouter);
