import { combineReducers } from 'redux';
import { Either } from 'monet';
import { push } from 'connected-react-router';
import {
    select,
    put,
    call,
    takeEvery,
    spawn,
    delay,
} from 'redux-saga/effects';
import { reducer as focusReducer } from './focus';
import {
    reducer as searchReducer,
    saga as searchSaga,
    search,
} from './search';
import {
    sessionCreateExperience,
    sessionDestroyExperience,
    sessionDuplicateExperience,
    sessionListExperiences,
    logout,
    selectOrganizationFuture,
    DuplicateResponseType,
} from '../../global/auth';
import { defaultExperience, Experience } from '../../global/experience';
import {
    loadExperiences,
    fetchExperiences,
} from '../../global/experiences';
import {
    registerBusyRaw,
    resolveBusyRaw,
} from '../../global/busy';
import {
    openModalSaga,
    OpenModalResult,
} from '../../global/modal';
import messages from '../../global/messages';
import { selectExperienceEditRoute } from '../links';
import { Future } from '../../global/utils';

enum ExperiencesActionType {
  CreateExperience = 'PAGES/EXPERIENCES/CREATE_EXPERIENCE',
  DestroyExperience = 'PAGES/EXPERIENCES/DESTROY_EXPERIENCE',
  DuplicateExperience = 'PAGES/EXPERIENCES/DUPLICATE_EXPERIENCE',
}

export default combineReducers({
    search: searchReducer,
    focus: focusReducer,
});

export const createExperience = () => ({
    type: ExperiencesActionType.CreateExperience,
});

export const destroyExperience = (id: string) => ({
    type: ExperiencesActionType.DestroyExperience,
    id,
});

export const duplicateExperience = (id: string) => ({
    type: ExperiencesActionType.DuplicateExperience,
    id,
});

export function* loadPage() {
    yield put(fetchExperiences());
    yield put(search(''));
}

export function* createExperienceSaga() {
    const busyId = `createExperience-${Math.random()}`;
    yield put(registerBusyRaw(busyId));
    try {
        const response: Either<unknown, Experience> = yield call(sessionCreateExperience, defaultExperience);
        if (response.isRight()) {
            const url: string = yield select(selectExperienceEditRoute, response.right().id);
            yield put(push(url));
        } else {
            yield call(logout);
        }
    } finally {
        yield put(resolveBusyRaw(busyId));
    }
}

export function* destroyExperienceSaga({ id }: { id: string }) {
    const confirmation: OpenModalResult<'confirmation'> = yield call(openModalSaga, {
        content: 'confirmation',
        contentConfig: {
            bodyText: messages.experienceDeleteConfirmation,
        },
        modalConfig: {
            implicitDismiss: true,
        },
    });
    if (confirmation.isLeft() || !(confirmation.right()?.confirmed ?? false)) {
        return;
    }
    const busyId = `deleteExperience-${Math.random()}`;
    yield put(registerBusyRaw(busyId));

    yield call(function* () {
        const isDestroyed: SagaReturnType<typeof sessionDestroyExperience> = (
            yield call(sessionDestroyExperience, id)
        );
        if (isDestroyed.isLeft()) { return; }

        const experiences: Either<unknown, Array<Experience>> = yield call(sessionListExperiences);
        if (experiences.isLeft()) { return; }

        yield put(loadExperiences(experiences.right()));
    });

    yield put(resolveBusyRaw(busyId));
}

export function* duplicateExperienceSaga({ id }: { id: string }) {
    const busyId = `duplicateExperience-${Math.random()}`;
    yield put(registerBusyRaw(busyId));

    const organizationFuture: ReturnType<typeof selectOrganizationFuture> = yield select(selectOrganizationFuture);
    const toOrganization = Future.unsafeUnwrap(organizationFuture).id.toString();

    const duplicateResponse: DuplicateResponseType = yield call(
        sessionDuplicateExperience,
        {
            copyExperienceIds: [id],
            toOrganization,
        },
    );

    if (duplicateResponse.isLeft()) {
        yield call(logout);
    } else if (duplicateResponse.right().succeeded.length > 0) {
        const url: ReturnType<typeof selectExperienceEditRoute> = (
        // we can only have one experience because we are only asking for one experience
            yield select(selectExperienceEditRoute, duplicateResponse.right().succeeded[0].toExperience)
        );
        yield put(push(url));
    } else {
    // TODO: ES-845: we don't log out here but we will add a "something went wrong" in case of no succeeded
    // for now delay a couple seconds to prevent user from mashing the button
        yield delay(2000);
    }
    yield put(resolveBusyRaw(busyId));
}

export function* saga() {
    yield spawn(searchSaga);
    yield takeEvery(ExperiencesActionType.CreateExperience, createExperienceSaga);
    yield takeEvery(ExperiencesActionType.DestroyExperience as any, destroyExperienceSaga);
    yield takeEvery(ExperiencesActionType.DuplicateExperience as any, duplicateExperienceSaga);
}
