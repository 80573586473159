import { createAction } from '@reduxjs/toolkit';
import { eventChannel, SagaIterator } from 'redux-saga';
import {
    call,
    put,
    select,
    spawn,
    takeLatest,
    cancelled,
} from 'redux-saga/effects';
import { Either } from 'monet';
import { ComponentTree as RawComponentTree } from '../../../global/componentTree';
import { RootState } from '../../../global/rootReducer';
import { DuplicationResponseBody } from '../../../global/api/asset/duplicate';
import { selectOrganizationFuture, sessionDuplicateAssets } from '../../../global/auth';
import {
    unwrapExists,
    isExisting,
    unreachable,
    Future,
} from '../../../global/utils';
import { ComponentTypeData } from '../../../global/componentTypeData';
import { canAddComponents } from '../../../global/componentTypeData/canAddComponents';
import {
    ComponentTree,
    AssetAssociations,
} from '../../../global/experience';
import * as Selection from '../selection';
import {
    createCopyMessage,
    parseCopyPayload,
    CopyPayload,
} from './payload';

const copyComponent = createAction('PAGES/EXPERIENCE_EDIT/COPY_COMPONENT');
function* copyComponentSaga(): SagaIterator {
    const component: ComponentTree.Component = yield select(Selection.selectSelectedSingleComponent);
    const sequentialComponents: Array<ComponentTree.Component> = yield select(Selection.selectSelectedSequentialComponents);
    const listId: Array<ComponentTree.ComponentId> = yield select(Selection.selectSelectedComponentListId);

    const componentListId = component?.parentListId ?? sequentialComponents?.[0]?.parentListId ?? listId;
    const start = component?.id ?? sequentialComponents?.[0]?.id ?? 'start';
    const end = component?.id ?? sequentialComponents?.[sequentialComponents.length - 1]?.id ?? 'end';

    if (componentListId == null) { return; }

    const subTree: ReturnType<typeof ComponentTree.selectExtractSubtree> = yield select((state: RootState) => (
        ComponentTree.selectExtractSubtree(state, {
            componentListId,
            start,
            end,
        })
    ));
    const assetIds: ReturnType<typeof AssetAssociations.selectAssetIdsFromTree> = yield call(AssetAssociations.selectAssetIdsFromTree, subTree);

    const organizationFuture: ReturnType<typeof selectOrganizationFuture> = yield select(selectOrganizationFuture);
    if (!Future.isSuccess(organizationFuture)) { return unreachable(); }
    const organization = Future.unwrap(organizationFuture);

    const assets: ReturnType<typeof AssetAssociations.selectEditingAssetWithIds> = (
        yield select(AssetAssociations.selectEditingAssetWithIds, assetIds)
    );

    const usedAssets = assets.filter(({ assetId }) => assetIds.has(assetId));

    const copyMessage = yield call(createCopyMessage, {
        componentTree: subTree,
        organizationId: organization.id,
        assets: usedAssets,
    });

    navigator.clipboard.writeText(copyMessage);
}

type PasteComponentPayload = {
  txt: string,
  focus: { type: 'component' | 'list', id: number },
};
const pasteComponent = createAction<PasteComponentPayload>('PAGES/EXPERIENCE_EDIT/PASTE_COMPONENT');
function* pasteComponentSaga({
    payload: { txt, focus },
}: ReturnType<typeof pasteComponent>): SagaIterator {
    const organizationFuture: ReturnType<typeof selectOrganizationFuture> = yield select(selectOrganizationFuture);
    if (!Future.isSuccess(organizationFuture)) { return unreachable(); }
    const currentOrganization = Future.unwrap(organizationFuture);

    const component = (
        focus.type === 'component'
            ? yield select((state: RootState) => (
                ComponentTree.selectComponentById(state, focus.id)
            ))
            : null
    );
    const listId: ComponentTree.ComponentListId = (
        focus.type === 'list'
            ? focus.id
            : (
                focus.type === 'component' && isExisting(component)
                    ? component.parentListId
                    : unreachable()
            )
    );

    const result: CopyPayload | null = yield call(parseCopyPayload, txt);
    if (result) {
        let { componentTree: tree, assets } = result;
        const {
            organizationId,
        } = result;

        const componentTypes: Array<ComponentTypeData> = yield select(
            (state: RootState) => canAddComponents(listId, state),
        );
        const componentTypeIds = new Set(componentTypes.map(({ id }) => id));

        const rootList = unwrapExists(
            RawComponentTree.localSelectors.componentLists.selectById(tree, 0),
        );

        const allAddableComponents = (
            rootList.componentIds.every(
                (
                    componentId: RawComponentTree.Components.ComponentId,
                ): boolean => {
                    const component = unwrapExists(
                        RawComponentTree.localSelectors.components.selectById(
                            tree,
                            componentId,
                        ),
                    );
                    return componentTypeIds.has(component.typeId);
                },
            )
        );
        if (!allAddableComponents) {
            return;
        }

        // Rewrite asset references to use duplicates owned by current organization
        if (
            !__IS_V2__
                && organizationId !== currentOrganization.id
                && assets.length > 0
        ) {
            const duplicationMappingsResponse: Either<unknown, DuplicationResponseBody> = yield call(sessionDuplicateAssets, {
                assetReferences: assets.map(({ filename, assetId, assetVersionId }) => ({
                    assetId,
                    assetVersionId,
                    hash: unwrapExists(filename.split('.')[0]),
                })),
            });

            if (duplicationMappingsResponse.isLeft()) {
                throw duplicationMappingsResponse.left();
            }

            const assetIdMapping = new Map<string, string>();
            const assetVersionIdMapping = new Map<string, string>();
            for (const {
                fromAssetId,
                toAssetId,
                fromAssetVersionId,
                toAssetVersionId,
            } of duplicationMappingsResponse.right().assetMappings) {
                assetIdMapping.set(fromAssetId, toAssetId);
                assetVersionIdMapping.set(fromAssetVersionId, toAssetVersionId);
            }

            tree = AssetAssociations.mapAssetIdsFromTree(assetIdMapping, tree);
            assets = assets.map(({
                filename,
                assetType,
                assetId,
                assetVersionId,
            }) => ({
                filename,
                assetType,
                assetId: unwrapExists(assetIdMapping.get(assetId)),
                assetVersionId: unwrapExists(assetVersionIdMapping.get(assetVersionId)),
            }));
        }

        yield put(AssetAssociations.addAssets(assets));

        yield put(ComponentTree.mountSubtree({
            tree,
            insertPosition: {
                componentListId: listId,
                position: (
                    focus.type === 'component'
                        ? { afterId: component.id }
                        : (
                            focus.type === 'list'
                                ? 'end'
                                : unreachable()
                        )
                ),
            },
        }));
    }
}

function* watchForCopyPasteComponentSaga(): SagaIterator {
    const copyComponentsEventsChannel = eventChannel((emitter) => {
        const callback = (e: Event) => {
            if ((document.activeElement as HTMLElement | undefined)?.dataset.focusId) {
                e.preventDefault();
                emitter(copyComponent());
            }
        };
        window.addEventListener('copy', callback);
        return () => {
            window.removeEventListener('copy', callback);
        };
    });

    const pasteComponentsEventsChannel = eventChannel((emitter) => {
        const callback = (e: Event) => {
            const activeEl = (document.activeElement as HTMLElement | undefined);

            const focus = (
                activeEl?.dataset.focusId
                    ? {
                        type: 'component' as const,
                        id: parseInt(activeEl?.dataset.focusId, 10),
                    }
                    : (
                        activeEl?.dataset.focusListId
                            ? {
                                type: 'list' as const,
                                id: parseInt(activeEl?.dataset.focusListId, 10),
                            }
                            : null
                    )
            );

            if (focus) {
                e.preventDefault();
                const txt: string = (
                    (event as any).clipboardData
                        ?? (window as any).clipboardData
                )?.getData('text');
                if (txt) {
                    emitter(pasteComponent({
                        txt,
                        focus,
                    }));
                }
            }
        };
        window.addEventListener('paste', callback);
        return () => {
            window.removeEventListener('paste', callback);
        };
    });

    try {
        yield takeLatest(copyComponentsEventsChannel, copyComponentSaga);
        yield takeLatest(pasteComponentsEventsChannel, pasteComponentSaga);
    } finally {
        if (yield cancelled()) {
            copyComponentsEventsChannel.close();
            pasteComponentsEventsChannel.close();
        }
    }
}

export function* saga() {
    yield takeLatest(copyComponent.toString(), copyComponentSaga);
    yield takeLatest(pasteComponent.toString(), pasteComponentSaga);

    yield spawn(watchForCopyPasteComponentSaga);
}
