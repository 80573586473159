export * as Future from './future';

export const isExisting = <T>(val: T): val is Exclude<T, null | undefined> => (
    val != null
);

export const unwrapExists = <T>(val: T): Exclude<T, null | undefined> => {
    if (!isExisting(val)) {
        throw new Error('asserted value is nullish');
    }
    return val;
};

export const assertExists: <T>(val: T) => asserts val is Exclude<T, null | undefined> = (
    (val: unknown) => { unwrapExists(val); }
);

export const unreachable = (): never => {
    throw new Error('Unreachable');
};

export const mapValues = <V, K>(func: (val: V) => K, obj: Record<string, V>): Record<string, K> => {
    const result: Record<string, K> = {};
    for (const k of Object.keys(obj)) {
        result[k] = func(obj[k]);
    }
    return result;
};

export const isPlainObject = (value: unknown): value is Record<string, unknown> => {
    if (typeof value !== 'object' || value === null) {
        return false;
    }

    let proto = value;
    while (Object.getPrototypeOf(proto) !== null) {
        proto = Object.getPrototypeOf(proto);
    }

    return Object.getPrototypeOf(value) === proto;
};

export const selectorPipe = <A, B, C, P extends Array<any>>(
    baseSel: (a: A) => B,
    childSel: (a: B, ...params: P) => C,
) => (a: A, ...params: P): C => (
        childSel(baseSel(a), ...params)
    );

export const deepFreeze = <A extends Record<string, unknown>>(o: A): A => {
    // Retrieve the property names defined on object
    const propNames = Object.getOwnPropertyNames(o);

    // Freeze properties before freezing self

    for (const name of propNames) {
        const value = o[name];

        if (value && typeof value === 'object') {
            deepFreeze(value as Record<string, unknown>);
        }
    }

    return Object.freeze(o);
};
