import * as R from 'ramda';
import { AnyAction } from 'redux';
import { createAction } from '@reduxjs/toolkit';
import { Experience, loadExperience, revertExperience } from '../shared';
import { RootState } from '../../rootReducer';
import { assertExists, unwrapExists } from '../../utils';

type RootAttributeState<T> = null | {
  edit: T,
  clean: T,
};

export const ROOT_ATTRIBUTES = ['name', 'description', 'inside_of_head', 'end_of_body'] as const;

type Key = 'name' | 'description' | 'inside_of_head' | 'end_of_body';

export const createRootAttributeAdapter = <K extends Key>(
    name: K,
    fallbackValue: Experience[K],
) => {
  type Val = Experience[K];

  const update = createAction<Val>(`EXPERIENCE/UPDATE_ROOT_ATTRIBUTE/${name}`);

  const reducer = (
      state: RootAttributeState<Val> = null,
      action: AnyAction,
  ): RootAttributeState<Val> => {
      if (loadExperience.match(action)) {
          const val = action.experience[name];
          return {
              edit: val ?? fallbackValue,
              clean: val ?? fallbackValue,
          };
      }
      if (revertExperience.match(action)) {
          assertExists(state);
          return {
              ...state,
              edit: state.clean,
          };
      }
      if (update.match(action)) {
          return {
              ...unwrapExists(state),
              edit: action.payload,
          };
      }

      return state;
  };

  const selectIsClean = (state: RootState): boolean => {
      const { edit, clean } = unwrapExists(state.experience[name]);
      return R.equals(edit, clean);
  };

  const selectValue = (state: RootState): Val | undefined => (
      unwrapExists(state.experience[name]).edit
  );

  return {
      update,
      reducer,
      selectIsClean,
      selectValue,
  };
};
