import produce from 'immer';
import * as ComponentTree from './componentTree';
import {
    mapValues,
    unwrapExists,
} from '../utils';

const FIXED_COMPONENT_TYPE_IDS = ['13', '34'];

type LegacyComponent = {
  id: string;
  typeId: string;
  options?: Record<string, unknown>;
  componentLists?: Record<string, string>;
};

type LegacyComponentList = {
  id: string;
  componentTypeId: string;
  componentKey: string;
  items: Array<LegacyComponent>;
};

export type LegacyTree = {
  items: Array<LegacyComponent>;
  lists?: Array<LegacyComponentList>;
  nextId: number;
  nextListId?: number;
};

const fromLegacyComponent = (
    c: LegacyComponent,
    listId: string,
): ComponentTree.Components.Component => ({
    id: parseInt(c.id, 10),
    typeId: parseInt(c.typeId, 10),
    options: c.options ?? {},
    parentListId: parseInt(listId, 10),
    ...(
        c.componentLists
            ? { componentLists: mapValues((id: string): number => parseInt(id, 10), c.componentLists) }
            : null
    ),
});

const fromLegacyComponentList = (
    l: LegacyComponentList,
    parentComponentIdMap: Map<string, number>,
): ComponentTree.ComponentLists.ComponentList => ({
    id: parseInt(l.id, 10),
    componentIds: l.items.map((c: LegacyComponent) => parseInt(c.id, 10)),
    parentComponentId: unwrapExists(parentComponentIdMap.get(l.id)),
});

const addToParentComponentIdMapMutating = (
    component: LegacyComponent,
    map: Map<string, number>,
) => {
    if (component.componentLists) {
        for (const k of Object.keys(component.componentLists)) {
            const listId = component.componentLists[k];
            map.set(listId, parseInt(component.id, 10));
        }
    }
};

export const fromLegacyTree = (legacyTree: LegacyTree): ComponentTree.ComponentTree => (
    produce(ComponentTree.getInitialState(), (s: ComponentTree.ComponentTree) => {
        const parentComponentIdMap: Map<string, number> = new Map();

        const rootListIds = [];
        const fixedTopListIds = [];
        for (const component of legacyTree.items) {
            addToParentComponentIdMapMutating(component, parentComponentIdMap);

            const id = parseInt(component.id, 10);
            if (FIXED_COMPONENT_TYPE_IDS.includes(component.typeId)) {
                ComponentTree.Components.addOne(s.components, fromLegacyComponent(component, '-1'));
                fixedTopListIds.push(id);
            } else {
                ComponentTree.Components.addOne(s.components, fromLegacyComponent(component, '0'));
                rootListIds.push(id);
            }
        }
        for (const list of (legacyTree.lists ?? [])) {
            for (const component of list.items) {
                addToParentComponentIdMapMutating(component, parentComponentIdMap);
                ComponentTree.Components.addOne(s.components, fromLegacyComponent(component, list.id));
            }
        }

        ComponentTree.ComponentLists.upsertMany(
            s.componentLists,
            [
                {
                    id: ComponentTree.ComponentLists.ROOT_LIST_ID,
                    componentIds: rootListIds,
                    parentComponentId: null,
                },
                {
                    id: ComponentTree.ComponentLists.FIXED_LIST_ID,
                    componentIds: fixedTopListIds,
                    parentComponentId: null,
                },
            ],
        );

        for (const list of (legacyTree.lists ?? [])) {
            ComponentTree.ComponentLists.addOne(
                s.componentLists,
                fromLegacyComponentList(list, parentComponentIdMap),
            );
        }

        s.components.ids.sort((a: number, b: number) => a - b);
        s.componentLists.ids.sort((a: number, b: number) => a - b);

        s.components.nextId = legacyTree.nextId;
        s.componentLists.nextId = legacyTree.nextListId ?? 1;
    })
);

const toLegacyComponent = (component: ComponentTree.Components.Component): LegacyComponent => ({
    id: String(component.id),
    typeId: String(component.typeId),
    options: component.options,
    ...(
        component.componentLists
            ? { componentLists: mapValues(String, component.componentLists) }
            : null
    ),
});

const findKey = <K extends string | number, V>(
    func: (v: V) => boolean,
    map: Record<K, V>,
): K | undefined => {
    for (const k of Object.keys(map) as Array<K>) {
        if (func(map[k])) {
            return k;
        }
    }
    return undefined;
};

const toLegacyComponentList = (
    componentList: ComponentTree.ComponentLists.ComponentList,
    components: Record<number, ComponentTree.Components.Component | undefined>,
): LegacyComponentList => {
    const parentComponent = unwrapExists(components[unwrapExists(componentList.parentComponentId)]);
    return ({
        id: String(componentList.id),
        componentTypeId: String(parentComponent.typeId),
        componentKey: unwrapExists(findKey(
            (id: number) => id === componentList.id,
            unwrapExists(parentComponent.componentLists),
        )),
        items: componentList.componentIds.map((id: number) => (
            toLegacyComponent(unwrapExists(components[id]))
        )),
    });
};

export const toLegacyTree = (tree: ComponentTree.ComponentTree): LegacyTree => {
    const items = [];
    const lists = [];

    const listMap = ComponentTree.localSelectors.componentLists.selectEntities(tree);
    const componentMap = ComponentTree.localSelectors.components.selectEntities(tree);

    for (const lid of Object.keys(listMap)) {
        const list = unwrapExists(listMap[lid]);
        if (list.id === ComponentTree.ComponentLists.ROOT_LIST_ID) {
            for (const id of list.componentIds) {
                items.push(toLegacyComponent(unwrapExists(componentMap[id])));
            }
        } else if (list.id === ComponentTree.ComponentLists.FIXED_LIST_ID) {
            const fixedTop = list.componentIds.map(
                (id: number) => toLegacyComponent(unwrapExists(componentMap[id])),
            );
            items.splice(0, 0, ...fixedTop);
        } else {
            lists.push(toLegacyComponentList(list, componentMap));
        }
    }

    return {
        items,
        lists,
        nextId: ComponentTree.localSelectors.components.selectNextId(tree),
        nextListId: ComponentTree.localSelectors.componentLists.selectNextId(tree),
    };
};
