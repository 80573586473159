import { connect } from 'react-redux';
import Auth from './component';
import {
    attemptLogin,
    loginFailedMessage,
} from './duck';
import { isBusy } from '../busy';

const mapStateToProps = (state) => ({
    isBusy: isBusy(state),
    loginFailedMessage: loginFailedMessage(state),
});

const mapDispatchToProps = {
    attemptLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
