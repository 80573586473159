import * as R from 'ramda';
import * as _ from 'lodash';
import {
    put,
    debounce,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import {
    experienceItems,
    ExperienceInfo,
} from '../../../global/experiences';
import { focusExperience } from '../focus';

type SearchName = {
  type: 'name',
  value: string,
}

type SearchId = {
  type: 'id',
  value: string,
}

type SearchTerm = null | SearchName | SearchId;

const SEARCH = 'PAGES/EXPERIENCES/SEARCH/SEARCH';
const SET_SEARCH_TERM = 'PAGES/EXPERIENCES/SEARCH/SET_SEARCH_TERM';

type SetSearchTermAction = {
  type: typeof SET_SEARCH_TERM,
  value: SearchTerm,
}

const setSearchTerm = (value: SearchTerm): SetSearchTermAction => ({
    type: SET_SEARCH_TERM,
    value,
});

type SearchAction = {
  type: typeof SEARCH,
  term: string,
}

export const search = (term: string): SearchAction => ({
    type: SEARCH,
    term: R.trim(term).toLowerCase(),
});

type OtherAction = {
  type: ''
}

type Actions = SetSearchTermAction | SearchAction | OtherAction;

type State = {
  term: SearchTerm,
}

const initialState: State = {
    term: null,
};

const reducer = (state: State = initialState, action: Actions) => {
    switch (action.type) {
        case SET_SEARCH_TERM:
            return {
                ...state,
                term: action.value,
            };
        default:
            return state;
    }
};

export default reducer;

const searchState = (state: any): State => state.pages.experiences.search;
const searchTerm = (state: any) => searchState(state).term;

export const results = createSelector(
    experienceItems,
    searchTerm,
    (items: ExperienceInfo[] | null, s: SearchTerm) => {
        if (!s || !items) { return items; }
        const search = new RegExp(_.escapeRegExp(s.value), 'i');
        return items.filter((i: ExperienceInfo) => search.test(i[s.type]));
    },
);

export function* searchSaga({ term }: { term: string }) {
    yield put(focusExperience(null));
    if (!term) {
        yield put(setSearchTerm(null));
        return;
    }
    if (term.match(/^bluebite:es:/)) {
        yield put(setSearchTerm({ type: 'id', value: term }));
        return;
    }
    yield put(setSearchTerm({ type: 'name', value: term }));
}

export function* saga() {
    yield debounce(500, SEARCH as any, searchSaga as any);
}
