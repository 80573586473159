import { SagaIterator } from 'redux-saga';
import {
    call,
    actionChannel,
    take,
    put,
    spawn,
    select,
    race,
} from 'redux-saga/effects';
import { Either } from 'monet';
import { id } from '../experience';
import {
    sessionUploadFile,
    sessionCreateAsset,
} from '../auth';
import { AssetAssociation } from '../api/asset';
import uploadAsset from '../api/asset/upload';
import { openModalSaga } from '../modal';
import Messages from '../messages';

const START_UPLOAD = 'UPLOAD_FILE/START_UPLOAD';
const UPLOAD_FINISHED = 'UPLOAD_FILE/UPLOAD_FINISHED';
const UPLOAD_FAILED = 'UPLOAD_FILE/UPLOAD_FAILED';

export const uploadFile = (
    name: string,
    file: File,
    uploadId: string,
): UploadFileAction => ({
    type: START_UPLOAD,
    file,
    uploadId,
    name,
});

type UploadFileAction = {
  type: typeof START_UPLOAD,
  name: string,
  file: File,
  uploadId: string,
}

export const uploadFinished = (
    asset: string | AssetAssociation,
    uploadId: string,
): UploadFinishedAction => ({
    type: UPLOAD_FINISHED,
    asset,
    uploadId,
});

type UploadFinishedAction = {
  type: typeof UPLOAD_FINISHED,
  asset: string | AssetAssociation,
  uploadId: string,
}

export const uploadFailed = (uploadId: string): UploadFailedAction => ({
    type: UPLOAD_FAILED,
    uploadId,
});

type UploadFailedAction = {
  type: typeof UPLOAD_FAILED,
  uploadId: string,
}

function* handleError(uploadId: string, messageName: keyof typeof Messages) {
    yield put(uploadFailed(uploadId));
    yield call(openModalSaga, {
        content: 'confirmation',
        contentConfig: {
            bodyText: Messages[messageName],
            acceptText: 'Ok',
            noReject: true,
        },
        modalConfig: {
            implicitDismiss: true,
        },
    });
}

function* watchForUploadFilesV3GetResult(
    name: string,
    studioId: string,
    file: File,
): (
  SagaIterator
  & Iterator<any, Either<string, never> | Resolve<ReturnType<typeof uploadAsset>>>
) {
    const assetResponse = (
        yield call(sessionCreateAsset, { name, studioId })
    );
    if (assetResponse.isLeft()) { return assetResponse; }
    const asset = assetResponse.right();
    const assetVersion = asset.assetVersions[0];
    if (!assetVersion) {
        return Either.Left('Failed to create asset version.');
    }
    if (!assetVersion.token) {
        return Either.Left('Upload token expired.');
    }
    const assetAssociations: Resolve<ReturnType<typeof uploadAsset>> = yield call(
        uploadAsset,
        {
            assetToken: assetVersion.token,
            file,
        },
    );
    return assetAssociations;
}

function* watchForUploadFileV3(): SagaIterator {
    const uploadStartChannel = yield actionChannel(START_UPLOAD);
    while (true) {
        const { name, file, uploadId } = (yield take(uploadStartChannel)) as UploadFileAction;
        const studioId = yield select(id);
        try {
            const result: SagaReturnType<typeof watchForUploadFilesV3GetResult> = yield call(
                watchForUploadFilesV3GetResult,
                name,
                studioId,
                file,
            );

            if (result.isRight()) {
                const asset = result.right().data;
                yield put(uploadFinished(asset, uploadId));
            } else {
                yield call(handleError, uploadId, 'invalidFileUpload');
            }
        } catch (e) {
            yield call(handleError, uploadId, 'invalidFileUpload');
        }
    }
}

function* watchForUploadFileV2(): SagaIterator {
    const uploadStartChannel = yield actionChannel(START_UPLOAD);
    while (true) {
        const { file, uploadId } = (yield take(uploadStartChannel)) as UploadFileAction;
        try {
            const result: SagaReturnType<typeof sessionUploadFile> = yield call(sessionUploadFile, { file });

            if (result.isRight()) {
                const { url } = result.right();
                yield put(uploadFinished(url, uploadId));
            } else {
                const response = result.left();
                if (/Maximum/.test(response.message)) {
                    yield call(handleError, uploadId, 'invalidFileSize');
                } else {
                    yield call(handleError, uploadId, 'invalidFileUpload');
                }
            }
        } catch (e) {
            yield call(handleError, uploadId, 'invalidFileUpload');
        }
    }
}

export function* uploadFileSaga({ name, file }: { name: string, file: File }): (
  SagaIterator & Iterator<any, Either<UploadFailedAction, UploadFinishedAction>>
) {
    const uploadId = `${Math.random()}`;
    yield put(uploadFile(name, file, uploadId));
    let result;
    do {
        result = yield race({
            finished: take(UPLOAD_FINISHED),
            failed: take(UPLOAD_FAILED),
        });
    } while ((result.finished || result.failed).uploadId !== uploadId);
    return result.finished
        ? Either.Right(result.finished)
        : Either.Left(result.failed);
}

export function* saga() {
    yield spawn(__IS_V2__ ? watchForUploadFileV2 : watchForUploadFileV3);
}
