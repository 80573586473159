import { Either } from 'monet';
import { ServerError } from './serverError';
import parseResponse from './parseResponse';

export const SERVER_ERROR = 'SERVER_ERROR';

export const API_BASE = __API_BASE__;

export const defaultHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
};

function rawApiRequest(route: string, {
    apiBase,
    accessToken,
    body,
    method,
    headers,
    subdomain,
    rawBody,
    oldAuth,
}: Options) {
    const DOMAIN = (apiBase || API_BASE)
        .replace('api-studio.', subdomain || 'api-studio.')
        .replace('api.studio.', subdomain || 'api.studio.')
        .replace('ese.', subdomain || 'ese.');

    return fetch(`${DOMAIN}${route}`, {
        method: method || 'GET',
        ...(method && method !== 'GET' && { body: JSON.stringify(body || {}) }) || {},
        ...rawBody && { body: rawBody },
        headers: {
            ...defaultHeaders,
            ...(
                __NEW_API__ && oldAuth !== true
                    ? { Authorization: `Bearer ${accessToken || ''}` }
                    : { 'X-Access-Token': accessToken || '' }
            ),
            ...(headers || {}),
        },
    });
}

export interface Options {
  apiBase?: string,
  accessToken?: string,
  body?: any,
  method?: string,
  headers?: any,
  subdomain?: string,
  rawBody?: string | File,
  oldAuth?: boolean,
}

export type ApiResponse<I> = Either<string | ServerError, I>;

async function apiRequest<I>(
    route: string,
    options: Options,
): Promise<ApiResponse<I>> {
    try {
        const response = await rawApiRequest(route, options);
        return await parseResponse<I>(response);
    } catch (e) {
        return Either.Left(SERVER_ERROR);
    }
}

export default apiRequest;
