import * as yup from 'yup';
import {
    createIncrementingIdEntityAdapter,
    IncrementingIdEntityState,
    IncrementingIdEntitySelectors,
    yupIncrementingIdState,
} from './incrementingIdEntityAdapter';
import {
    ComponentListId,
} from './componentLists';

export type ComponentId = number;

export type Component<O = any> = {
  id: ComponentId;
  typeId: number;
  options: O;
  componentLists?: Record<string, ComponentListId>;
  parentListId: number;
};

export type UnmountedComponent = (
  Omit<Component, 'id' | 'parentListId' | 'componentLists'>
  & { componentLists?: Record<string, null> }
);

const componentsAdapter = createIncrementingIdEntityAdapter<Component>();

export const {
    createOne,
    addOne,
    addMany,
    reserveId,
    getInitialState,
    updateOne,
    updateMany,
    removeOne,
} = componentsAdapter;

export type LocalState = IncrementingIdEntityState<Component>;

export type Selectors<V> = IncrementingIdEntitySelectors<Component, V>;

export const getSelectors = <V>(
    selectState: (state: V) => LocalState,
): Selectors<V> => {
    const localSelectors = componentsAdapter.getSelectors(selectState);

    return {
        ...localSelectors,
    };
};

export const yupComponents = yupIncrementingIdState(yup.object().shape<Component>({
    id: yup.number().required(),
    typeId: yup.number().required(),
    options: yup.object().required(),
    parentListId: yup.number().required(),
    componentLists: yup.lazy((value: unknown) => {
        if (typeof value !== 'object' || value == null) {
            return yup.object();
        }
        const shape: Record<string | number, yup.NumberSchema> = {};
        for (const k of Object.keys(value)) {
            if (!Number.isNaN(parseInt(value[k as keyof typeof value], 10))) {
                shape[k] = yup.number();
            }
        }
        return yup.object(shape).noUnknown().required();
    }),
}).required());
