import { urlParams } from '../global/auth';

export const selectExperienceEditRoute = (state: any, id: string): string => {
    const params = urlParams(state);
    return [
        '/experiences/',
        id.split(':')[2],
        '/edit',
        params ? `?${params.toString()}` : '',
    ].join('');
};

export const selectExperiencesRoute = (state: any): string => {
    const params = urlParams(state);
    return [
        '/experiences',
        params ? `?${params.toString()}` : '',
    ].join('');
};

export const selectOrganizationSettingsRoute = (state: any): string => {
    const params = urlParams(state);
    return [
        '/organization/settings',
        params ? `?${params.toString()}` : '',
    ].join('');
};

export const selectLoginRoute = (state: any, clearOrg: boolean): string => {
    const params = clearOrg ? null : urlParams(state);
    return [
        '/login',
        params ? `?${params.toString()}` : '',
    ].join('');
};
