import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import Navbar from './component';
import {
    logout,
    hasSession,
    selectOrganizationFuture,
} from '../auth';
import {
    selectExperiencesRoute,
    selectOrganizationSettingsRoute,
} from '../../pages/links';

// Todo: remove any
const mapStateToProps = (state: any) => ({
    hasSession: hasSession(state),
    organizationFuture: selectOrganizationFuture(state),
    experienceRoute: selectExperiencesRoute(state),
    organizationSettingsRoute: selectOrganizationSettingsRoute(state),
});

const mapDispatchToProps = {
    logout,
    push,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
