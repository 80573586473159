import React from 'react';
import Tooltip from '../../../global/uiComponents/tooltip';
import * as styles from './styles.scss';

const Search = ({ search }) => (
    <div className={styles.searchBody}>
        <Tooltip messageName="searchHover">
            <label className={styles.searchLabel}>Search</label>
            <input type="text" className={styles.searchInput} onChange={(e) => search(e.target.value)} />
        </Tooltip>
    </div>
);

export default Search;
