import createNextState, {
    isDraft,
} from 'immer';
import {
    PayloadAction,
} from '@reduxjs/toolkit';
import { isPlainObject } from '../utils';

// This is not exported by redux-toolkit so this is a copy of
// https://github.com/reduxjs/redux-toolkit/commit/621edab0d08e0f2cba6eb8e22efca79b43592fce

const isValidKey = (key: string) => (
    ['type', 'payload', 'error', 'meta'].indexOf(key) > -1
);
export const isFSA = (
    action: unknown,
): action is {
  type: string
  payload?: unknown
  error?: unknown
  meta?: unknown
} => (
    isPlainObject(action)
        && typeof (action as any).type === 'string'
        && Object.keys(action).every(isValidKey)
);

export const createStateOperator = <R, S>(
    mutator: (arg: R, state: S) => void,
) => (
        state: S,
        arg: R | PayloadAction<R>,
    ): S => {
        const isPayloadActionArgument = (
            arg: R | PayloadAction<R>,
        ): arg is PayloadAction<R> => (
            isFSA(arg)
        );

        const runMutator = (draft: S) => {
            if (isPayloadActionArgument(arg)) {
                mutator(arg.payload, draft);
            } else {
                mutator(arg, draft);
            }
        };

        if (isDraft(state)) {
            // we must already be inside a `createNextState` call, likely because
            // this is being wrapped in `createReducer` or `createSlice`.
            // It's safe to just pass the draft to the mutator.
            runMutator(state);

            // since it's a draft, we'll just return it
            return state;
        }
        // than an Immutable<S>, and TypeScript cannot find out how to reconcile
        // these two types.
        return createNextState(state, runMutator);
    };
