import * as R from 'ramda';
import apiRequest from '../apiRequest';
import { Experience } from '../../experience';
import {
    seperateMetadata,
} from './helpers';

function create({ accessToken, experience }: CreateParams) {
    const [body, metadata] = seperateMetadata(experience.body) as any;

    return apiRequest('/experiences', {
        method: 'POST',
        accessToken,
        body: {
            ...R.omit(['id', 'assetAssociation'], experience),
            body,
            metadata,
        },
    });
}

export type CreateParams = {
  accessToken: string,
  experience: Exclude<Experience, 'url'>,
}

export default create;
