import * as React from 'react';
import classNames from 'classnames';
import * as styles from './styles.scss';
import TabRouterContext from '../context';

export type TabConfig = {
  id: string,
  title: string,
};

type TabRouterProps = {
  className?: string,
  tabRouterId: string,
  selectedTab: string | null,
  selectTab: (tabRouterId: string, tabId: string) => void,
  defaultTabs?: Array<string>
  tabs: Array<TabConfig>,
  children: React.ReactElement | Array<React.ReactElement>,
};

const TabRouter = ({
    className,
    tabRouterId,
    selectedTab,
    selectTab,
    tabs,
    defaultTabs,
    children,
}: TabRouterProps) => {
    // If no tab is selected select a default
    if ((!selectedTab || !tabs.find(({ id }) => id === selectedTab)) && defaultTabs) {
        const choice = defaultTabs.find((defaultId: string) => !!tabs.find(({ id }) => defaultId === id));
        if (choice) {
            selectTab(tabRouterId, choice);
        }
    }
    return (
        <div className={classNames(styles.tabRouterContainer, className)}>
            <div className={styles.tabs}>
                { tabs.map((tab: TabConfig) => (
                    <Tab
                        key={tab.id}
                        tab={tab}
                        active={tab.id === selectedTab}
                        selectTab={(tabId: string) => selectTab(tabRouterId, tabId)}
                    />
                )) }
            </div>
            <div className={styles.routerScrollContainer}>
                <TabRouterContext.Provider value={tabRouterId}>
                    { children }
                </TabRouterContext.Provider>
            </div>
        </div>
    );
};

type TabProps = {
  active: boolean,
  tab: TabConfig,
  selectTab: (tabId: string) => void,
};
const Tab = ({ active, tab, selectTab }: TabProps) => (
    <div className={classNames(styles.tabItems, { [styles.active]: active })} onClick={() => selectTab(tab.id)}>
        { tab.title }
    </div>
);

export default TabRouter;
