import produce from 'immer';
import { Action, createAction } from '@reduxjs/toolkit';
import { loadExperience } from '../shared';
import { AssetAssociation } from '../../api/asset';
import type { RootState } from '../../rootReducer';
import { ComponentTree } from '../../componentTree';
import * as ExperienceComponentTree from '../componentTree';
import { IMAGE_TYPE_ID } from '../../componentTypeData';
import { selectAttributes } from '../attributes';

const ASSOCIATION_UPDATE_CLAIM = 'ASSET_CLAIM' as const;
const ASSOCIATION_UPDATE_RELINQUISH = 'ASSET_RELINQUISH' as const;

export const addAssets = createAction<Array<AssetAssociation>>('EXPERIENCE/ASSET_ASSOCIATIONS/ADD_ASSET');

export type LocalState = {
  editing: Array<AssetAssociation>;
  clean: Array<AssetAssociation>;
};

const initialState = {
    editing: [],
    clean: [],
};

export const reducer = (state: LocalState = initialState, action: Action): LocalState => {
    if (loadExperience.match(action)) {
        const a = action.experience.asset_associations;
        return {
            editing: a,
            clean: a,
        };
    }

    if (addAssets.match(action)) {
        const assets = action.payload;
        const currentIds = new Set(state.editing.map(({ assetId }) => assetId));
        const newAssets = assets.filter(({ assetId }) => !currentIds.has(assetId));
        if (newAssets.length < 1) { return state; }
        return {
            ...state,
            editing: [...state.editing, ...newAssets],
        };
    }

    return state;
};

const localAssets = (state: RootState): LocalState => state.experience.assetAssociations;
export const selectCleanAssetAssociations = (state: RootState): Array<AssetAssociation> => (
    localAssets(state).clean
);
export const selectEditingAssetAssociations = (state: RootState): Array<AssetAssociation> => (
    localAssets(state).editing
);
export const selectEditingAssetWithId = (state: RootState, assetId: string): AssetAssociation | null => (
    selectEditingAssetAssociations(state)
        .find((a) => a.assetId === assetId) || null
);
export const selectEditingAssetWithIds = (state: RootState, assetIds: Set<string>): Array<AssetAssociation> => (
    selectEditingAssetAssociations(state).filter(({ assetId }) => assetIds.has(assetId))
);

export const selectAssetIdsFromTree = (tree: ComponentTree.ComponentTree): Set<string> => {
    const results = new Set<string>();
    for (const c of ComponentTree.localSelectors.components.selectAll(tree)) {
        if (c?.typeId === IMAGE_TYPE_ID) {
            const { src } = c.options;
            if (typeof src === 'object') {
                results.add(src.assetId);
            }
        }
    }
    return results;
};

export const mapAssetIdsFromTree = (
    assetIdMap: Map<string, string>,
    tree: ComponentTree.ComponentTree,
): ComponentTree.ComponentTree => (
    produce(tree, (draft) => {
        for (const component of Object.values(draft.components.entities)) {
            if (component?.typeId === IMAGE_TYPE_ID) {
                const { src } = component.options;
                if (typeof src === 'object') {
                    src.assetId = assetIdMap.get(src.assetId);
                }
            }
        }
    })
);

const indexedByKey = <T extends string, U extends Record<T, string>>(
    items: Array<U>,
    key: T,
): Map<string, U> => {
    const result = new Map<string, U>();
    for (const i of items) {
        result.set(i[key], i);
    }
    return result;
};

type AssetAssociationClaim = {
  type: typeof ASSOCIATION_UPDATE_CLAIM;
  assetId: string;
  assetVersionId: string;
};

type AssetAssociationRelinquish = {
  type: typeof ASSOCIATION_UPDATE_RELINQUISH;
  assetId: string;
};

export type AssetAssociationUpdate =
  | AssetAssociationClaim
  | AssetAssociationRelinquish;
export type AssetAssociationUpdates = Array<AssetAssociationUpdate>;

export const selectAssociationChanges = (state: RootState): AssetAssociationUpdates => {
    const clean = indexedByKey(
        selectCleanAssetAssociations(state),
        'assetId',
    );
    const editing = indexedByKey(
        selectEditingAssetAssociations(state),
        'assetId',
    );
    const usedAssetIds = selectAssetIdsFromTree(
        ExperienceComponentTree.selectEditingTree(state),
    );

    const { backgroundImage } = selectAttributes(state);
    if (backgroundImage && typeof backgroundImage !== 'string') {
        usedAssetIds.add(backgroundImage.assetId);
    }

    const claim = new Map<string, AssetAssociation>();
    for (const usedAssetId of usedAssetIds) {
        if (!clean.has(usedAssetId)) {
            const asset = editing.get(usedAssetId);
            if (asset) {
                claim.set(usedAssetId, asset);
            }
        }
    }

    const relinquish = new Map<string, AssetAssociation>();
    for (const [assetId, asset] of clean) {
        if (!usedAssetIds.has(assetId)) {
            relinquish.set(assetId, asset);
        }
    }

    const result = [];
    for (const { assetId, assetVersionId } of claim.values()) {
        result.push({
            type: ASSOCIATION_UPDATE_CLAIM,
            assetId,
            assetVersionId,
        });
    }
    for (const { assetId, assetVersionId } of relinquish.values()) {
        result.push({
            type: ASSOCIATION_UPDATE_RELINQUISH,
            assetId,
            assetVersionId,
        });
    }
    return result;
};
