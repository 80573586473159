import { sortBy } from 'lodash-es';
import apiRequest from '../apiRequest';

async function list({ accessToken }: ListParams) {
    const response = await apiRequest('/experiences', { accessToken });
    return response.map(({ experiences }) => sortBy(experiences, 'name'));
}

export type ListParams = {
  accessToken: string,
}

export default list;
