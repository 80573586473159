import {
    combineReducers,
    Reducer,
} from 'redux';
import {
    reducer as contentReducer,
    LocalState as ContentLocalState,
} from './content';
import {
    reducer as modalRootReducer,
} from './reducer';

export * from './reducer';
export * from './saga';
export * from './container';
export * from './hooks';

export type LocalState = {
  content: ContentLocalState,
  root: any,
};

export const reducer: Reducer<LocalState> = combineReducers({
    root: modalRootReducer,
    content: contentReducer,
});
