import * as R from 'ramda';
import { combineReducers } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import * as Attributes from './attributes';
import { sessionUpdateExperience } from '../auth';
import * as ComponentTree from './componentTree';
import staticAttribute from './staticAttribute';
import { createRootAttributeAdapter } from './rootAttribute';
import { selectAssociationChanges, reducer as assetReducer } from './assetAssociations';
import type { RootState } from '../rootReducer';

// Root experience attributes still use snakecase to align with
// old python api and DynamoDB conventions
/* eslint-disable camelcase */
export const RootAttributes = {
    name: createRootAttributeAdapter('name', ''),
    description: createRootAttributeAdapter('description', ''),
    inside_of_head: createRootAttributeAdapter('inside_of_head', ''),
    end_of_body: createRootAttributeAdapter('end_of_body', ''),
};

export const reducer = combineReducers({
    id: staticAttribute((e) => e.id),
    url: staticAttribute((e) => e.url),
    name: RootAttributes.name.reducer,
    description: RootAttributes.description.reducer,
    inside_of_head: RootAttributes.inside_of_head.reducer,
    end_of_body: RootAttributes.end_of_body.reducer,
    assetAssociations: assetReducer,
    body: combineReducers({
        version: staticAttribute((e) => e.body.version),
        attributes: Attributes.reducer,
        componentTree: ComponentTree.reducer,
    }),
});
export type LocalState = ReturnType<typeof reducer>;

export const experience = (state: RootState) => state.experience;
export const id = (state: RootState) => experience(state).id;
export const url = (state: RootState) => experience(state).url;
export const selectResultExperience = (state: RootState) => ({
    ...experience(state),
    name: RootAttributes.name.selectValue(state),
    description: RootAttributes.description.selectValue(state),
    inside_of_head: RootAttributes.inside_of_head.selectValue(state),
    end_of_body: RootAttributes.end_of_body.selectValue(state),
    body: {
        ...R.omit(['componentTree'], experience(state).body),
        attributes: Attributes.selectAttributes(state),
        relationships: {
            components: ComponentTree.selectLegacyState(state),
        },
    },
});
/* eslint-enable camelcase */

export const isClean = (state: RootState) => (
    RootAttributes.name.selectIsClean(state)
        && RootAttributes.description.selectIsClean(state)
        && RootAttributes.inside_of_head.selectIsClean(state)
        && RootAttributes.end_of_body.selectIsClean(state)
        && Attributes.selectIsClean(state)
        && ComponentTree.selectIsClean(state)
);

export function* updateExperience(): SagaIterator {
    return yield call(sessionUpdateExperience, {
        ...(yield select(selectResultExperience)),
        /* eslint-disable-next-line camelcase */
        asset_association_updates: (yield select(selectAssociationChanges)),
    });
}
