// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-global-auth-styles__title--xpd5T {\n  font-weight: 900;\n  font-size: 24px;\n  padding-bottom: 8px;\n  border-bottom: 1px solid rgba(30, 34, 40, 0.05);\n  margin-bottom: 22px; }\n\n.src-global-auth-styles__submitButton--2-yws {\n  margin: auto;\n  margin-top: 24px; }\n\n.src-global-auth-styles__errorMessage--1Xj_f {\n  margin-bottom: 24px;\n  color: #FF8888; }\n", "",{"version":3,"sources":["webpack://./src/global/auth/styles.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,mBAAmB;EACnB,+CAA+C;EAC/C,mBAAmB,EAAA;;AAGrB;EACE,YAAY;EACZ,gBAAgB,EAAA;;AAGlB;EACE,mBAAmB;EACnB,cAAc,EAAA","sourcesContent":[".title {\n  font-weight: 900;\n  font-size: 24px;\n  padding-bottom: 8px;\n  border-bottom: 1px solid rgba(30, 34, 40, 0.05);\n  margin-bottom: 22px;\n}\n\n.submitButton {\n  margin: auto;\n  margin-top: 24px;\n}\n\n.errorMessage {\n  margin-bottom: 24px;\n  color: #FF8888;\n}\n"],"sourceRoot":""}]);
// Exports
export var title = "src-global-auth-styles__title--xpd5T";
export var submitButton = "src-global-auth-styles__submitButton--2-yws";
export var errorMessage = "src-global-auth-styles__errorMessage--1Xj_f";
export default ___CSS_LOADER_EXPORT___;
