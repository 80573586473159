import { connect } from 'react-redux';
import { search } from './duck';
import Search from './component';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    search,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
