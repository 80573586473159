import {
    bindActionCreators,
    Dispatch,
} from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from '../rootReducer';
import { Modal as Component } from './component';
import { modalCompleteConfig } from './reducer';
import { closeModal } from './saga';

const mapStateToProps = (state: RootState) => ({
    modalCompleteConfig: modalCompleteConfig(state),
});

const mapDispatchToProps = (d: Dispatch) => bindActionCreators({
    closeModal,
}, d);

export const Modal = connect(mapStateToProps, mapDispatchToProps)(Component);
