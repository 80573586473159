import { Either } from 'monet';
import serverError, {
    ServerError,
} from './serverError';

const parseResponse = async <I = any>(response: Response): Promise<Either<ServerError, I>> => {
    const payload = response.status === 204 ? null : await response.json();
    return response.ok
        ? Either.Right(payload)
        : Either.Left(serverError({
            status: response.status,
            message: payload.message || payload.Message,
            code: payload.code || payload.Code,
        }));
};

export default parseResponse;
