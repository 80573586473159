import { AssetAssociation } from '../../api/asset';
import { v0_1_4 } from './versions';

export const CURRENT_VERSION = 'v0.1.4';

export const update = (experience: Experience) => ({
    ...experience,
    body: v0_1_4.update(experience.body),
});

export type Experience = ExperienceOfVersion;

export type UncreatedExperience = UncreatedExperienceOfVersion;

export type ExperienceAttributes = v0_1_4.ExperienceAttributes;

export type ExperienceAnyVersion = ExperienceOfVersion;

export type Metadata = {
  components?: {
    [id: string]: { name?: string },
  },
};

type ExperienceOfVersion = {
  id: string,
  url: string,
  name: string,
  description: string,
  body: v0_1_4.Body,
  metadata?: Metadata,
  asset_associations: Array<AssetAssociation>,
  inside_of_head?: string,
  end_of_body?: string,
};

type UncreatedExperienceOfVersion = {
  name: string,
  description: string,
  body: v0_1_4.Body,
  metadata?: Metadata,
};
