import * as R from 'ramda';
import { matchPath } from 'react-router';
import { SagaIterator } from 'redux-saga';
import {
    all,
    call,
    put,
    take,
    takeEvery,
    select,
    spawn,
    delay,
} from 'redux-saga/effects';
import { selectOrganizationFuture, logout } from '../auth';
import { Future } from '../utils';
import { Page } from '../../pages/type';
import {
    pageLoadStart,
    pageLoadFinished,
} from '../loadablePage';

export type RoutePayload = {
  search: string,
  hash: string,
  params: any,
};

export function* pageRouterSaga(pages: Array<Page>): SagaIterator {
    yield all(
        R.map(
            (f: any) => spawn(f),
            R.map(({ saga }) => saga || (() => { /* do nothing. */ }), pages),
        ),
    );

    const pageSagas = R.map(
        ({
            loadPage,
            pageLoaded,
            route,
            needsOrg,
        }) => [
            route,
            function* ({ match }: any): SagaIterator {
                yield put(pageLoadStart());
                let org;
                while (true) {
                    org = yield select(selectOrganizationFuture);
                    if (!needsOrg || !Future.isUnresolved(org)) {
                        break;
                    }
                    yield take('*');
                }
                if (Future.isErr(org) && needsOrg) {
                    yield put(logout());
                    return;
                }
                if (loadPage) {
                    yield call(loadPage, { match });
                }
                yield put(pageLoadFinished());
                if (pageLoaded) {
                    yield call(pageLoaded as any, { match });
                }
            } as any,
        ],
        pages,
    );

    yield takeEvery(
      '@@router/LOCATION_CHANGE' as any,
      function* ({ payload: { location: { pathname } } }: any) {
          yield delay(0);
          yield all(R.pipe(
              R.map(([route, saga]) => {
                  const match = matchPath(pathname, route);
                  return match ? saga({ match }) : null;
              }),
            R.filter(R.identity) as any,
          )(pageSagas) as any);
      },
    );
}
