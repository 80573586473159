import * as R from 'ramda';

export const FUTURE = 'FUTURE';

const SUCCESS = 'SUCCESS' as const;
const UNRESOLVED = 'UNRESOLVED' as const;
const ERROR = 'ERROR' as const;

export type Success<A> = {
    readonly type: typeof FUTURE;
    readonly value: {
        readonly futureStatus: typeof SUCCESS;
        readonly value: A;
    };
};

export type Err<E> = {
    readonly type: typeof FUTURE;
    readonly value: {
        readonly futureStatus: typeof ERROR;
        readonly err: E;
    };
};

export type Unresolved = {
    readonly type: typeof FUTURE;
    readonly value: {
        readonly futureStatus: typeof UNRESOLVED;
    };
};

export type Future<A, E> = Success<A> | Err<E> | Unresolved;
export type Type<A, E> = Future<A, E>;
export type ValueForFuture<A> =
    A extends Future<infer B, any>
        ? B
        : never;
export type ErrorForFuture<A> =
    A extends Future<any, infer B>
        ? B
        : never;

export const Success = <A>(value: A): Success<A> => ({
    type: FUTURE,
    value: {
        value,
        futureStatus: SUCCESS,
    },
});

export const Unresolved: Unresolved = {
    type: FUTURE,
    value: {
        futureStatus: UNRESOLVED,
    },
};

export const Err = <E>(err: E): Err<E> => ({
    type: FUTURE,
    value: {
        futureStatus: ERROR,
        err,
    },
});

export const unwrap = <P>(sp: Success<P>): P => sp.value.value;
export const unwrapErr = <P>(sp: Err<P>): P => sp.value.err;
export const unsafeUnwrap = <A>(future: Future<A, unknown>): A => {
    if (future.value.futureStatus === SUCCESS) {
        return future.value.value;
    }
    throw new Error('Future is not a success');
};

export const isSuccess = <A>(a: Future<A, any>): a is Success<A> => (
    a.value.futureStatus === SUCCESS
);
export const isUnresolved = (a: Future<any, any>): a is Unresolved => (
    a.value.futureStatus === UNRESOLVED
);
export const isErr = <E>(a: Future<any, E>): a is Err<E> => (
    a.value.futureStatus === ERROR
);

export type Up<A> = A extends Future<any, any> ? A : Success<A>;

export const of = Success;

export const up = <A extends { type?: unknown }>(a: A): Up<A> => (
    (typeof a === 'object' && a.type === FUTURE ? a : of(a)) as any
);

export const map: {
    <A, B, E>(fn: (a: A) => B, p: Future<A, E>): Future<B, E>;
    <A, B, E>(fn: (a: A) => B): (p: Future<A, E>) =>
        Future<B, E>;
} = R.curry(<A, B, E>(
    fn: (a: A) => B,
    p: Future<A, E>,
): Future<B, E> => (
        isSuccess(p) ? Success(fn(unwrap(p))) : p
    )) as any;

export const mapErr: {
    <A, E, F>(fn: (a: E) => F, p: Future<A, E>): Future<A, F>;
    <A, E, F>(fn: (a: E) => F): (p: Future<A, E>) =>
        Future<A, F>;
} = R.curry(<A, E, F>(
    fn: (a: E) => F,
    p: Future<A, E>,
): Future<A, F> => (
        isErr(p) ? Err(fn(unwrapErr(p))) : p
    )) as any;

// combines map and mapErr to use the same function on both
// mostly useful for cases where both the value and error are
// objects with the same structure
export const mapBoth: {
    <A, B, E, F>(fn: ((a: A) => B) | ((e: E) => F), p: Future<A, E>): Future<B, F>;
    <A, B, E, F>(fn: ((a: A) => B) | ((e: E) => F)): (p: Future<A, E>) =>
        Future<B, F>;
} = R.curry(<A, B, E, F>(
    fn: ((a: A) => B) | ((e: E) => F),
    p: Future<A, E>,
): Future<B, F> => (
        isErr(p)
            ? Err(fn(unwrapErr(p) as any) as F)
            : isSuccess(p)
                ? Success(fn(unwrap(p) as any) as B)
                : p
    )) as any;

export const chain: {
    <A, B, E, F>(
        fn: (a: A) => Future<B, F>,
        p: Future<A, E>,
    ): Future<B, E | F>;
    <A, B, E, F>(
        fn: (a: A) => Future<B, F>,
    ): (p: Future<A, E>) => Future<B, E | F>;
} = R.curry(<A, B, E, F>(
    fn: (a: A) => Future<B, F>,
    p: Future<A, E>,
): Future<B, E | F> => (
        isSuccess(p) ? fn(p.value.value) : p
    )) as any;

export const unwrapOr: {
    <A, B>(fallback: B, p: Future<A, any>): A | B;
    <B>(fallback: B): <A>(p: Future<A, any>) => A | B;
} = R.curry(<A, B>(
    fallback: B,
    p: Future<A, any>,
): A | B => (isSuccess(p) ? p.value.value : fallback));

export const or: {
    <A, B, F>(fallback: Future<B, F>, p: Future<A, any>):
        Future<A | B, F>;
    <A, B, F>(fallback: Future<B, F>):
        (p: Future<A, any>) => Future<A | B, F>;
} = R.curry(<A, B, F>(
    fallback: Future<B, F>,
    p: Future<A, any>,
): Future<A | B, F> => (
        isErr(p) ? fallback : p
    )) as any;

export const orElse: {
    <A, B, E, F>(
        fallback: (err: E) => Future<B, F>,
        p: Future<A, E>,
    ): Future<A | B, F>;
    <A, B, E, F>(fallback: (err: E) => Future<B, F>):
        (p: Future<A, E>) => Future<A | B, F>;
} = R.curry(<A, B, E, F>(
    fallback: (err: E) => Future<B, F>,
    p: Future<A, E>,
): Future<A | B, F> => (
        isErr(p) ? fallback(unwrapErr(p)) : p
    )) as any;

export const immediateOr: {
    <A, B, F>(
        fallback: Future<B, F>,
        p: Future<A, any>,
    ): Future<A | B, F>;
    <A, B, F>(fallback: Future<B, F>):
        (p: Future<A, any>) => Future<A | B, F>;
} = R.curry(<A, B, F>(
    fallback: Future<B, F>,
    p: Future<A, any>,
): Future<A | B, F> => (
        isSuccess(p) ? p : fallback
    )) as any;

export const all: {
    (futures: []): Success<[]>;
    <A, E>(futures: [Future<A, E>]): Future<[A], E>;
    <A, B, E, F>(futures: [Future<A, E>, Future<B, F>]): Future<[A, B], E | F>;
    <A, B, C, E, F, G>(futures: [Future<A, E>, Future<B, F>, Future<C, G>]): Future<[A, B, C], E | F | G>;
    <A, B, C, D, E, F, G, H>(futures: [Future<A, E>, Future<B, F>, Future<C, G>, Future<D, H>]): Future<[A, B, C, D], E | F | G | H>;
    <R, E>(futures: Array<Future<R, E>>): Future<Array<R>, E>;
} = (<R, E>(
    futures: ReadonlyArray<Future<R, E>>,
): Future<ReadonlyArray<R>, E> => (
    futures.reduce((
        acc: Future<Array<any>, any>,
        p: Future<any, any>,
    ) => {
        if (!isSuccess(acc)) { return acc; }
        if (!isSuccess(p)) { return p; }
        unwrap(acc).push(unwrap(p));
        return acc;
    }, Success([]))
)) as any;
/* eslint-enable max-len */

// same as all or Promise.all but on an objects values instead of an array
export const allValues = <O extends Record<string, Future<any, any>>>(obj: O): (
    Future<
        { [K in keyof O]: O[K] extends Future<infer V, any> ? V : never },
        O[string] extends Future<any, infer E> ? E : never
    >
) => {
    const [keys = [], values = []] = R.transpose(R.toPairs(obj)) as [
        Array<string>,
        Array<Future<any, any>>,
    ];
    const allValues = all(values) as Future<any, any>;
    return map(R.zipObj(keys), allValues) as Future<any, any>;
};

export const allTupple: {
    <A extends ReadonlyArray<Future<any, any>>>(futures: A): (
        A extends ReadonlyArray<Future<any, infer E>>
            ? Future<{ [K in keyof A]: ValueForFuture<A[K]> }, E>
            : never
    );
} = all as any;

export const ap: {
    <A, R, E1, E2>(
        f: Future<(a: A) => R, E1>,
        arg: Future<A, E2>,
    ): Future<R, E1 | E2>;
    <A, R, E1>(f: Future<(a: A) => R, E1>):
        <E2>(arg: Future<A, E2>) => Future<R, E1 | E2>;
} = R.curry(<A, R, E1, E2>(
    f: Future<(a: A) => R, E1>,
    arg: Future<A, E2>,
): Future<R, E1 | E2> => {
    if (!isSuccess(f)) { return f; }
    if (!isSuccess(arg)) { return arg; }
    return of(unwrap(f)(unwrap(arg)));
}) as any;

export const allObject: {
    <O extends Record<string, Future<any, any>>>(
        futures: O,
    ): Future<
        {
            [K in keyof O]:
                O[K] extends Future<infer A, any>
                    ? A
                    : never
        },
        Partial<{
            [K in keyof O]:
                O[K] extends Future<any, infer E>
                    ? E
                    : never
        }>
    >;
} = ((futures: Record<string, Future<any, any>>): (
    Future<Record<string, any>, Record<string, any>>) => R.reduce(
    (
        acc: Future<Record<string, any>, any>,
        [k, v]: [string, Future<any, any>],
    ): Future<Record<string, any>, any> => {
        if (isUnresolved(acc) || isUnresolved(v)) {
            return Unresolved;
        }
        if (isSuccess(acc)) {
            if (isSuccess(v)) {
                return Success({ ...unwrap(acc), [k]: unwrap(v) });
            }
            if (isErr(v)) {
                return Err({ [k]: unwrapErr(v) });
            }
        }
        if (isErr(acc) && isErr(v)) {
            return Err({ ...unwrapErr(acc), [k]: unwrapErr(v) });
        }
        return acc;
    },
    Success({}),
    R.toPairs(futures),
)) as any;

export const eql = (p0: Type<any, any>, p1: Type<any, any>): boolean => (
    (isSuccess(p0) && isSuccess(p1) && unwrap(p0) === unwrap(p1))
    || (isErr(p0) && isErr(p1) && unwrapErr(p0) === unwrapErr(p1))
    || (isUnresolved(p0) && isUnresolved(p1))
);
