import React, { FunctionComponent } from 'react';
import {
    createGlobalStyle,
    DefaultTheme,
    ThemeProvider,
} from 'styled-components';
import defaultTheme from './themes';

const GlobalStyles = createGlobalStyle`
    body {
        position: relative;
        padding: 0;
        margin: 0;
        font-family: ${({ theme }) => theme.primaryFont};
        color: ${({ theme }) => theme.neutralColor.dark};
    }
`;

const StyleRoot: FunctionComponent<{ theme?: DefaultTheme }> = ({
    children,
    theme = defaultTheme,
}) => (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
    </ThemeProvider>
);

export default StyleRoot;
