import * as v0_1_3 from './v0_1_3';

export interface Body {
  version: 'v0.1.4',
  attributes: ExperienceAttributes,
  relationships: ExperienceRelationships,
}

export interface ExperienceAttributes {
  componentStyle?: 'default' | 'none',
  backgroundColor?: string,
  backgroundImage?: string | { type: string; assetId: string } | null,
  trackingId?: string,
  analyticsType?: string,
  gaDimensions?: Array<{
    key: string,
    value: string,
  }>,
}

export interface ExperienceList {
  id: string,
  componentTypeId: string,
  componentKey: string,
  items: { id: string, typeId: string, options?: any, componentLists?: { [key: string]: string } }[],
}

export interface ExperienceRelationships {
  components: {
    items: { id: string, typeId: string, options?: any, componentLists?: { [key: string]: string } }[],
    lists?: ExperienceList[],
    nextId: number,
    nextListId?: number,
  }
}

export type AnyBody = Body | v0_1_3.AnyBody;

export function update(body: AnyBody): Body {
    switch (body.version) {
        case 'v0.1.4':
            return body;
        default: {
            const b = v0_1_3.update(body);
            return {
                ...b,
                version: 'v0.1.4',
                relationships: {
                    ...b.relationships,
                    components: {
                        ...b.relationships.components,
                        items: b.relationships.components.items.map((i) => {
                            switch (i.typeId) {
                                case '16':
                                case '23':
                                    return {
                                        ...i,
                                        options: {
                                            ...i.options,
                                            startDate: {
                                                epoch: parseInt(i.options.startDate, 10) || null,
                                                offset: 0,
                                            },
                                            endDate: {
                                                epoch: parseInt(i.options.endDate, 10) || null,
                                                offset: 0,
                                            },
                                        },
                                    };
                                default:
                                    return i;
                            }
                        }),
                    },
                },
            };
        }
    }
}
