import {
    createStore,
    applyMiddleware,
    compose,
} from 'redux';
import { getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './global/rootReducer';
import SagaManager from './global/hotModule/sagaManager';
import { history } from './global/router';
import {
    CustomDomainApi,
} from './global/api';
import init from './global/init';

const sagaMiddleware = createSagaMiddleware();

const middleware = [
    ...getDefaultMiddleware(),
    sagaMiddleware,
    routerMiddleware(history),
    CustomDomainApi.middleware,
];

const composeEnhancers = (
    !__PRODUCTION__
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;

const store = createStore(
    createRootReducer(history),
    {},
    composeEnhancers(applyMiddleware(...middleware)),
);

store.runSaga = sagaMiddleware.run.bind(sagaMiddleware);

if (!__PRODUCTION__ && module.hot) {
    module.hot.accept('./global/rootReducer', async () => {
        const rootReducer = await import('./global/rootReducer').then((m) => m.default);
        store.replaceReducer(rootReducer);
    });

    SagaManager.startSagas(sagaMiddleware);
    module.hot.accept('./global/hotModule/sagaManager', async () => {
        const ReloadedSagaManager = await import('./global/hotModule/sagaManager').then((m) => m.default);
        ReloadedSagaManager.cancelSagas(store);
        ReloadedSagaManager.startSagas(sagaMiddleware);
    });
} else {
    SagaManager.startSagas(sagaMiddleware);
}

init(store);

export default store;
