import React from 'react';
import classnames from 'classnames';
import Tooltip from '../../tooltip';
import * as styles from '../styles.scss';

class StudioFile extends React.Component {
    constructor(props) {
        super(props);
        if (!props.experienceItems) {
            props.fetchExperiences();
        }
    }

    render() {
        const {
            experienceItems,
            label,
            onChange,
            tooltipName,
            fetchExperiences: _,
            ...rest
        } = this.props;

        return (
            !experienceItems
                ? null
                : (
                    <div className={classnames('row', styles.formField)}>
                        <div className={classnames('col-xs-6', styles.label)}>
                            <Tooltip id={tooltipName} messageName={tooltipName}>
                                <label>{ label }</label>
                            </Tooltip>
                        </div>
                        <div className="col-xs-6">
                            <select
                                className={styles.select}
                                onChange={(e) => onChange && onChange(e.target.value)}
                                {...rest}
                            >
                                <option value="" />
                                { experienceItems.map((e) => <option key={e.id} value={e.id}>{ e.name }</option>) }
                            </select>
                        </div>
                    </div>
                )
        );
    }
}

export default StudioFile;
