import * as yup from 'yup';

export const ASSET_REFERENCE_TYPE = 'ASSET_REFERENCE' as const;

export enum AssetStatus {
  ARCHIVED = 'ARCHIVED',
  AVAILABLE = 'AVAILABLE',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  UPLOADING = 'UPLOADING',
}

export enum AssetType {
  DATA = 'DATA',
  FONT = 'FONT',
  IMAGE = 'IMAGE',
}

export type AssetVersion = {
  __typename: 'AssetVersion';
  id: string;
  status: AssetStatus;
  token: string | null;
  createdAt: any;
  hash: string | null;
};

export type Asset = {
  __typename: 'Asset';
  id: string;
  name: string;
  assetType: AssetType;
  createdAt: any;
  assetVersions: Array<AssetVersion>;
};

export type AssetAssociation = {
  assetId: string,
  assetVersionId: string,
  filename: string,
  assetType: AssetType,
};

export type AssetReference = {
  type: typeof ASSET_REFERENCE_TYPE,
  assetId: string,
};

export const assetAssociationToReference = ({
    assetId,
}: AssetAssociation): AssetReference => ({
    type: ASSET_REFERENCE_TYPE,
    assetId,
});

export const yupAssetAssociation = yup.object().shape<AssetAssociation>({
    assetId: yup.string().required(),
    assetVersionId: yup.string().required(),
    filename: yup.string().required(),
    assetType: yup.string().oneOf<AssetType>([
        AssetType.DATA,
        AssetType.FONT,
        AssetType.IMAGE,
    ]).required(),
}).required();
