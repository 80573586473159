import * as v0_1_1 from './v0_1_1';

export interface Body {
  version: 'v0.1.2',
  attributes: ExperienceAttributes,
  relationships: ExperienceRelationships,
}

export interface ExperienceAttributes {
  componentStyle?: 'default' | 'none',
  backgroundColor?: string,
}

export interface ExperienceRelationships {
  components: {
    items: { id: string, typeId: string, options?: any }[],
    nextId: number,
  }
}

export type AnyBody = Body | v0_1_1.AnyBody;

export function update(body: AnyBody): Body {
    switch (body.version) {
        case 'v0.1.2':
            return body;
        default: {
            const b = v0_1_1.update(body);
            return {
                ...b,
                version: 'v0.1.2',
                relationships: {
                    ...b.relationships,
                    components: {
                        ...b.relationships.components,
                        items: b.relationships.components.items.map((i) => {
                            if (i.typeId === '6') {
                                return {
                                    ...i,
                                    options: {
                                        ...i.options,
                                        format: i.options.format === 'Celsius' ? 'celsius' : 'fahrenheit',
                                    },
                                };
                            }
                            return i;
                        }),
                    },
                },
            };
        }
    }
}
