import { experienceItemWithId } from '../../../global/experiences';

const action = (a) => `PAGES/EXPERIENCES/FOCUS/${a}`;

const FOCUS_EXPERIENCE = action('FOCUS_EXPERIENCE');

const initialState = {
    id: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FOCUS_EXPERIENCE:
            return { ...state, id: action.id };
        default:
            return state;
    }
};

export default reducer;

export const focus = (state) => state.pages.experiences.focus;
export const focusedId = (state) => focus(state).id;
export const focusedExperience = (state) => experienceItemWithId(focusedId(state))(state);

export const focusExperience = (id) => ({
    type: FOCUS_EXPERIENCE,
    id,
});
