import * as R from 'ramda';

const SELECT_TAB = 'UI/TAB_ROUTER/SELECT_TAB' as const;

type FocusAction = {
  type: typeof SELECT_TAB,
  tabRouterId: string,
  selectedTabId: string,
};

export const selectTab = (tabRouterId: string, selectedTabId: string) => ({
    type: SELECT_TAB,
    tabRouterId,
    selectedTabId,
});

type OtherAction = { type: '' };
type Actions = FocusAction | OtherAction;

export type TabRouterState = {
  selectedTabId: string | null,
  tabs: string[],
}

export type State = {
  [tabRouterId: string]: TabRouterState,
};

const reducer = (state: State = {}, action: Actions): State => {
    switch (action.type) {
        case SELECT_TAB:
            return R.set(
                R.lensPath([action.tabRouterId, 'selectedTabId']),
                action.selectedTabId,
                state,
            );
        default:
            return state;
    }
};

export default reducer;

const getTabRouter = (tabRouterId: string, state: any): TabRouterState => state.ui.tabRouter[tabRouterId] || {};
export const getSelectedTab = (tabRouterId: string, state: any): string | null => (
    getTabRouter(tabRouterId, state).selectedTabId
);
