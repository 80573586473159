import {
    combineReducers,
    Reducer,
} from '@reduxjs/toolkit';
import * as R from 'ramda';
import { toPairs } from 'lodash-es';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import * as pages from '../../pages';
import { Page } from '../../pages/type';
import { reducer as experiencesReducer } from '../experiences';
import { reducer as authReducer } from '../auth';
import { CustomDomainApi } from '../api';
import {
    reducer as experienceReducer,
    LocalState as ExperienceState,
} from '../experience';
import { reducer as loadablePageReducer } from '../loadablePage';
import { reducer as busyReducer } from '../busy';
import { reducer as tabRouterReducer, State as TabRouterState } from '../uiComponents/tabs';
import {
    reducer as modalReducer,
    LocalState as ModalLocalState,
} from '../modal';
import { LocalState as ExperienceEditState } from '../../pages/experienceEdit/duck';

export type RootState = {
  experience: ExperienceState,
  modal: ModalLocalState,
  ui: {
    tabRouter: TabRouterState,
  },
  pages: Record<string, unknown> & {
    experienceEdit: ExperienceEditState;
  },
} & Record<string, unknown>;

const createRootReducer = (history: History): Reducer<RootState> => combineReducers<RootState>({
    [CustomDomainApi.reducerPath]: CustomDomainApi.reducer,
    router: connectRouter(history),
    experience: experienceReducer,
    auth: authReducer,
    experiences: experiencesReducer,
    loadablePage: loadablePageReducer,
    busy: busyReducer,
    modal: modalReducer,
    ui: combineReducers({
        tabRouter: tabRouterReducer,
    }),
    pages: combineReducers(R.mergeAll(
        toPairs(pages).map(([name, { reducer }]: [string, Page]) => (
            reducer
                ? { [name]: reducer }
                : {}
        )),
    )),
});

export default createRootReducer;
