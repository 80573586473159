import * as R from 'ramda';
import componentTypeData, {
    ComponentTypeData,
    rootListConfig,
    fixedListConfig,
    infoForComponentTypeId,
} from './index';
import * as ComponentTree from '../experience/componentTree';

enum Operation {
  Add,
  Remove,
  Move,
}

type Validation = R.CurriedFunction4<
  Operation,
  any,
  any,
  ComponentTypeData,
  boolean
>;

const validations: Record<string, Validation> = {
    unique: R.curry((
        operation: Operation,
        options: any,
        state: any,
        component: ComponentTypeData,
    ) => (
        operation !== Operation.Add
            || !ComponentTree.selectMoreThanNComponentsOfType(
                state,
                options.count ?? 1,
                component.id,
            )
    )),
    directChildrenType: R.curry((
        operation: Operation,
        options: any,
        state: any,
        component: ComponentTypeData,
    ) => (
        options.whitelist
            ? R.contains(
                component.id,
                options.whitelist,
            )
            : !R.contains(
                component.id,
                options.blacklist,
            )
    )),
    fail: R.curryN(4, () => false),
};

// Get a validation object that describes validation of a list
// it finds this by looking at the parent and seeing what validations
// are on that list id
const lookupListValidations = (listId: number, state: any) => {
    if (listId === 0) {
        return rootListConfig.validate;
    }
    if (listId === -1) {
        return fixedListConfig.validate;
    }

    const parentComponent = ComponentTree.selectParentComponentForListId(state, listId);

    const parentComponentTypeInfo = (
        parentComponent
            ? infoForComponentTypeId(parentComponent.typeId)
            : null
    );

    // At the moment no component has multiple lists
    return parentComponentTypeInfo?.componentLists?.[0]?.validate ?? [];
};

// Create a validation functions from all of the list validation objects
const makeListValidations = (operation: Operation, listId: number, state: any) => R.map(
    ({ type, ...options }) => validations[type](operation, options, state),
    lookupListValidations(listId, state),
);

// Lookup and combine component and list validations
export const validateOperationForComponentType = (operation: Operation) => (
    listId: number | null,
    state: any,
) => {
    if (listId == null) { return () => false; }

    const listValidations = makeListValidations(operation, listId, state);

    // reusing the same listValidations for all typeIds
    // makes canAddComponents more performant
    return (typeId: number) => {
        const component = infoForComponentTypeId(typeId);

        return R.none(
            (v) => !v(component),
            listValidations,
        );
    };
};

export const validateAddComponentType = validateOperationForComponentType(Operation.Add);
export const validateMoveComponentType = validateOperationForComponentType(Operation.Move);

const canOperationComponents = (operation: Operation) => (
    (listId: number | null, state: any) => (
        R.sortBy(
            R.prop('ord'),
            R.filter(
                R.pipe(
                    R.prop('id'),
                    validateOperationForComponentType(operation)(listId, state),
                ),
                R.values(componentTypeData),
            ),
        )
    )
);

export const canAddComponents = canOperationComponents(Operation.Add);
export const canMoveComponents = canOperationComponents(Operation.Move);
