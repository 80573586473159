import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { Future } from '../utils';
import * as styles from './styles.scss';
import { PagePadding } from '../page';

const NavbarContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #3f4146;
    color: #e2e2e2;
    z-index: 1000;
    box-sizing: border-box;
    font-weight: 500;
    align-items: stretch;
`;

const NavbarContent = styled(PagePadding)`
    padding: 0 30px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
`;

const BlueBiteLogo = ({ className }) => (
    <svg className={className} x="0px" y="0px" viewBox="132 5 100 26" style={{ enableBackground: 'new 132 5 100 26' }}>
        <g>
            <path d="M232,21c0,0-0.4,1.5-1.5,2.6c-1,1-2.7,1.6-2.7,1.6l1.2,1.1c0,0-1.8,2.1-4.2,2.1c-1.1,0-1.9-0.2-2.6-0.5c2.6-2.1,4.6-5,4.6-6.8c0-3.2-2.8-3.4-3.6-3.4c-4,0-6.1,4.4-6.1,7.1c0,0.7,0.2,1.7,0.6,2.6c0,0-0.1,0-0.1,0c-6.1,2.3-2.4-7-0.6-11c0,0,0,0,0,0c0.8,0.1,1.8,0.2,2.9-0.1c3.6-0.8,4.2-2.7,3.8-2.9c-0.4-0.3-2,1.2-3.8,1.2c-0.8,0-1.4-0.1-1.9-0.3c1.9-3.9,4.7-8.8,4.7-8.8s-3.6,0.8-5.7,4.6c-0.5,0.9-1.1,2.1-1.6,3.1c-4.8-2.2-7-1.1-7.1-1.1c0,0,0,0,0,0c-0.6,0.3-1.1,1-1.1,1.7c0,1,0.8,1.9,1.9,1.9c1,0,1.8-0.8,1.9-1.8c0.1,0,0.3,0,0.4,0c1,0,1.7,0.9,2.8,1.5c0,0,0,0,0,0s-1.3,2.6-2.4,5.8c-1.1,3.2-0.8,5.5-0.8,5.5c-5.1,3.6-4.8-0.1-3.9-3.3c0.9-3.1,2.6-5.8,2.6-5.8h-3.5c0,0-1.5,3-2,4.9c-0.6,1.9-0.9,3.5-0.9,4.7c0,1.2,0.4,3.5,3.3,3.5s5.1-2,5.1-2s1.3,2,4,2c1.3,0,2.7-0.5,4-1.2c1,0.7,2.3,1.2,4.2,1.2C232.2,30.8,232,21,232,21z M220.6,26.1c-0.5-1.2-0.3-2.5,0.1-3.5c0.4-0.9,1.3-2.2,2.6-2.2c1.4,0,1.1,1.9,0.1,3.1C222.6,24.3,222.1,25.2,220.6,26.1z" />
            <path d="M143.8,18.6c0,0,5.4-1,5.4-6.1s-3.2-6.8-6.3-6.8c-3.2,0-6.2,5.2-7.9,9.9s-2.1,6.3-2.5,8c-1.2,4.2,0.8,7.3,0.8,7.3s0.5-2,1.6-5.7c0.7-2.2,1.6-5.2,2.7-8c0.5-1.2,1-2.4,1.6-3.4c1.5-2.8,3-4.7,4-4.7c0.8,0,3.1,0.5,3.1,4.1s-3.7,3.8-5.1,3.5c-1.4-0.3-2.4-0.8-2.7,0c-0.3,0.8,0.8,1.1,1.8,1.6c1,0.5,4.2,2.2,4.2,6.2c0,4.2-4.7,4.1-4.7,4.1s-1.8,0-3.4-1.3c-0.4-0.4-1-0.8-1.4-0.3c-0.3,0.4,1.2,3.7,6.1,3.7c5.1,0,6.8-3.1,6.8-5.7C147.7,20.5,143.8,18.6,143.8,18.6z" />
            <path d="M198.5,18.6c0,0,5.4-1,5.4-6.1s-3.2-6.8-6.3-6.8c-3.2,0-6.2,5.2-7.9,9.9c-1.8,4.8-2.1,6.3-2.5,8c-1.2,4.2,0.8,7.3,0.8,7.3s0.5-2,1.6-5.7c0.7-2.2,1.6-5.2,2.7-8c0.5-1.2,1-2.4,1.6-3.4c1.5-2.8,3-4.7,4-4.7c0.8,0,3.1,0.5,3.1,4.1s-3.7,3.8-5.1,3.5c-1.4-0.3-2.4-0.8-2.7,0c-0.3,0.8,0.8,1.1,1.8,1.6c1,0.5,4.2,2.2,4.2,6.2c0,4.2-4.7,4.1-4.7,4.1s-1.8,0-3.4-1.3c-0.4-0.4-1-0.8-1.4-0.3c-0.3,0.4,1.2,3.7,6.1,3.7c5.1,0,6.8-3.1,6.8-5.7C202.4,20.5,198.5,18.6,198.5,18.6z" />
            <path d="M177.6,28.3c-0.9,0-1.7-0.2-2.3-0.4c1-0.7,2-1.4,2.7-2.2c2.7-2.8,3-8-1.5-8.1c-3.2,0-6.1,3.5-6.1,7.1c0,0.5,0.2,1.6,0.7,2.7c-0.5,0.2-1,0.4-1.5,0.6c-1.6,0.6-2.7,0.1-2.8-0.9c-0.1-0.9,0.8-2.8,1-3.4c0.4-1,2.6-6.1,2.6-6.1h-3.3c0,0-1.6,4.3-2.2,5.8c-0.4,0.8-1.7,4-3.2,4c-2,0-0.9-3.3-0.6-4.1c0.3-0.8,2.4-5.7,2.4-5.7h-3.2c0,0-1.7,4.2-2.3,5.6c-0.5,1.5-0.7,3.4-0.7,3.4c-4.7,3-4.9-0.4-4.9-1.1c0.1-3.9,3.5-10.8,3.5-10.8c2.4-0.5,4.5-1.6,5.7-3.3c1.4-1.8,1.8-4.9-0.9-5.6c-2.7-0.8-5,2.1-7.4,7c-1.4,0.3-3.3,1.4-4.6,3.2c-0.7,1-0.5,1.4-0.3,1.5c0.3,0.2,0.6,0,1.2-0.6c0.5-0.5,1.6-1.3,2.5-1.5c-1.7,3.7-3.2,7.4-3.2,10.3c0,3.2,1.9,5,4.9,5c2,0,3.8-1.7,3.8-1.7s0.5,1.7,3,1.7c2.5,0,3.8-1.6,3.8-1.6s0.9,1.6,3.3,1.6c1.2,0,2.9-0.5,4.6-1.4c1,0.8,2.3,1.4,4.2,1.4c5.8,0,6-5.6,6-5.6S180.2,28.3,177.6,28.3z M158.4,9.5c0.6-0.8,1.3-1.6,1.7-1.3c0.4,0.3,0.1,1.2-0.2,1.7c-0.2,0.2-0.6,0.8-1.3,1.2c-0.8,0.5-1.7,0.8-1.7,0.8S157.7,10.3,158.4,9.5z M176.5,20.4c0.7,0,0.9,0.6,0.9,1.3c-0.1,0.8-0.6,2.2-1.4,2.9c-0.4,0.3-1.2,0.9-2.4,1.6C172.7,23.8,174.5,20.4,176.5,20.4z" />
        </g>
    </svg>
);

const Navbar = ({
    logout,
    hasSession,
    organizationFuture,
    push,
    experienceRoute,
    organizationSettingsRoute,
}) => (
    <NavbarContainer>
        <NavbarContent>
            <div className={styles.contentLeft}>
                <BlueBiteLogo className={styles.logo} />
                {
                    hasSession
                        ? (
                            <>
                                <a
                                    className={styles.navLink}
                                    onClick={() => push(experienceRoute)}
                                >
                                    Experiences
                                </a>
                                {
                                    __IS_V2__ ? null : (
                                        <a
                                            className={styles.navLink}
                                            onClick={() => push(organizationSettingsRoute)}
                                        >
                                            Organization Settings
                                        </a>
                                    )
                                }
                            </>
                        )
                        : null
                }
                <a
                    href={__DASHBOARD_BASE__}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.navLink}
                >
                    Dashboard
                </a>
            </div>
            <div className={styles.contentRight}>
                {
                    !__IS_V2__ && Future.isSuccess(organizationFuture)
                        ? (
                            <div className={styles.orgName}>
                                { Future.unwrap(organizationFuture).name }
                            </div>
                        )
                        : null
                }
                {
                    hasSession
                        ? (
                            <a
                                className={classnames(styles.navLink, styles.logout)}
                                onClick={() => logout(true)}
                            >
                                Sign Out
                            </a>
                        )
                        : null
                }
            </div>
        </NavbarContent>
    </NavbarContainer>
);

export default Navbar;
