import { omit } from 'lodash-es';
import * as v0_1_0 from './v0_1_0';

export interface Body {
  version: 'v0.1.1',
  attributes: ExperienceAttributes,
  relationships: ExperienceRelationships,
}

export interface ExperienceAttributes {
  componentStyle?: 'default' | 'none',
  backgroundColor?: string,
}

export interface ExperienceRelationships {
  components: {
    items: { id: string, typeId: string, options?: any }[],
    nextId: number,
  }
}

export type AnyBody = Body | v0_1_0.AnyBody;

export function update(body: AnyBody): Body {
    switch (body.version) {
        case 'v0.1.1':
            return body;
        default: {
            const b = v0_1_0.update(body);
            return {
                ...b,
                version: 'v0.1.1',
                attributes: omit(b.attributes, 'pageTitle'),
            };
        }
    }
}
