import { connect } from 'react-redux';
import * as R from 'ramda';
import {
    fetchExperiences,
    experienceItems,
} from '../../../experiences';
import StudioFile from './component';

const mapStateToProps = R.applySpec({
    experienceItems,
});

const mapDispatchToProps = {
    fetchExperiences,
};

export default connect(mapStateToProps, mapDispatchToProps)(StudioFile);
