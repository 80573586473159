import * as React from 'react';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import Spinner from '../../loadingSpinner';
import * as styles from './styles.scss';

const ImageUpload = ({
    value,
    onChange,
    fileUploadLoading,
    asset,
}) => (
    <div className={styles.dropzoneContainer}>
        {
            fileUploadLoading
                ? null
                : (
                    <>
                        <div className={classnames(styles.dropzoneMessage, styles.left)}>
                            Select Image
                        </div>
                        <div
                            className={classnames(styles.dropzoneMessage, styles.right)}
                            onClick={() => onChange(null)}
                        >
                            X
                        </div>
                    </>
                )
        }
        <Dropzone
            accept="image/*"
            disabled={fileUploadLoading}
            className={styles.dropzone}
            onDrop={(files) => onChange(files[0])}
            multiple
        >
            {
                fileUploadLoading
                    ? <Spinner className={styles.fileUploadSpinner} />
                    : (
                        <img
                            src={
                                asset
                                    ? [
                                        __ASSOCIATED_ASSETS_BASE__,
                                        asset.assetId,
                                        asset.filename,
                                    ].join('/')
                                    : value
                            }
                            className={styles.imagePreview}
                        />
                    )
            }
        </Dropzone>
    </div>
);

export default ImageUpload;
