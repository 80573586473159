import {
    loadPage,
    pageLoaded,
} from './duck';
import { Page } from '../type';

export default {
    loadPage,
    pageLoaded,
    route: {
        path: '/login',
    },
    loadable: {
        loader: () => import('./component'),
    },
} as Page;
