// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-pages-experiences-search-styles__searchBody--2G529 {\n  position: relative;\n  padding-top: 16px;\n  background-color: #ffffff;\n  border: 1px solid rgba(30, 34, 40, 0.05);\n  margin-bottom: 24px; }\n\n.src-pages-experiences-search-styles__searchInput--1Hk46 {\n  border: none;\n  background-color: transparent;\n  font-size: 26px;\n  width: 100%; }\n\n.src-pages-experiences-search-styles__searchLabel--r44aF {\n  position: absolute;\n  top: 8px;\n  left: 8px;\n  font-size: 10;\n  font-weight: bold; }\n", "",{"version":3,"sources":["webpack://./src/pages/experiences/search/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;EACzB,wCAAwC;EACxC,mBAAmB,EAAA;;AAGrB;EACE,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,WAAW,EAAA;;AAGb;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,aAAa;EACb,iBAAiB,EAAA","sourcesContent":[".searchBody {\n  position: relative;\n  padding-top: 16px;\n  background-color: #ffffff;\n  border: 1px solid rgba(30, 34, 40, 0.05);\n  margin-bottom: 24px;\n}\n\n.searchInput {\n  border: none;\n  background-color: transparent;\n  font-size: 26px;\n  width: 100%;\n}\n\n.searchLabel {\n  position: absolute;\n  top: 8px;\n  left: 8px;\n  font-size: 10;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export var searchBody = "src-pages-experiences-search-styles__searchBody--2G529";
export var searchInput = "src-pages-experiences-search-styles__searchInput--1Hk46";
export var searchLabel = "src-pages-experiences-search-styles__searchLabel--r44aF";
export default ___CSS_LOADER_EXPORT___;
