import styled, { css } from 'styled-components';

const sharedCss = css`
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.05em;
    font-family: inherit;
    display: inline-block;
    text-decoration: none;
    margin: 0;
`;

export const PrimaryButton = styled.button`
    ${sharedCss}
    background-color: ${({ theme }) => theme.primaryColor.normal};
    color: ${({ theme }) => theme.backgroundColor};
    border: 0;
    border-radius: 3px;
    padding: 12px 16px;

    &:hover {
        background-color: ${({ theme }) => theme.primaryColor.dark};
    }

    &:disabled {
        background-color: ${({ theme }) => theme.disabledColor.light};
    }
`;

export const SecondaryButton = styled.button`
    ${sharedCss}
    background-color: transparent;
    color: ${({ theme }) => theme.primaryColor.normal};
    border: 1px solid ${({ theme }) => theme.primaryColor.normal};
    border-radius: 3px;
    padding: 12px 16px;

    &:hover {
        background-color: ${({ theme }) => theme.primaryColor.extraLight};
    }

    &:disabled {
        color: ${({ theme }) => theme.disabledColor.light};
        border: 1px solid ${({ theme }) => theme.disabledColor.light};
        background-color: transparent;
    }
`;

export const TertiaryButton = styled.button`
    ${sharedCss}
    background-color: transparent;
    color: ${({ theme }) => theme.primaryColor.normal};
    border: 0;
    border-radius: 20px;
    padding: 2px 8px;

    &:hover {
        background-color: ${({ theme }) => theme.primaryColor.extraLight};
    }

    &:disabled {
        color: ${({ theme }) => theme.disabledColor.light};
        background-color: transparent;
    }
`;
