import React from 'react';
import {
    Content,
    ContentResponse,
    ContentType,
    ContentProps,
} from './content';
import {
    ModalCompleteConfig,
} from './reducer';
import * as styles from './styles.scss';

export type OverlayProps<T extends ContentType = ContentType> = {
  modalCompleteConfig: ModalCompleteConfig,
  closeModal: (content: T, response: ContentResponse<T>) => void,
};

const TopBar = () => (
    <div className={styles.topBar} />
);

const Overlay = ({
    modalCompleteConfig,
    closeModal,
}: OverlayProps): React.ReactElement | null => (
    modalCompleteConfig
        ? (
            <div
                className={styles.overlay}
                onClick={
                    modalCompleteConfig.modalConfig.implicitDismiss
                        ? () => { closeModal(modalCompleteConfig.content, null); }
                        : () => { /* do nothing. */ }
                }
            >
                <ModalBody
                    modalCompleteConfig={modalCompleteConfig}
                    closeModal={closeModal}
                />
            </div>
        )
        : null
);

const ModalBody = (props: ContentProps): React.ReactElement => (
    <div className={styles.modalBody}>
        <TopBar />
        <Content {...props} />
    </div>
);

export const Modal = Overlay;
