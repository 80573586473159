import * as R from 'ramda';
import apiRequest from '../apiRequest';
import {
    update,
    ExperienceAnyVersion,
} from '../../experience';
import { injectMetadata } from './helpers';

async function get({ accessToken, id }: GetParams) {
    return (
        await apiRequest<ResponsePayload>(`/experiences/${id}`, { accessToken })
    ).map(R.pipe(
        ({ body, metadata = {}, ...rest }: any): ExperienceAnyVersion => ({
            ...rest,
            body: injectMetadata(metadata)(body),
        }),
        update,
    ));
}

export type GetParams = {
  accessToken: string,
  id: string,
}

export type ResponsePayload = ExperienceAnyVersion;

export default get;
