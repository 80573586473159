import * as R from 'ramda';
import { Metadata } from '../../experience';

const injectComponentMetadata = (metadata: Metadata) => (
    metadata
        ? R.map((c: any) => ({
            ...c,
            options: {
                ...c.options,
                metadata: (metadata.components && metadata.components[c.id]) || {},
            },
        }))
        : R.identity
);

const removeMetadata = R.evolve({ options: R.omit(['metadata']) });
const mergeMetadata = R.pipe(
    R.map(({ id, options }) => (options.metadata ? { [id]: options.metadata } : {})),
    R.mergeAll,
);

export const injectMetadata = (metadata: Metadata) => R.evolve({
    relationships: {
        components: {
            items: injectComponentMetadata(metadata),
            lists: R.when((i) => !!i, R.map(R.evolve({
                items: injectComponentMetadata(metadata),
            }))),
        },
    },
});

export const seperateMetadata = (v: any) => R.map((f) => f(v), [
    R.evolve({
        relationships: {
            components: {
                items: R.map(removeMetadata),
                lists: R.map(R.evolve({
                    items: R.map(removeMetadata),
                })),
            },
        },
    }),
    ({ relationships: { components: { items, lists } } }: any) => ({
        components: {
            ...(mergeMetadata(items || []) as Record<string, Metadata>),
            ...(R.mergeAll(R.map(
                ({ items }) => mergeMetadata(items),
                lists || [],
            )) as Record<string, Metadata>),
        } as Record<string, Metadata>,
    }),
]);
