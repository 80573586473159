import apiRequest from '../apiRequest';

function login({ email, password }: Props) {
    return apiRequest('/login', {
        method: 'POST',
        body: {
            email,
            password,
        },
    });
}

export type Props = {
  email: string,
  password: string,
};

export default login;
