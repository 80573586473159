import * as R from 'ramda';
import {
    FieldSlot,
    infoForComponentTypeId,
} from './index';
import { UnmountedComponent } from '../componentTree';

const OPTION_TYPES = [
  'options' as const,
  'analyticsOptions' as const,
  'styleOptions' as const,
];

const defaultOptionsForFields: (fields: FieldSlot[]) => any = R.reduce((acc: any, field: FieldSlot) => {
    // Form validation is nested as a special case this should be generalized
    if (field.type === 'checkbox' && field.formValidation) {
        const { name, defaultValue } = field;
        const { formValidationId, formValidationType } = field.formValidation;
        return R.over(
            R.lensPath(['formValidations', formValidationId]),
            R.pipe(
                R.defaultTo({}),
                R.over(R.lensProp(name), R.defaultTo(defaultValue)),
                R.assoc('type', formValidationType),
            ),
            acc,
        );
    }
    if (field.type === 'namespace') {
        return {
            ...acc,
            [field.name]: {
                ...acc[field.name],
                ...defaultOptionsForFields(field.fields),
            },
        };
    }
    if (field.type !== 'message' && field.defaultValue) {
        return {
            ...acc,
            [field.name]: field.defaultValue,
        };
    }
    return acc;
}, {});

const defaultOptionsForComponentType = (typeId: number) => defaultOptionsForFields(
    R.reduce(
        (acc, ot) => [...acc, ...((infoForComponentTypeId(typeId)[ot] || {}).fields || [])],
        [] as FieldSlot[],
        OPTION_TYPES,
    ),
);

const defaultComponentListsForType = (
    typeId: number,
): null | Record<string, null> => {
    const lists = infoForComponentTypeId(typeId).componentLists;
    if (!lists) { return null; }
    return R.fromPairs(R.map(({ key }) => [key, null] as [string, null], lists));
};

export const defaultComponentOfType = (
    typeId: number,
): UnmountedComponent => {
    const lists = defaultComponentListsForType(typeId);
    return {
        typeId,
        options: defaultOptionsForComponentType(typeId),
        ...(lists && { componentLists: lists }),
    };
};
