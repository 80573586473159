import {
    combineReducers,
    Reducer,
} from 'redux';

export * from './component';
export * from './types';

export type LocalState = Record<string, unknown>;

export const reducer: Reducer<LocalState> = combineReducers({
    // Can't be empty even though no content has a reducer yet
    placeholder: (state = null) => state,
});
