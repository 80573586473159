import * as yup from 'yup';
import { CAMPAIGN_IDS } from '../../../global/componentTypeData';
import { ComponentTree as RawComponentTree } from '../../../global/componentTree';
import {
    yupAssetAssociation,
    AssetAssociation,
} from '../../../global/api/asset/types';

const HASH_PREFIX = 'V1_HASH_PREFIX';

const createChecksum = async (message: string): Promise<string> => {
    const msgUint8 = new TextEncoder().encode(`${HASH_PREFIX}${message}`);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    return hashHex.slice(0, 16);
};

const ACTION_COPY = 'BB_ACTION/COPY' as const;
const COPY_VERSION = 3 as const;

const PLATFORM_MTAG = 'mtag' as const;
const PLATFORM_BB = 'bb' as const;
type Platform = typeof PLATFORM_MTAG | typeof PLATFORM_BB;

type CopyMessage = {
  type: typeof ACTION_COPY;
  version: typeof COPY_VERSION;
  platform: Platform;
  payload: string;
  checksum: string;
};

export type CopyPayload = {
  componentTree: RawComponentTree.ComponentTree;
  organizationId: number;
  assets: Array<AssetAssociation>;
};

export const createCopyMessage = async (cp: CopyPayload): Promise<string> => {
    const payload = JSON.stringify(cp);
    const checksum = await createChecksum(payload);
    return JSON.stringify({
        type: ACTION_COPY,
        version: COPY_VERSION,
        payload,
        checksum,
        platform: __IS_V2__ ? PLATFORM_MTAG : PLATFORM_BB,
    } as CopyMessage);
};

const yupCopyMessage = yup.object().shape<CopyMessage>({
    type: yup.string().oneOf([ACTION_COPY]).required(),
    version: yup.number().oneOf([COPY_VERSION]).required(),
    platform: yup.string().oneOf([PLATFORM_MTAG, PLATFORM_BB]).required(),
    payload: yup.string().required(),
    checksum: yup.string().required(),
})
    .test(
        'platform',
        /* eslint-disable-next-line no-template-curly-in-string */
        '${path} invalid platform',
        async (value: CopyMessage) => (
            __IS_V2__
                ? value.platform === PLATFORM_MTAG
                : true
        ),
    )
    .test(
        'payload-checksum',
        /* eslint-disable-next-line no-template-curly-in-string */
        '${path} failed checksum',
        async (value: CopyMessage) => {
            try {
                const checksum = await createChecksum(value.payload);
                return checksum === value.checksum;
            } catch (_) {
                // parts of the structure may be blank and trigger errors
                // you cannot enforce checking the shape before the tests in yup
                // https://github.com/jquense/yup/issues/851
                return false;
            }
        },
    );

const yupCopyPayload = yup.object().shape<CopyPayload>({
    componentTree: RawComponentTree.yupComponentTree.required(),
    organizationId: yup.number().required(),
    assets: yup.array().of(yupAssetAssociation).required(),
});

export const parseCopyPayload = async (message: string): Promise<CopyPayload | null> => {
    try {
        const { payload, platform } = await yupCopyMessage.required().validate(JSON.parse(message));
        const resultPayload = await yupCopyPayload.required().validate(JSON.parse(payload));
        if (!__IS_V2__ && platform === PLATFORM_MTAG) {
            const removeIds = resultPayload.componentTree.components.ids.filter((id) => {
                const component = resultPayload.componentTree.components.entities[id];
                return (
                    component?.typeId
                        && CAMPAIGN_IDS.includes(component?.typeId)
                );
            });
            resultPayload.componentTree = RawComponentTree.removeComponents(
                resultPayload.componentTree,
                { componentIds: removeIds },
            );
        }
        return resultPayload;
    } catch (_) {
        return null;
    }
};
