import { connect } from 'react-redux';
import { AssetAssociations } from '../../../experience';
import { idIsBusy } from '../../../busy';
import ImageUpload from './component';

type Props = {
  label: string,
  tooltipName: string,
  loadingId: string,
  value: null | string | {
    assetId: string,
  },
  onChange: (file: null | File) => void,
};

const mapStateToProps = (state: any, props: Props) => ({
    ...props,
    fileUploadLoading: idIsBusy(props.loadingId)(state),
    asset: (
        props.value && typeof props.value !== 'string'
            ? AssetAssociations.selectEditingAssetWithId(state, props.value.assetId)
            : null
    ),
});

export default connect(mapStateToProps)(ImageUpload);
