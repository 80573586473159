import { SagaIterator } from 'redux-saga';
import {
    select,
    put,
    takeLeading,
    take,
    actionChannel,
} from 'redux-saga/effects';
import { Either } from 'monet';
import {
    ContentConfig,
    ContentResponse,
    ContentType,
} from './content';
import * as Reducer from './reducer';
import {
    internalOpenModal,
    internalCloseModal,
    ModalConfig,
    InternalModalOpenAction,
} from './sharedActions';

export const ERROR_ALREADY_OPEN = 'ALREADY_OPEN' as const;
type TriggerModalErrorAlreadyOpen = { type: typeof ERROR_ALREADY_OPEN };

export const OPEN = 'MODAL/OPEN' as const;
export const CLOSE = 'MODAL/CLOSE' as const;

type ModalOpenAction<K extends ContentType = ContentType> = Omit<
  InternalModalOpenAction<K>,
  'type'
> & Record<'type', typeof OPEN>;
export const openModal = <K extends ContentType>(
    content: K,
    contentConfig: ContentConfig<K>,
    modalConfig: ModalConfig,
): ModalOpenAction<K> => ({
        type: OPEN,
        content,
        contentConfig,
        modalConfig,
    });

type ModalCloseAction<K extends ContentType = ContentType> = {
  type: typeof CLOSE,
  content: K,
  response: ContentResponse<K>,
};
export const closeModal = <K extends ContentType>(
    content: K,
    response: ContentResponse<K>,
): ModalCloseAction<K> => ({
        type: CLOSE,
        content,
        response,
    });

export type OpenModalResult<T extends ContentType> = (
  Either<TriggerModalErrorAlreadyOpen, ContentResponse<T>>
);
export type OpenModalSagaProps<T extends ContentType> = Omit<ModalOpenAction<T>, 'type'>;
export type OpenModalError = TriggerModalErrorAlreadyOpen;
export function* openModalSaga<T extends ContentType>(
    {
        content,
        contentConfig,
        modalConfig,
    }: OpenModalSagaProps<T>,
): SagaIterator<Either<TriggerModalErrorAlreadyOpen, ContentResponse<T>>> {
    const currentConfig: ContentConfig = yield select(Reducer.modalCompleteConfig);
    if (currentConfig) {
        return Either.Left<TriggerModalErrorAlreadyOpen, ContentResponse<T>>({
            type: ERROR_ALREADY_OPEN,
        });
    }

    yield put(internalOpenModal(
        content,
        contentConfig,
        modalConfig,
    ));

    const closeChan = yield actionChannel(CLOSE);
    while (true) {
        const close: ModalCloseAction<T> = yield take(closeChan);
        if (close.content === content) {
            yield put(internalCloseModal(close.content));
            return Either.Right<TriggerModalErrorAlreadyOpen, ContentResponse<T>>(
                close.response,
            );
        }
    }
}

// The same function but requires an unused action type which fixes the typing for `takeLeading`
const openModalSagaStrict: <T extends ContentType>(
  params: ModalOpenAction<T>,
) => SagaIterator<Either<TriggerModalErrorAlreadyOpen, ContentResponse<T>>> = (
    openModalSaga
);

export function* saga() {
    yield takeLeading(OPEN, openModalSagaStrict);
}
