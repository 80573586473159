export interface Body {
  version: 'v0.1.0',
  attributes: ExperienceAttributes,
  relationships: ExperienceRelationships,
}

export interface ExperienceAttributes {
  componentStyle?: 'default' | 'none',
  backgroundColor?: string,
  pageTitle?: string,
}

export interface ExperienceRelationships {
  components: {
    items: { id: string, typeId: string, options?: any }[],
    nextId: number,
  }
}

export type AnyBody = Body;

export function update(body: Body): Body {
    return body;
}
