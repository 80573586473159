export const HUBSPOT_ENDPOINT = 'https://forms.hubspot.com/uploads/form/v2';

export const HUBSPOT_KEY = '4496170';

export async function hubspotRequest(tracker: string, body?: any) {
    if (!__PRODUCTION__) {
        return;
    }

    const params = new URLSearchParams();

    for (const k of Object.keys(body)) {
        params.set(k, body[k]);
    }

    try {
        return await fetch(`${HUBSPOT_ENDPOINT}/${HUBSPOT_KEY}/${tracker}`, {
            method: 'POST',
            body: params,
        });
    } catch (e) {
        // If hubspot fails we want to continue on with the application
        // May change this functionality down the road
    }
}
