import React from 'react';
import { SagaMiddleware, Task } from 'redux-saga';
import { useStore } from 'react-redux';
import {
    openModalSaga,
    OpenModalSagaProps,
    OpenModalResult,
    OpenModalError,
} from './saga';
import { ContentType, ContentResponse } from './content';

type UseModalUninitialized = {
    isSuccess: false,
    isError: false,
    isLoading: false,
    isUninitialized: true,
};

type UseModalSuccess<T extends ContentType> = {
    isSuccess: true,
    isError: false,
    data: ContentResponse<T>,
    isLoading: false,
    isUninitialized: false,
};

type UseModalError = {
    isSuccess: false,
    isError: true,
    error: OpenModalError,
    isLoading: false,
    isUninitialized: false,
};

type UseModalLoading = {
    isSuccess: false,
    isError: false,
    isLoading: true,
    isUninitialized: false,
};

type UseModalResult<T extends ContentType> = (
    | UseModalSuccess<T>
    | UseModalError
    | UseModalLoading
    | UseModalUninitialized
);

type UseModalTrigger<T extends ContentType> = (
    (override?: OpenModalSagaProps<T>) => Promise<UseModalSuccess<T> | UseModalError>
);

const modalResultToUseResult = <T extends ContentType>(
    response: OpenModalResult<T>,
): UseModalSuccess<T> | UseModalError => (
        response.isLeft()
            ? ({
                isSuccess: false,
                isError: true,
                isLoading: false,
                isUninitialized: false,
                error: response.left(),
            })
            : ({
                isSuccess: true,
                isError: false,
                isLoading: false,
                isUninitialized: false,
                data: response.right(),
            })
    );

export const useModal = <T extends ContentType>(
    props: OpenModalSagaProps<T>,
): [UseModalTrigger<T>, UseModalResult<T>] => {
    const store = useStore();
    const [result, setResult] = React.useState<UseModalResult<T>>({
        isSuccess: false,
        isError: false,
        isLoading: false,
        isUninitialized: true,
    });

    const [task, setTask] = React.useState<Task | null>(null);

    React.useEffect(() => {
        if (!task) { return; }

        setResult({
            isSuccess: false,
            isError: false,
            isLoading: true,
            isUninitialized: false,
        });

        let canceled = false;

        (async () => {
            const result: OpenModalResult<T> = await task.toPromise();
            if (canceled) {
                return;
            }
            setResult(modalResultToUseResult(result));

            setTask(null);
        })();

        return () => {
            task?.cancel();
            canceled = true;
        };
    }, [task]);

    const trigger = React.useCallback(async (
        overrides: Partial<OpenModalSagaProps<T>> = {},
    ): Promise<UseModalSuccess<T> | UseModalError> => {
        const task = ((store as any).runSaga as SagaMiddleware['run'])(
            // Need to write this type so TypeScript knows the prop for openModalSaga
            // accepts the generic prop type. Otherwise typescript resolves the type
            // too eagerly and they don't match.
            openModalSaga as (p?: OpenModalSagaProps<T>) => ReturnType<typeof openModalSaga>,
            {
                ...props,
                contentConfig: {
                    ...props.contentConfig,
                    ...overrides?.contentConfig,
                },
                modalConfig: {
                    ...props.modalConfig,
                    ...overrides?.modalConfig,
                },
            },
        );
        setTask(task);
        return modalResultToUseResult(await task.toPromise());
    }, [store, props]);

    return [trigger, result];
};
