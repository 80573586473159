// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-global-modal-styles__modalBody--3TeRi {\n  background-color: #fff;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  max-width: calc(100vw - 20px);\n  box-shadow: 0 0 27px 0 rgba(25, 25, 25, 0.33); }\n\n.src-global-modal-styles__topBar--1yKWS {\n  height: 20px;\n  background-color: #23272f; }\n\n.src-global-modal-styles__overlay--3_Gq7 {\n  z-index: 10000;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.2); }\n", "",{"version":3,"sources":["webpack://./src/global/modal/styles.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,6BAA6B;EAC7B,6CAA6C,EAAA;;AAG/C;EACE,YAAY;EACZ,yBAAyB,EAAA;;AAG3B;EACE,cAAc;EACd,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;EACR,oCAAoC,EAAA","sourcesContent":[".modalBody {\n  background-color: #fff;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  max-width: calc(100vw - 20px);\n  box-shadow: 0 0 27px 0 rgba(25, 25, 25, 0.33);\n}\n\n.topBar {\n  height: 20px;\n  background-color: #23272f;\n}\n\n.overlay {\n  z-index: 10000;\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  background-color: rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export var modalBody = "src-global-modal-styles__modalBody--3TeRi";
export var topBar = "src-global-modal-styles__topBar--1yKWS";
export var overlay = "src-global-modal-styles__overlay--3_Gq7";
export default ___CSS_LOADER_EXPORT___;
