import TabRouter, {
    TabConfig,
} from './tabRouter';
import TabContent from './tabContent';
import reducer from './reducer';

export * from './reducer';

export {
    TabRouter,
    TabContent,
    TabConfig,
    reducer,
};
