export const SERVER_ERROR = 'SERVER_ERROR';

export type ServerError = {
  type: 'SERVER_ERROR',
  status: number,
  message?: string,
  code?: string,
};

function serverError({ status, message, code }: Props): ServerError {
    return {
        status,
        message,
        code,
        type: SERVER_ERROR,
    };
}

export type Props = {
  status: number,
  message?: string,
  code?: string,
};

export default serverError;
