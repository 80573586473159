import apiRequest from './apiRequest';

type uploadFileProps = {
  accessToken: string,
  file: File,
}

export const uploadFile = ({ accessToken, file }: uploadFileProps) => (
    apiRequest('/images', {
        method: 'POST',
        accessToken,
        apiBase: __NEW_API__ ? __API_BASE__.replace('/api', '') : __API_BASE__,
        subdomain: 'api.usercontent.',
        headers: {
            'Content-Type': file.type,
        },
        rawBody: file,
        oldAuth: true,
    })
);
