import * as v0_1_2 from './v0_1_2';

export interface Body {
  version: 'v0.1.3',
  attributes: ExperienceAttributes,
  relationships: ExperienceRelationships,
}

export interface ExperienceAttributes {
  componentStyle?: 'default' | 'none',
  backgroundColor?: string,
  backgroundImage?: any,
}

export interface ExperienceRelationships {
  components: {
    items: { id: string, typeId: string, options?: any }[],
    nextId: number,
  }
}

export type AnyBody = Body | v0_1_2.AnyBody;

export function update(body: AnyBody): Body {
    switch (body.version) {
        case 'v0.1.3':
            return body;
        default: {
            const b = v0_1_2.update(body);
            return {
                ...b,
                version: 'v0.1.3',
                relationships: {
                    ...b.relationships,
                    components: {
                        ...b.relationships.components,
                        items: b.relationships.components.items.map((i) => {
                            if (['1', '2', '3', '4', '10'].indexOf(i.typeId) !== -1) {
                                return {
                                    ...i,
                                    options: {
                                        ...i.options,
                                        configuration: 'dynamic',
                                    },
                                };
                            }
                            return i;
                        }),
                    },
                },
            };
        }
    }
}
